import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Nav, Collapse, UncontrolledTooltip } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import logodarkicon from '../../../assets/images/logo-icon.gif';
import SiteSelector from 'common/site-selector/views/SiteSelector';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const mapStateToProps = (state) => ({
  ...state,
});

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
      expanded: true,
    };
  }

  activeRoute(prop) {
    return prop.name === this.state.selected ? 'active' : '';
  }

  setSelectedAndOpenState() {
    let selected = '';
    let openState = null;

    if (
      this.state.selected === '' &&
      this.props.siteSelector.selectedSite !== ''
    ) {
      this.props.routes.map((route) => {
        if (!route.redirect && !route.routeonly) {
          if (
            this.props.location.pathname.indexOf(
              route.path.replace(
                '/site',
                '/site/' + this.props.siteSelector.selectedSite.website_domain
              )
            ) > -1
          )
            selected = route.name;
          route.child.map((child) => {
            if (child.subchild) {
              child.subchild.map((subchild) => {
                if (
                  this.props.location.pathname.indexOf(
                    subchild.path.replace(
                      ':sitecode',
                      this.props.siteSelector.selectedSite.website_domain
                    )
                  ) > -1
                )
                  openState = child.state;
                return true;
              });
            }
            return true;
          });
        }
        return true;
      });
      this.setState({
        ...this.state,
        selected:
          selected ||
          (this.props.routes.length > 0 && this.props.routes[0].name) ||
          'Not Found',
        [openState]: true,
      });
    }
  }

  componentDidMount() {
    this.setSelectedAndOpenState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ ...this.state, selected: '' });
    }
    this.setSelectedAndOpenState();
    if (
      document.getElementById('sidebarbg').className.indexOf('shrinked') !== -1
    ) {
      document.getElementById('page-wrapper').classList.add('shrinked');
      if (document.getElementsByClassName('page-header').length > 0) {
        document
          .getElementsByClassName('page-header')[0]
          .classList.add('shrinked');
      }
    } else {
      document.getElementById('page-wrapper').classList.remove('shrinked');
      if (document.getElementsByClassName('page-header').length > 0) {
        document
          .getElementsByClassName('page-header')[0]
          .classList.remove('shrinked');
      }
    }
  }

  render() {
    return (
      <div>
        <aside
          className={
            this.state.expanded ? 'left-sidebar' : 'left-sidebar shrinked'
          }
          id='sidebarbg'
          style={{ marginTop: '44px' }}
        >
          <div className='scroll-sidebar'>
            <div
              className={
                this.state.expanded
                  ? 'scrollbar-container small'
                  : 'scrollbar-container small shrinked'
              }
            >
              <PerfectScrollbar className='sidebar-nav'>
                <Nav id='sidebarnav'>
                  {this.props.routes.map((prop, key) => {
                    if (prop.redirect || prop.routeonly) return null;
                    else {
                      return (
                        <li
                          key={key}
                          className='sidebar-item item-container item-icon'
                          id={prop.id}
                        >
                          <div
                            className={this.activeRoute(prop) + ' sidebar-link'}
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                selected: prop.name,
                              });
                            }}
                          >
                            <prop.icon className='ic-lg' />
                          </div>
                          {/* <UncontrolledTooltip placement="right" target={prop.id}>
													{prop.name}
												</UncontrolledTooltip> */}
                        </li>
                      );
                    }
                  })}
                </Nav>
              </PerfectScrollbar>
            </div>

            <div
              className={
                this.state.expanded
                  ? 'scrollbar-container large'
                  : 'scrollbar-container large shrinked'
              }
            >
              <PerfectScrollbar className='sidebar-nav'>
                <Nav
                  id='sidebarnav'
                  style={{
                    paddingLeft: 9,
                    paddingRight: 9,
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  <h3 className='sidebar-submenu-title'>
                    {this.state.selected !== '' &&
                      this.props.routes.find(
                        (route) => route.name === this.state.selected
                      ).title}
                  </h3>

                  {this.props.routes.map((prop) => {
                    if (prop.child) {
                      if (this.state.selected === prop.name)
                        return prop.child.map((child, key) => {
                          if (child.navlabel) {
                            return (
                              <li
                                className='sidebar-item item-container item-nav-label'
                                key={key}
                              >
                                <div className='nav-label-container'>
                                  <span className='nav-label'>
                                    {child.navlabel}
                                  </span>
                                </div>
                              </li>
                            );
                          } else if (child.collapse) {
                            let firstdd = {};
                            firstdd[child['state']] = !this.state[child.state];
                            return (
                              <li className='sidebar-item' key={key}>
                                <div
                                  className='sidebar-sublink'
                                  onClick={() => {
                                    this.setState(firstdd);
                                  }}
                                >
                                  <child.icon className='sidebar-sublink-text icon ic-md' />
                                  <span className='sidebar-sublink-text arrow-container'>
                                    {child.name}
                                  </span>
                                  <span
                                    aria-expanded={this.state[child.state]}
                                    data-toggle='collapse'
                                    className='has-arrow'
                                  />
                                </div>

                                <Collapse isOpen={this.state[child.state]}>
                                  <ul className='first-level'>
                                    {child.subchild.map((subchild, key) => {
                                      return (
                                        <li className='sidebar-item' key={key}>
                                          <NavLink
                                            to={subchild.path.replace(
                                              ':sitecode',
                                              this.props.siteSelector
                                                .selectedSite.website_domain
                                            )}
                                            activeClassName='active'
                                            className='sidebar-sublink'
                                          >
                                            <span>{subchild.name}</span>
                                          </NavLink>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </Collapse>
                              </li>
                            );
                          } else
                            return (
                              <li className='sidebar-item' key={key}>
                                <NavLink
                                  to={child.path.replace(
                                    ':sitecode',
                                    this.props.siteSelector.selectedSite
                                      .website_domain
                                  )}
                                  activeClassName='active'
                                  className='sidebar-sublink'
                                >
                                  <child.icon className='sidebar-sublink-text icon ic-md' />
                                  <span className='sidebar-sublink-text'>
                                    {child.name}
                                  </span>
                                </NavLink>
                              </li>
                            );
                        });
                      else return null;
                    }
                    return true;
                  })}
                </Nav>
              </PerfectScrollbar>
              <div
                className='text-center pt-3 cursor-pointer'
                onClick={() => {
                  this.setState({
                    ...this.state,
                    expanded: !this.state.expanded,
                  });
                }}
              >
                {this.state.expanded ? (
                  <ArrowBackIosIcon className='ic-sm' />
                ) : (
                  <ArrowForwardIosIcon className='ic-sm' />
                )}
              </div>
            </div>
          </div>
        </aside>
        <div className='top-of-sidebar left-sidebar'>
          <div className='scrollbar-logo'>
            <a href='/'>
              <img
                src={logodarkicon}
                alt='homepage'
                className='light-logo'
                width='35px'
                height='35px'
              />
            </a>
          </div>
          <div className='scrollbar-siteselector'>
            <SiteSelector {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps)(Sidebar);
