import { instance2 } from 'utils/axios'
import CustomSnackbar from 'common/customsnackbar'

import {
    UPDATE_GENERAL_SETTING_ERROR,
    UPDATE_GENERAL_SETTING_PENDING,
    UPDATE_GENERAL_SETTING_SUCCESS,
    FETCH_ONE_GENERAL_SETTING,
    FETCH_ONE_GENERAL_SETTING_PENDING,
} from './types';

const setUpdateGeneralSettingPending = isUpdateGeneralSettingPending => dispatch => {
    dispatch({
        type: UPDATE_GENERAL_SETTING_PENDING,
        payload: isUpdateGeneralSettingPending
    });
}

const setFetchOneGeneralSettingPending = isGeneralSettingDataPending => dispatch => {
    dispatch({
        type: FETCH_ONE_GENERAL_SETTING_PENDING,
        payload: isGeneralSettingDataPending
    });
}

export const setUpdateGeneralSettingError = updateGeneralSettingError => dispatch => {
    dispatch({
        type: UPDATE_GENERAL_SETTING_ERROR,
        payload: updateGeneralSettingError
    });
}

export const setUpdateGeneralSettingSuccess = isUpdateGeneralSettingSuccess => dispatch => {
    dispatch({
        type: UPDATE_GENERAL_SETTING_SUCCESS,
        payload: isUpdateGeneralSettingSuccess
    });
}

// https://site.hopwebsite.com/coreapi/meta/save/{seting_group}
export const updateGeneralSetting = (postData) => async dispatch => {

    dispatch(setUpdateGeneralSettingSuccess(false));
    dispatch(setUpdateGeneralSettingPending(true));
    dispatch(setFetchOneGeneralSettingPending(true));

    instance2.post('meta/save/general', postData)
        .then(response => {
            dispatch({
                type: FETCH_ONE_GENERAL_SETTING,
                payload: response.data.data
            });
            dispatch(setUpdateGeneralSettingSuccess(true));
            CustomSnackbar('success', 'Setting updated successfully');
        })
        .catch(err => {
            if (err.response) {
                dispatch(setUpdateGeneralSettingError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        })
        .finally(() => {
            dispatch(setUpdateGeneralSettingPending(false));
            dispatch(setFetchOneGeneralSettingPending(false));
        });

}

// https://site.hopwebsite.com/coreapi/meta/get/{seting_group}
export const fetchGeneralSetting = () => async dispatch => {

    dispatch(setFetchOneGeneralSettingPending(true));

    instance2.post('meta/get/general', {})
        .then(response => {
            dispatch({
                type: FETCH_ONE_GENERAL_SETTING,
                payload: response.data.data
            });
            dispatch(setFetchOneGeneralSettingPending(false));
        })
        .catch(err => {
            dispatch(setFetchOneGeneralSettingPending(false));
        });
}