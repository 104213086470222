import React from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  CustomInput,
  FormText,
  Row,
  Col,
} from 'reactstrap';

import Select from 'react-select';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CustomSpinner from 'common/CustomSpinner';
import { connect } from 'react-redux';
import {
  setUpdateSeoError,
  setUpdateSeoSuccess,
  updateSeo,
  fetchSeo,
} from '../actions/seoActions';

const metaRobotOptions = [
  { value: 'index, follow', label: 'index, follow' },
  { value: 'index, nofollow', label: 'index, nofollow' },
  { value: 'noindex, follow', label: 'noindex, follow' },
  { value: 'noindex, nofollow', label: 'noindex, nofollow' },
];

class SeoTitlesMetas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      site: '',
    };
  }

  componentDidMount() {
    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
      });
      this.props.fetchSeo();
    }
  }

  componentDidUpdate() {
    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
      });
      this.props.fetchSeo();
    }
  }

  render() {
    const { seoData, updateSeo, isSeoDataPending, isUpdateSeoPending, site } =
      this.props;

    if (!site || (isSeoDataPending && !isUpdateSeoPending)) {
      return <CustomSpinner />;
    }

    return (
      <div>
        <div>{isSeoDataPending && <CustomSpinner />}</div>
        <Formik
          enableReinitialize
          initialValues={{
            seo_title_separator: seoData.seo_title_separator || '-',
            front_page_title:
              seoData.front_page_title ||
              '%sitetitle% %separator% %sitedescription%',
            front_page_meta_description:
              seoData.front_page_meta_description || '',
            front_page_meta_robots:
              seoData.front_page_meta_robots || 'index, follow',
            post_page_title_template:
              seoData.post_page_title_template ||
              '%post_title% %separator% %sitetitle%',
            post_page_meta_description_template:
              seoData.post_page_meta_description_template || '%post_excerpt%',
            post_page_meta_robots:
              seoData.post_page_meta_robots || 'index, follow',
            search_page_title:
              seoData.search_page_title || 'Search %separator% %sitetitle%',
            search_page_meta_description:
              seoData.search_page_meta_description || '',
            search_page_meta_robots:
              seoData.search_page_meta_robots || 'noindex, follow',
            error_page_title:
              seoData.error_page_title || 'Error 404 %separator% %sitetitle%',
            error_page_meta_description:
              seoData.error_page_meta_description || '',
            error_page_meta_robots:
              seoData.error_page_meta_robots || 'noindex, follow',
            category_page_title:
              seoData.category_page_title ||
              'Category:%term_name% %separator% %sitetitle%',
            category_page_meta_description:
              seoData.category_page_meta_description || '%term_description%',
            category_page_meta_robots:
              seoData.category_page_meta_robots || 'index, nofollow',
            tag_page_title:
              seoData.tag_page_title ||
              'Tag:%term_name% %separator% %sitetitle%',
            tag_page_meta_description:
              seoData.tag_page_meta_description || '%term_description%',
            tag_page_meta_robots:
              seoData.tag_page_meta_robots || 'index, nofollow',
          }}
          validationSchema={Yup.object().shape({
            front_page_title: Yup.string().required('Page Title is required'),
            post_page_title_template: Yup.string().required(
              'Page Title is required'
            ),
            search_page_title: Yup.string().required('Page Title is required'),
            error_page_title: Yup.string().required('Page Title is required'),
            category_page_title: Yup.string().required(
              'Page Title is required'
            ),
            tag_page_title: Yup.string().required('Page Title is required'),
          })}
          onSubmit={({
            seo_title_separator,
            front_page_title,
            front_page_meta_description,
            front_page_meta_robots,
            post_page_title_template,
            post_page_meta_description_template,
            post_page_meta_robots,
            search_page_title,
            search_page_meta_description,
            search_page_meta_robots,
            error_page_title,
            error_page_meta_description,
            error_page_meta_robots,
            category_page_title,
            category_page_meta_description,
            category_page_meta_robots,
            tag_page_title,
            tag_page_meta_description,
            tag_page_meta_robots,
          }) => {
            const postData = {
              seo_title_separator: seo_title_separator,
              front_page_title: front_page_title,
              front_page_meta_description: front_page_meta_description,
              front_page_meta_robots: front_page_meta_robots,
              post_page_title_template: post_page_title_template,
              post_page_meta_description_template:
                post_page_meta_description_template,
              post_page_meta_robots: post_page_meta_robots,
              search_page_title: search_page_title,
              search_page_meta_description: search_page_meta_description,
              search_page_meta_robots: search_page_meta_robots,
              error_page_title: error_page_title,
              error_page_meta_description: error_page_meta_description,
              error_page_meta_robots: error_page_meta_robots,
              category_page_title: category_page_title,
              category_page_meta_description: category_page_meta_description,
              category_page_meta_robots: category_page_meta_robots,
              tag_page_title: tag_page_title,
              tag_page_meta_description: tag_page_meta_description,
              tag_page_meta_robots: tag_page_meta_robots,
            };
            updateSeo(postData);
          }}
        >
          {({ errors, touched, setFieldValue, values, setFieldTouched }) => (
            <Form id='seoForm'>
              <div className='page-header'>
                <Row>
                  <Col className='page-title'>
                    <h1>{this.props.title}</h1>
                  </Col>
                  <Col className='text-right page-header-button'>
                    <button type='submit' className='btn btn-primary'>
                      Save Changes
                    </button>
                  </Col>
                </Row>
              </div>
              <div className='content-wrapper'>
                <Row>
                  <Col md='6'>
                    <Card>
                      <CardTitle>Home/Front Page</CardTitle>
                      <CardBody className=''>
                        <Row>
                          <Col md='4'>
                            <FormGroup>
                              <Label
                                className='control-label'
                                for='seo_title_separator'
                              >
                                Separator
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md='8'>
                            <Field
                              id='seo_title_separator'
                              name='seo_title_separator'
                              type='text'
                              className={
                                'form-control' +
                                (errors.seo_title_separator &&
                                touched.seo_title_separator
                                  ? ' is-invalid'
                                  : '')
                              }
                            />
                            <ErrorMessage
                              name='seo_title_separator'
                              component='div'
                              className='invalid-feedback'
                            />
                            <FormText color='muted'>
                              Use this separator with %separator% in your title
                              and meta description.
                            </FormText>
                          </Col>
                        </Row>
                        <Row className='mt-3'>
                          <Col md='4'>
                            <FormGroup>
                              <Label
                                className='control-label'
                                for='front_page_title'
                              >
                                Page Title
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md='8'>
                            <Field
                              id='front_page_title'
                              name='front_page_title'
                              type='text'
                              className={
                                'form-control' +
                                (errors.front_page_title &&
                                touched.front_page_title
                                  ? ' is-invalid'
                                  : '')
                              }
                            />
                            <ErrorMessage
                              name='front_page_title'
                              component='div'
                              className='invalid-feedback'
                            />
                            <FormText color='muted'>
                              The following variables can be used in the Page
                              Title: %sitetitle%,%separator%,%sitedescription%
                            </FormText>
                          </Col>
                        </Row>
                        <Row className='mt-3'>
                          <Col md='4'>
                            <FormGroup>
                              <Label
                                className='control-label'
                                for='front_page_meta_description'
                              >
                                Meta Description
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md='8'>
                            <Field
                              component='textarea'
                              rows='3'
                              id='front_page_meta_description'
                              name='front_page_meta_description'
                              className={
                                'form-control' +
                                (errors.front_page_meta_description &&
                                touched.front_page_meta_description
                                  ? ' is-invalid'
                                  : '')
                              }
                            />
                            <ErrorMessage
                              name='front_page_meta_description'
                              component='div'
                              className='invalid-feedback'
                            />
                          </Col>
                        </Row>
                        <Row className='mt-3'>
                          <Col md='4'>
                            <FormGroup>
                              <Label
                                className='control-label'
                                for='front_page_meta_robots'
                              >
                                Meta Robots
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md='8'>
                            <Select
                              id='front_page_meta_robots'
                              name='front_page_meta_robots'
                              options={metaRobotOptions}
                              isSearchable={false}
                              getOptionLabel={(option) => option.label}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  border: '1px solid #e9ecef',
                                  borderRadius: '2px',
                                }),
                                singleValue: (provided, state) => ({
                                  ...provided,
                                  color: '#525f7f',
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  padding: '0 0.75rem',
                                }),
                              }}
                              onChange={(option) => {
                                setFieldValue(
                                  'front_page_meta_robots',
                                  option.value
                                );
                              }}
                              onBlur={() => {
                                setFieldTouched('front_page_meta_robots');
                              }}
                              value={
                                metaRobotOptions.find(
                                  (option) =>
                                    option.value ===
                                    values.front_page_meta_robots
                                ) || 'index, follow'
                              }
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md='6'>
                    <Card>
                      <CardTitle>Post Page</CardTitle>
                      <CardBody className=''>
                        <Row>
                          <Col md='4'>
                            <FormGroup>
                              <Label
                                className='control-label'
                                for='post_page_title_template'
                              >
                                Page Title Template
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md='8'>
                            <Field
                              id='post_page_title_template'
                              name='post_page_title_template'
                              type='text'
                              className={
                                'form-control' +
                                (errors.post_page_title_template &&
                                touched.post_page_title_template
                                  ? ' is-invalid'
                                  : '')
                              }
                            />
                            <ErrorMessage
                              name='post_page_title_template'
                              component='div'
                              className='invalid-feedback'
                            />
                            <FormText color='muted'>
                              The following variables can be used in the Page
                              Title: %post_title%,%separator%,%sitetitle%
                            </FormText>
                          </Col>
                        </Row>
                        <Row className='mt-3'>
                          <Col md='4'>
                            <FormGroup>
                              <Label
                                className='control-label'
                                for='post_page_meta_description_template'
                              >
                                Meta Description Template
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md='8'>
                            <Field
                              component='textarea'
                              rows='3'
                              id='post_page_meta_description_template'
                              name='post_page_meta_description_template'
                              className={
                                'form-control' +
                                (errors.post_page_meta_description_template &&
                                touched.post_page_meta_description_template
                                  ? ' is-invalid'
                                  : '')
                              }
                            />
                            <ErrorMessage
                              name='post_page_meta_description_template'
                              component='div'
                              className='invalid-feedback'
                            />
                          </Col>
                        </Row>
                        <Row className='mt-3'>
                          <Col md='4'>
                            <FormGroup>
                              <Label
                                className='control-label'
                                for='post_page_meta_robots'
                              >
                                Meta Robots
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md='8'>
                            <Select
                              id='post_page_meta_robots'
                              name='post_page_meta_robots'
                              options={metaRobotOptions}
                              isSearchable={false}
                              getOptionLabel={(option) => option.label}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  border: '1px solid #e9ecef',
                                  borderRadius: '2px',
                                }),
                                singleValue: (provided, state) => ({
                                  ...provided,
                                  color: '#525f7f',
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  padding: '0 0.75rem',
                                }),
                              }}
                              onChange={(option) => {
                                setFieldValue(
                                  'post_page_meta_robots',
                                  option.value
                                );
                              }}
                              onBlur={() => {
                                setFieldTouched('post_page_meta_robots');
                              }}
                              value={
                                metaRobotOptions.find(
                                  (option) =>
                                    option.value ===
                                    values.post_page_meta_robots
                                ) || 'index, follow'
                              }
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md='6'>
                    <Card>
                      <CardTitle>Search Page</CardTitle>
                      <CardBody className=''>
                        <Row>
                          <Col md='4'>
                            <FormGroup>
                              <Label
                                className='control-label'
                                for='search_page_title'
                              >
                                Page Title
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md='8'>
                            <Field
                              id='search_page_title'
                              name='search_page_title'
                              type='text'
                              className={
                                'form-control' +
                                (errors.search_page_title &&
                                touched.search_page_title
                                  ? ' is-invalid'
                                  : '')
                              }
                            />
                            <ErrorMessage
                              name='search_page_title'
                              component='div'
                              className='invalid-feedback'
                            />
                            <FormText color='muted'>
                              The following variables can be used in the Page
                              Title: %sitetitle%,%separator%
                            </FormText>
                          </Col>
                        </Row>
                        <Row className='mt-3'>
                          <Col md='4'>
                            <FormGroup>
                              <Label
                                className='control-label'
                                for='search_page_meta_description'
                              >
                                Meta Description
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md='8'>
                            <Field
                              component='textarea'
                              rows='3'
                              id='search_page_meta_description'
                              name='search_page_meta_description'
                              className={
                                'form-control' +
                                (errors.search_page_meta_description &&
                                touched.search_page_meta_description
                                  ? ' is-invalid'
                                  : '')
                              }
                            />
                            <ErrorMessage
                              name='search_page_meta_description'
                              component='div'
                              className='invalid-feedback'
                            />
                          </Col>
                        </Row>
                        <Row className='mt-3'>
                          <Col md='4'>
                            <FormGroup>
                              <Label
                                className='control-label'
                                for='search_page_meta_robots'
                              >
                                Meta Robots
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md='8'>
                            <Select
                              id='search_page_meta_robots'
                              name='search_page_meta_robots'
                              options={metaRobotOptions}
                              isSearchable={false}
                              getOptionLabel={(option) => option.label}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  border: '1px solid #e9ecef',
                                  borderRadius: '2px',
                                }),
                                singleValue: (provided, state) => ({
                                  ...provided,
                                  color: '#525f7f',
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  padding: '0 0.75rem',
                                }),
                              }}
                              onChange={(option) => {
                                setFieldValue(
                                  'search_page_meta_robots',
                                  option.value
                                );
                              }}
                              onBlur={() => {
                                setFieldTouched('search_page_meta_robots');
                              }}
                              value={
                                metaRobotOptions.find(
                                  (option) =>
                                    option.value ===
                                    values.search_page_meta_robots
                                ) || 'noindex, follow'
                              }
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md='6'>
                    <Card>
                      <CardTitle>Error Page</CardTitle>
                      <CardBody className=''>
                        <Row>
                          <Col md='4'>
                            <FormGroup>
                              <Label
                                className='control-label'
                                for='error_page_title'
                              >
                                Page Title
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md='8'>
                            <Field
                              id='error_page_title'
                              name='error_page_title'
                              type='text'
                              className={
                                'form-control' +
                                (errors.error_page_title &&
                                touched.error_page_title
                                  ? ' is-invalid'
                                  : '')
                              }
                            />
                            <ErrorMessage
                              name='error_page_title'
                              component='div'
                              className='invalid-feedback'
                            />
                            <FormText color='muted'>
                              The following variables can be used in the Page
                              Title: %sitetitle%,%separator%
                            </FormText>
                          </Col>
                        </Row>
                        <Row className='mt-3'>
                          <Col md='4'>
                            <FormGroup>
                              <Label
                                className='control-label'
                                for='error_page_meta_description'
                              >
                                Meta Description
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md='8'>
                            <Field
                              component='textarea'
                              rows='3'
                              id='error_page_meta_description'
                              name='error_page_meta_description'
                              className={
                                'form-control' +
                                (errors.error_page_meta_description &&
                                touched.error_page_meta_description
                                  ? ' is-invalid'
                                  : '')
                              }
                            />
                            <ErrorMessage
                              name='error_page_meta_description'
                              component='div'
                              className='invalid-feedback'
                            />
                          </Col>
                        </Row>
                        <Row className='mt-3'>
                          <Col md='4'>
                            <FormGroup>
                              <Label
                                className='control-label'
                                for='error_page_meta_robots'
                              >
                                Meta Robots
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md='8'>
                            <Select
                              id='error_page_meta_robots'
                              name='error_page_meta_robots'
                              options={metaRobotOptions}
                              isSearchable={false}
                              getOptionLabel={(option) => option.label}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  border: '1px solid #e9ecef',
                                  borderRadius: '2px',
                                }),
                                singleValue: (provided, state) => ({
                                  ...provided,
                                  color: '#525f7f',
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  padding: '0 0.75rem',
                                }),
                              }}
                              onChange={(option) => {
                                setFieldValue(
                                  'error_page_meta_robots',
                                  option.value
                                );
                              }}
                              onBlur={() => {
                                setFieldTouched('error_page_meta_robots');
                              }}
                              value={
                                metaRobotOptions.find(
                                  (option) =>
                                    option.value ===
                                    values.error_page_meta_robots
                                ) || 'noindex, follow'
                              }
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md='6'>
                    <Card>
                      <CardTitle>Category Page</CardTitle>
                      <CardBody className=''>
                        <Row>
                          <Col md='4'>
                            <FormGroup>
                              <Label
                                className='control-label'
                                for='category_page_title'
                              >
                                Page Title
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md='8'>
                            <Field
                              id='category_page_title'
                              name='category_page_title'
                              type='text'
                              className={
                                'form-control' +
                                (errors.category_page_title &&
                                touched.category_page_title
                                  ? ' is-invalid'
                                  : '')
                              }
                            />
                            <ErrorMessage
                              name='category_page_title'
                              component='div'
                              className='invalid-feedback'
                            />
                            <FormText color='muted'>
                              The following variables can be used in the Page
                              Title: %term_name%,%sitetitle%,%separator%
                            </FormText>
                          </Col>
                        </Row>
                        <Row className='mt-3'>
                          <Col md='4'>
                            <FormGroup>
                              <Label
                                className='control-label'
                                for='category_page_meta_description'
                              >
                                Meta Description
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md='8'>
                            <Field
                              component='textarea'
                              rows='3'
                              id='category_page_meta_description'
                              name='category_page_meta_description'
                              className={
                                'form-control' +
                                (errors.category_page_meta_description &&
                                touched.category_page_meta_description
                                  ? ' is-invalid'
                                  : '')
                              }
                            />
                            <ErrorMessage
                              name='category_page_meta_description'
                              component='div'
                              className='invalid-feedback'
                            />
                          </Col>
                        </Row>
                        <Row className='mt-3'>
                          <Col md='4'>
                            <FormGroup>
                              <Label
                                className='control-label'
                                for='category_page_meta_robots'
                              >
                                Meta Robots
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md='8'>
                            <Select
                              id='category_page_meta_robots'
                              name='category_page_meta_robots'
                              options={metaRobotOptions}
                              isSearchable={false}
                              getOptionLabel={(option) => option.label}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  border: '1px solid #e9ecef',
                                  borderRadius: '2px',
                                }),
                                singleValue: (provided, state) => ({
                                  ...provided,
                                  color: '#525f7f',
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  padding: '0 0.75rem',
                                }),
                              }}
                              onChange={(option) => {
                                setFieldValue(
                                  'category_page_meta_robots',
                                  option.value
                                );
                              }}
                              onBlur={() => {
                                setFieldTouched('category_page_meta_robots');
                              }}
                              value={
                                metaRobotOptions.find(
                                  (option) =>
                                    option.value ===
                                    values.category_page_meta_robots
                                ) || 'noindex, follow'
                              }
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md='6'>
                    <Card>
                      <CardTitle>Tag Page</CardTitle>
                      <CardBody className=''>
                        <Row>
                          <Col md='4'>
                            <FormGroup>
                              <Label
                                className='control-label'
                                for='tag_page_title'
                              >
                                Page Title
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md='8'>
                            <Field
                              id='tag_page_title'
                              name='tag_page_title'
                              type='text'
                              className={
                                'form-control' +
                                (errors.tag_page_title && touched.tag_page_title
                                  ? ' is-invalid'
                                  : '')
                              }
                            />
                            <ErrorMessage
                              name='tag_page_title'
                              component='div'
                              className='invalid-feedback'
                            />
                            <FormText color='muted'>
                              The following variables can be used in the Page
                              Title: %term_name%,%sitetitle%,%separator%
                            </FormText>
                          </Col>
                        </Row>
                        <Row className='mt-3'>
                          <Col md='4'>
                            <FormGroup>
                              <Label
                                className='control-label'
                                for='tag_page_meta_description'
                              >
                                Meta Description
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md='8'>
                            <Field
                              component='textarea'
                              rows='3'
                              id='tag_page_meta_description'
                              name='tag_page_meta_description'
                              className={
                                'form-control' +
                                (errors.tag_page_meta_description &&
                                touched.tag_page_meta_description
                                  ? ' is-invalid'
                                  : '')
                              }
                            />
                            <ErrorMessage
                              name='tag_page_meta_description'
                              component='div'
                              className='invalid-feedback'
                            />
                          </Col>
                        </Row>
                        <Row className='mt-3'>
                          <Col md='4'>
                            <FormGroup>
                              <Label
                                className='control-label'
                                for='tag_page_meta_robots'
                              >
                                Meta Robots
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md='8'>
                            <Select
                              id='tag_page_meta_robots'
                              name='tag_page_meta_robots'
                              options={metaRobotOptions}
                              isSearchable={false}
                              getOptionLabel={(option) => option.label}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  border: '1px solid #e9ecef',
                                  borderRadius: '2px',
                                }),
                                singleValue: (provided, state) => ({
                                  ...provided,
                                  color: '#525f7f',
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  padding: '0 0.75rem',
                                }),
                              }}
                              onChange={(option) => {
                                setFieldValue(
                                  'tag_page_meta_robots',
                                  option.value
                                );
                              }}
                              onBlur={() => {
                                setFieldTouched('tag_page_meta_robots');
                              }}
                              value={
                                metaRobotOptions.find(
                                  (option) =>
                                    option.value === values.tag_page_meta_robots
                                ) || 'noindex, follow'
                              }
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    seoData: state.seo.seoData,
    isSeoDataPending: state.seo.isSeoDataPending,
    isUpdateSeoPending: state.seo.isUpdateSeoPending,
    isUpdateSeoSuccess: state.seo.isUpdateSeoSuccess,
    updateSeoError: state.seo.updateSeoError,
    site: state.siteSelector.selectedSite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUpdateSeoError: (updateSeoError) => {
      dispatch(setUpdateSeoError(updateSeoError));
    },
    setUpdateSeoSuccess: (isUpdateSeoSuccess) => {
      dispatch(setUpdateSeoSuccess(isUpdateSeoSuccess));
    },
    updateSeo: (postData) => {
      dispatch(updateSeo(postData));
    },
    fetchSeo: () => {
      dispatch(fetchSeo());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SeoTitlesMetas);
