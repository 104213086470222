import React from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  InputGroup,
  Input,
  Row,
  Col,
} from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CustomSpinner from 'common/CustomSpinner';
import { connect } from 'react-redux';
import {
  setUpdateSeoError,
  setUpdateSeoSuccess,
  updateSeo,
  fetchSeo,
} from '../actions/seoActions';

class Permalink extends React.Component {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.state = {
      site: '',
      cursorStart: 0,
      cursorEnd: 0,
      isMobile: window.innerWidth < 991 ? true : false,
    };
  }

  componentDidMount() {
    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
      });
      this.props.fetchSeo();
    }

    window.addEventListener('load', this.updateDimensions);
    window.addEventListener('resize', this.updateDimensions);
    document.getElementsByClassName('footer')[0].classList.add('active');
  }

  componentDidUpdate() {
    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
      });
      this.props.fetchSeo();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.updateDimensions);
    window.removeEventListener('resize', this.updateDimensions);
    document.getElementsByClassName('footer')[0].classList.remove('active');
  }

  updateDimensions() {
    let element = document.getElementsByClassName('footer')[0];
    if (window.innerWidth < 991) {
      element.classList.add('active');
    } else {
      element.classList.remove('active');
    }
  }

  render() {
    const { seoData, updateSeo, isSeoDataPending, isUpdateSeoPending, site } =
      this.props;

    const onRadioChangeHandler = (id, setFieldValue) => {
      let structure = '';

      switch (id) {
        case 0:
          structure = '';
          break;

        case 1:
          structure = '/%year%/%monthnum%/%day%/%postname%/';
          break;

        case 2:
          structure = '/%year%/%monthnum%/%postname%/';
          break;

        case 3:
          structure = '/archives/%post_id%/';
          break;

        case 4:
          structure = '/%postname%/';
          break;

        case 5:
          structure = '/%category%/%postname%/';
          break;

        case 6:
          structure = '/%year%/%postname%/';
          break;
      }
      setFieldValue('structure', structure);
    };

    const ifChecked = (id, structure) => {
      switch (id) {
        case 0:
          return structure === '' ? true : false;

        case 1:
          return structure === '/%year%/%monthnum%/%day%/%postname%/'
            ? true
            : false;

        case 2:
          return structure === '/%year%/%monthnum%/%postname%/' ? true : false;

        case 3:
          return structure === '/archives/%post_id%/' ? true : false;

        case 4:
          return structure === '/%postname%/' ? true : false;

        case 5:
          return structure === '/%category%/%postname%/' ? true : false;

        case 6:
          return (
            structure !== '' &&
            structure !== '/%year%/%monthnum%/%day%/%postname%/' &&
            structure !== '/%year%/%monthnum%/%postname%/' &&
            structure !== '/archives/%post_id%/' &&
            structure !== '/%postname%/' &&
            structure !== '/%category%/%postname%/'
          );
      }
    };

    const isTagActive = (tag, structure) => {
      return structure.includes(tag) ? 'active' : '';
    };

    const addRemoveTag = (tag, structure, setFieldValue) => {
      if (structure.includes(tag)) {
        if (
          structure === tag ||
          structure === '/' + tag ||
          structure === tag + '/' ||
          structure === '/' + tag + '/'
        )
          setFieldValue('structure', '');
        else if (structure.includes('/' + tag))
          setFieldValue('structure', structure.replace('/' + tag, ''));
        else setFieldValue('structure', structure.replace(tag, ''));
      } else {
        let startText = structure.substring(0, this.state.cursorStart);
        let endText = structure.substring(
          this.state.cursorEnd,
          structure.length
        );
        setFieldValue(
          'structure',
          startText.endsWith('/')
            ? (startText + tag + '/' + endText).trim()
            : (startText + '/' + tag + '/' + endText).trim()
        );
      }
    };

    if (!site || (isSeoDataPending && !isUpdateSeoPending)) {
      return <CustomSpinner />;
    }

    return (
      <div>
        <div>{isSeoDataPending && <CustomSpinner />}</div>
        <Formik
          enableReinitialize
          initialValues={{
            structure: seoData.permalink_structure || '',
            categoryBase: seoData.category_base || '',
            tagBase: seoData.tag_base || '',
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={({ structure, categoryBase, tagBase }) => {
            const postData = {
              permalink_structure: structure,
              category_base: categoryBase,
              tag_base: tagBase,
            };
            updateSeo(postData);
          }}
        >
          {({ errors, touched, setFieldValue, values, setFieldTouched }) => (
            <Form id='permalinkForm'>
              <div className='page-header'>
                <Row>
                  <Col className='page-title'>
                    <h1>{this.props.title}</h1>
                  </Col>
                  <Col className='text-right page-header-button'>
                    <button type='submit' className='btn btn-primary'>
                      Save Changes
                    </button>
                  </Col>
                </Row>
              </div>
              <div className='content-wrapper'>
                <Card>
                  <CardTitle>
                    Common Settings
                    <div className='card-intro'>
                      HopCMS offers you the ability to create a custom URL
                      structure for your permalinks and archives. Custom URL
                      structures can improve the aesthetics, usability, SEO and
                      forward-compatibility of your links.
                    </div>
                  </CardTitle>
                  <CardBody className=''>
                    <FormGroup tag='fieldset'>
                      <Row>
                        <Col md='2' sm='6' xs='12'>
                          <FormGroup check>
                            <Label check className='control-label'>
                              <Input
                                type='radio'
                                checked={ifChecked(0, values.structure)}
                                onChange={() =>
                                  onRadioChangeHandler(0, setFieldValue)
                                }
                                name='radio1'
                              />{' '}
                              Plain
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col md='10' sm='6' xs='12'>
                          <FormGroup>
                            <code>{site.website_url}/?p=123</code>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='2' sm='6' xs='12'>
                          <FormGroup check>
                            <Label check className='control-label'>
                              <Input
                                type='radio'
                                checked={ifChecked(1, values.structure)}
                                onChange={() =>
                                  onRadioChangeHandler(1, setFieldValue)
                                }
                                name='radio1'
                              />{' '}
                              Day and name
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col md='10' sm='6' xs='12'>
                          <FormGroup>
                            <code>
                              {site.website_url}/2020/06/22/sample-post/
                            </code>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='2' sm='6' xs='12'>
                          <FormGroup check>
                            <Label check className='control-label'>
                              <Input
                                type='radio'
                                checked={ifChecked(2, values.structure)}
                                onChange={() =>
                                  onRadioChangeHandler(2, setFieldValue)
                                }
                                name='radio1'
                              />{' '}
                              Month and name
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col md='10' sm='6' xs='12'>
                          <FormGroup>
                            <code>{site.website_url}/2020/06/sample-post/</code>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='2' sm='6' xs='12'>
                          <FormGroup check>
                            <Label check className='control-label'>
                              <Input
                                type='radio'
                                checked={ifChecked(3, values.structure)}
                                onChange={() =>
                                  onRadioChangeHandler(3, setFieldValue)
                                }
                                name='radio1'
                              />{' '}
                              Numeric
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col md='10' sm='6' xs='12'>
                          <FormGroup>
                            <code>{site.website_url}/archives/123/</code>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='2' sm='6' xs='12'>
                          <FormGroup check>
                            <Label check className='control-label'>
                              <Input
                                type='radio'
                                checked={ifChecked(4, values.structure)}
                                onChange={() =>
                                  onRadioChangeHandler(4, setFieldValue)
                                }
                                name='radio1'
                              />{' '}
                              Post name
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col md='10' sm='6' xs='12'>
                          <FormGroup>
                            <code>{site.website_url}/sample-post/</code>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='2' sm='6' xs='12'>
                          <FormGroup check>
                            <Label check className='control-label'>
                              <Input
                                type='radio'
                                checked={ifChecked(5, values.structure)}
                                onChange={() =>
                                  onRadioChangeHandler(5, setFieldValue)
                                }
                                name='radio1'
                              />{' '}
                              Category and post name
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col md='10' sm='6' xs='12'>
                          <FormGroup>
                            <code>
                              {site.website_url}/category/sample-post/
                            </code>
                          </FormGroup>
                        </Col>
                      </Row>
                    </FormGroup>
                  </CardBody>
                </Card>

                <Card>
                  <CardTitle>
                    Base for Archives Pages
                    <div className='card-intro'>
                      If you like, you may enter custom structures for your
                      category and tag URLs here. For example, using{' '}
                      <code>topics</code> as your category base would make your
                      category links like{' '}
                      <code>{site.website_url}/topics/uncategorized/.</code> If
                      you leave these blank the defaults will be used.
                    </div>
                  </CardTitle>
                  <CardBody className=''>
                    <Row>
                      <Label
                        md='2'
                        sm='6'
                        xs='12'
                        className='control-label'
                        for='categoryBase'
                      >
                        Category base
                      </Label>
                      <Col md='4' sm='6' xs='12'>
                        <Field
                          id='categoryBase'
                          name='categoryBase'
                          type='text'
                          className={
                            'form-control' +
                            (errors.categoryBase && touched.categoryBase
                              ? ' is-invalid'
                              : '')
                          }
                        />
                        <ErrorMessage
                          name='categoryBase'
                          component='div'
                          className='invalid-feedback'
                        />
                      </Col>
                    </Row>

                    <Row className='mt-4'>
                      <Label
                        md='2'
                        sm='6'
                        xs='12'
                        className='control-label'
                        for='tagBase'
                      >
                        Tag base
                      </Label>
                      <Col md='4' sm='6' xs='12'>
                        <Field
                          id='tagBase'
                          name='tagBase'
                          type='text'
                          className={
                            'form-control' +
                            (errors.tagBase && touched.tagBase
                              ? ' is-invalid'
                              : '')
                          }
                        />
                        <ErrorMessage
                          name='tagBase'
                          component='div'
                          className='invalid-feedback'
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    seoData: state.seo.seoData,
    isSeoDataPending: state.seo.isSeoDataPending,
    isUpdateSeoPending: state.seo.isUpdateSeoPending,
    isUpdateSeoSuccess: state.seo.isUpdateSeoSuccess,
    updateSeoError: state.seo.updateSeoError,
    site: state.siteSelector.selectedSite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUpdateSeoError: (updateSeoError) => {
      dispatch(setUpdateSeoError(updateSeoError));
    },
    setUpdateSeoSuccess: (isUpdateSeoSuccess) => {
      dispatch(setUpdateSeoSuccess(isUpdateSeoSuccess));
    },
    updateSeo: (postData) => {
      dispatch(updateSeo(postData));
    },
    fetchSeo: () => {
      dispatch(fetchSeo());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Permalink);
