import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { changePostStatus, deletePosts } from '../../actions/postActions';
import { connect } from 'react-redux';
import { Popover, PopoverBody } from 'reactstrap';
import CustomModal from 'common/custommodal';

import { history } from 'helpers/history';

const PageActionButtons = (props) => {
  const {
    setPage,
    setSelected,
    page,
    rowsPerPage,
    keyword,
    row,
    rowLength,
    changePostStatus,
    deletePosts,
  } = props;

  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);

  const [modal1, setModal1] = React.useState(false);
  const toggle1 = () => setModal1(!modal1);

  const [popover, setPopover] = React.useState(false);
  const togglePopover = () => setPopover(!popover);

  const onDelete = () => {
    toggle1();
    if (rowLength === 1 && page !== 0) {
      setPage(page - 1);
      deletePosts(
        { id: row.id },
        page,
        rowsPerPage,
        keyword,
        '',
        'post',
        props.otherProps.status
      );
    } else
      deletePosts(
        { id: row.id },
        page + 1,
        rowsPerPage,
        keyword,
        '',
        'post',
        props.otherProps.status
      );
  };

  const onTrash = () => {
    toggle();
    if (rowLength === 1 && page !== 0) {
      setPage(page - 1);
      changePostStatus(
        { id: row.id, status: 'trash' },
        page,
        rowsPerPage,
        keyword,
        '',
        'post',
        props.otherProps.status
      );
    } else
      changePostStatus(
        { id: row.id, status: 'trash' },
        page + 1,
        rowsPerPage,
        keyword,
        '',
        'post',
        props.otherProps.status
      );
    setSelected([]);
  };

  return (
    <div>
      <IconButton
        aria-label='key'
        className='no-border-onfocus'
        onClick={togglePopover}
        id={`Popover-${row.id}`}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popover
        placement='left'
        trigger='legacy'
        isOpen={popover}
        target={`Popover-${row.id}`}
        toggle={togglePopover}
      >
        <PopoverBody>
          {props.otherProps.status !== 'trash' && (
            <>
              <a
                role='button'
                className='anchoreless-button'
                onClick={(e) => {
                  e.stopPropagation();
                  const win = window.open(
                    props.site.website_url + row.slug,
                    '_blank'
                  );
                  if (win != null) {
                    win.focus();
                  }
                }}
              >
                <VisibilityIcon /> View Post
              </a>

              <a
                role='button'
                className='anchoreless-button'
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(row.id);
                }}
              >
                <EditIcon /> Edit Post
              </a>
            </>
          )}

          <a
            role='button'
            className='anchoreless-button'
            onClick={(e) => {
              togglePopover();
              e.stopPropagation();
              changePostStatus(
                { id: row.id, status: 'draft' },
                page + 1,
                rowsPerPage,
                keyword,
                '',
                'post',
                props.otherProps.status
              );
            }}
          >
            <SaveIcon />{' '}
            {props.otherProps.status !== 'trash' ? 'Move to Draft' : 'Restore'}
          </a>

          {props.otherProps.status !== 'trash' && (
            <a
              role='button'
              className='anchoreless-button'
              onClick={(e) => {
                togglePopover();
                e.stopPropagation();
                toggle();
              }}
            >
              <DeleteIcon /> Move to Trash
            </a>
          )}
          {row.post_status === 'trash' && (
            <a
              role='button'
              className='anchoreless-button'
              onClick={(e) => {
                togglePopover();
                e.stopPropagation();
                toggle1();
              }}
            >
              <DeleteIcon /> Delete Permanently
            </a>
          )}
        </PopoverBody>
      </Popover>
      <CustomModal
        onModalAccept={onTrash}
        onModalCancel={toggle}
        modal={modal}
        toggle={toggle}
        modalTitle='Move to Trash'
        modalBody='Are you sure you want to trash this post?'
      />
      <CustomModal
        onModalAccept={onDelete}
        onModalCancel={toggle1}
        modal={modal1}
        toggle={toggle1}
        modalTitle='Delete'
        modalBody='Are you sure you want to delete?'
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.siteSelector.selectedSite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePostStatus: (
      postData,
      pageNo,
      perPage,
      keyword,
      order,
      postType,
      post_status
    ) => {
      dispatch(
        changePostStatus(
          postData,
          pageNo,
          perPage,
          keyword,
          order,
          postType,
          post_status
        )
      );
    },
    deletePosts: (
      postData,
      pageNo,
      perPage,
      keyword,
      order,
      postType,
      post_status
    ) => {
      dispatch(
        deletePosts(
          postData,
          pageNo,
          perPage,
          keyword,
          order,
          postType,
          post_status
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageActionButtons);
