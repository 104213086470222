import React from "react";
import { connect } from "react-redux";
import {
  Nav,
  NavItem,
  NavLink,
  Button,
  Navbar,
  NavbarBrand,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  UncontrolledCarousel,
  Progress,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import MenuIcon from "@material-ui/icons/Menu";
import { logout } from "app/auth/actions/authActions";

import { fetchUserDetail } from "app/auth/actions/userDetailActions";
import { history } from "helpers/history";

import * as data from "./data"; //remove this later

//icons
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddIcon from "@material-ui/icons/Add";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PostAddIcon from "@material-ui/icons/PostAdd";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import DescriptionIcon from "@material-ui/icons/Description";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import LanguageIcon from "@material-ui/icons/Language";
import PersonIcon from "@material-ui/icons/Person";

const mapStateToProps = (state) => ({
  ...state,
});

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onQuickAddMouseEnter = this.onQuickAddMouseEnter.bind(this);
    this.onQuickAddMouseLeave = this.onQuickAddMouseLeave.bind(this);
    this.state = { isOpen: false, isQuickAddOpen: false };
  }
  componentDidMount() {
    this.props.fetchUserDetail();
    window.addEventListener("scroll", this.toggleBodyClass);
    this.toggleBodyClass();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.toggleBodyClass);
  }

  toggleBodyClass = () => {
    if (window.scrollY < 100) {
      document.body.classList.remove("scroll-body");
    } else {
      document.body.classList.add("scroll-body");
    }
  };

  // toggle = () => this.setState({ ...this.state, isOpen: !this.state.isOpen });

  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  showMobilemenu() {
    document.getElementById("main-wrapper").classList.toggle("show-sidebar");
  }

  toggle() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
      isQuickAddOpen: !prevState.isQuickAddOpen,
    }));
  }

  onMouseEnter() {
    this.setState({ isOpen: true });
  }

  onMouseLeave() {
    this.setState({ isOpen: false });
  }

  onQuickAddMouseEnter() {
    this.setState({ isQuickAddOpen: true });
  }

  onQuickAddMouseLeave() {
    this.setState({ isQuickAddOpen: false });
  }

  render() {
    const { userData } = this.props.authUser;
    const siteCode = this.props.siteSelector.selectedSite.website_domain;

    return (
      <header className="topbar navbarbg">
        <span className="nav-toggler d-block d-lg-none float-left">
          <MenuIcon
            style={{ cursor: "pointer" }}
            onClick={this.showMobilemenu}
          />
        </span>
        <Navbar
          className={
            "top-navbar " +
            (this.props.settings.activeNavbarBg === "skin6"
              ? "navbar-light"
              : "navbar-dark")
          }
          expand="md"
        >
          <div className="navbar-header" id="logobg">
            {/*--------------------------------------------------------------------------------*/}
            {/* Mobile View Toggler  [visible only after 991px screen]                         */}
            {/*--------------------------------------------------------------------------------*/}

            {/*--------------------------------------------------------------------------------*/}
            {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
            {/*--------------------------------------------------------------------------------*/}
            {/* <NavbarBrand href="/">
							<b className="logo-icon"> */}
            {/* <img src={logodarkicon} alt="homepage" className="dark-logo" /> */}
            {/* <img
									src={logodarkicon}
									alt="homepage"
									className="light-logo"
									width="35px"
									height="35px"
								/>
							</b>
						</NavbarBrand> */}
            {/*--------------------------------------------------------------------------------*/}
            {/* Mobile View Toggler  [visible only after 768px screen]                         */}
            {/*--------------------------------------------------------------------------------*/}
            {/* <span className="topbartoggler d-block d-lg-none" onClick={this.toggle}>
							<MoreVertIcon fontSize='small' style={{ cursor: 'pointer' }} />
						</span> */}
          </div>
          {/* <SiteSelector /> */}
          <div className="navbarbg">
            <Nav className="ml-auto float-left" navbar>
              {/*--------------------------------------------------------------------------------*/}
              {/* Start +QuickAdd Dropdown                                                   */}
              {/*--------------------------------------------------------------------------------*/}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <AddIcon />
                </DropdownToggle>
                <DropdownMenu right className="mailbox">
                  <div className="message-center">
                    <span
                      className="message-item"
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`/site/${siteCode}/cms/post/add`);
                      }}
                    >
                      <PostAddIcon />
                      <div className="mail-contnet">
                        <span className="sidebar-sublink-text">Post</span>
                      </div>
                    </span>

                    <span
                      className="message-item"
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`/site/${siteCode}/cms/post/add`);
                      }}
                    >
                      <DescriptionIcon />
                      <div className="mail-contnet">
                        <span className="sidebar-sublink-text">Page</span>
                      </div>
                    </span>

                    <span
                      className="message-item"
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`/site/${siteCode}/cms/media/add`);
                      }}
                    >
                      <PermMediaIcon />
                      <div className="mail-contnet">
                        <span className="sidebar-sublink-text">Media</span>
                      </div>
                    </span>

                    <span
                      className="message-item"
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push("/manage/user/add");
                      }}
                    >
                      <PeopleAltIcon />
                      <div className="mail-contnet">
                        <span className="sidebar-sublink-text">User</span>
                      </div>
                    </span>

                    <span
                      className="message-item"
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push("/manage/website/add");
                      }}
                    >
                      <LanguageIcon />
                      <div className="mail-contnet">
                        <span className="sidebar-sublink-text">Website</span>
                      </div>
                    </span>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
              {/*--------------------------------------------------------------------------------*/}
              {/* End +QuickAdd Dropdown                                                     */}
              {/*--------------------------------------------------------------------------------*/}
              {/*--------------------------------------------------------------------------------*/}
              {/* Start VisitWebsite Dropdown                                                   */}
              {/*--------------------------------------------------------------------------------*/}
              <UncontrolledDropdown nav inNavbar>
                <a
                  href={this.props.siteSelector.selectedSite.website_url}
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ alignSelf: "center", color: "white" }}
                  className="dropdown-toggle nav-link"
                >
                  <VisibilityIcon />
                </a>
              </UncontrolledDropdown>
              {/*--------------------------------------------------------------------------------*/}
              {/* End VisitWebsite Dropdown                                                     */}
              {/*--------------------------------------------------------------------------------*/}
              {/*--------------------------------------------------------------------------------*/}
              {/* Start Notification Dropdown                                                        */}
              {/*--------------------------------------------------------------------------------*/}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <NotificationsIcon />
                </DropdownToggle>
                <DropdownMenu right className="mailbox" style={{ width: 350 }}>
                  <div className="d-flex no-block align-items-center p-3 bg-light">
                    <h4 className="mb-0 font-medium">5 New Messages</h4>
                  </div>
                  <div className="message-center message-body">
                    {/*<!-- Message -->*/}
                    {data.messages.map((message, index) => {
                      return (
                        <span href="" className="message-item" key={index}>
                          <span className="user-img">
                            <img
                              src={message.image}
                              alt="user"
                              className="rounded-circle"
                              width=""
                            />
                            <span
                              className={
                                "profile-status pull-right " + message.status
                              }
                            ></span>
                          </span>
                          <div className="mail-contnet">
                            <h3 className="message-title">{message.title}</h3>
                            <span className="mail-desc">{message.desc}</span>
                            <span className="time">{message.time}</span>
                          </div>
                        </span>
                      );
                    })}
                  </div>
                  <a
                    className="nav-link text-center link text-dark"
                    href="google.com"
                  >
                    <b style={{ color: "black" }}>See all Notifications</b>
                  </a>
                </DropdownMenu>
              </UncontrolledDropdown>
              {/*--------------------------------------------------------------------------------*/}
              {/* End Notification Dropdown                                                          */}
              {/*--------------------------------------------------------------------------------*/}
              {/*--------------------------------------------------------------------------------*/}
              {/* Start Logout Dropdown                                                        */}
              {/*--------------------------------------------------------------------------------*/}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <span>Hi, {userData && userData.user_display_name}</span>
                  {userData && (
                    <img
                      src={userData.user_profile}
                      className="topbar-user-avatar"
                    />
                  )}
                </DropdownToggle>

                <DropdownMenu right className="mailbox">
                  <div className="message-center">
                    <span
                      className="message-item"
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push("/manage/user/me");
                      }}
                    >
                      <PersonIcon />
                      <div className="mail-contnet">
                        <span className="sidebar-sublink-text">My Profile</span>
                      </div>
                    </span>

                    <span className="message-item" onClick={this.props.logout}>
                      <ExitToAppIcon />
                      <div className="mail-contnet">
                        <span className="sidebar-sublink-text">Logout</span>
                      </div>
                    </span>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
              {/*--------------------------------------------------------------------------------*/}
              {/* End Logout Dropdown                                                          */}
              {/*--------------------------------------------------------------------------------*/}
            </Nav>
          </div>
        </Navbar>
      </header>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
    fetchUserDetail: () => {
      dispatch(fetchUserDetail());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
