import { AUTH_STATE_CHANGED, LOGIN_PENDING, LOGIN_ERROR } from '../actions/types';

const initialState = {
    isLoginPending: false,
    loginError: null,
    isAuthenticated: false,
    isInitialised: false,
    user: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_PENDING:
            return {
                ...state,
                isLoginPending: action.payload
            };

        case LOGIN_ERROR:
            return {
                ...state,
                loginError: action.payload
            };

        case AUTH_STATE_CHANGED:
            const { isAuthenticated, user } = action.payload;

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user
            };

        default:
            return state;
    }
}

