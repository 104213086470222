//Import Components for Site Settings Nav
import NotFound from 'common/NotFound';
import Permalink from 'app/site/settings/seo/views/Permalink';
import General from 'app/site/settings/general/views/General';
import Themes from 'app/site/settings/appearance/views/Themes';
import SeoGeneral from 'app/site/settings/seo/views/SeoGeneral';
import SeoTitlesMetas from 'app/site/settings/seo/views/SeoTitlesMetas';

//Import icons for Site Settings Nav
import SettingsIcon from '@material-ui/icons/Settings';
import DescriptionIcon from '@material-ui/icons/Description';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import BrushIcon from '@material-ui/icons/Brush';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SecurityIcon from '@material-ui/icons/Security';
import SpeedIcon from '@material-ui/icons/Speed';
import MenuIcon from '@material-ui/icons/Menu';
import WidgetsIcon from '@material-ui/icons/Widgets';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import ShareIcon from '@material-ui/icons/Share';
import ComputerIcon from '@material-ui/icons/Computer';

const sitesettingsroutes = [
  {
    path: '/site/settings',
    name: 'Site Settings',
    title: 'Site Settings',
    id: 'site-settings',
    icon: SettingsIcon,
    child: [
      {
        path: '/site/:sitecode/settings/general',
        name: 'General',
        title: 'General Settings',
        icon: SettingsIcon,
        component: General,
      },
      {
        path: '/site/:sitecode/settings/menu',
        name: 'Menu',
        title: 'Manage Menu',
        icon: MenuIcon,
        component: NotFound,
      },
      {
        path: '/site/:sitecode/settings/widgets',
        name: 'Widgets',
        title: 'Manage Widgets',
        icon: WidgetsIcon,
        component: NotFound,
      },
      {
        collapse: true,
        name: 'Appearance',
        icon: BrushIcon,
        state: 'Appearance',
        subchild: [
          {
            path: '/site/:sitecode/settings/appearance/themes',
            name: 'Themes',
            title: 'Manage Theme',
            component: Themes,
          },
          {
            path: '/site/:sitecode/settings/appearance/theme-options',
            name: 'Theme Options',
            title: 'Theme Options',
            component: NotFound,
          },
          {
            path: '/site/:sitecode/settings/appearance/header',
            name: 'Header',
            title: 'Header Builder',
            component: NotFound,
          },
          {
            path: '/site/:sitecode/settings/appearance/footer',
            name: 'Footer',
            title: 'Footer Builder',
            component: NotFound,
          },
          {
            path: '/site/:sitecode/settings/appearance/media',
            name: 'Media',
            title: 'Media Settings',
            component: NotFound,
          },
          {
            path: '/site/:sitecode/settings/appearance/custom-css',
            name: 'Custom CSS',
            title: 'Edit Custom CSS',
            component: NotFound,
          },
        ],
      },
      {
        collapse: true,
        name: 'Content',
        icon: DescriptionIcon,
        state: 'Content',
        subchild: [
          {
            path: '/site/:sitecode/settings/content/editor',
            name: 'Editor',
            title: 'Editor Settings',
            component: NotFound,
          },
          {
            path: '/site/:sitecode/settings/content/writing',
            name: 'Writing',
            title: 'Writing Settings',
            component: NotFound,
          },
          {
            path: '/site/:sitecode/settings/content/reading',
            name: 'Reading',
            title: 'Reading Settings',
            component: NotFound,
          },
          {
            path: '/site/:sitecode/settings/content/discussion',
            name: 'Discussion',
            title: 'Discussion Settings',
            component: NotFound,
          },
          {
            path: '/site/:sitecode/settings/content/privacy',
            name: 'Privacy',
            title: 'Privacy Settings',
            component: NotFound,
          },
        ],
      },
      {
        collapse: true,
        name: 'SEO',
        icon: TrendingUpIcon,
        state: 'SEO',
        subchild: [
          {
            path: '/site/:sitecode/settings/seo/general',
            name: 'General',
            title: 'General SEO Settings',
            // Webmaster Tools, Robot Settings, Schema.org Settings, Social Media Settings
            component: SeoGeneral,
          },
          {
            path: '/site/:sitecode/settings/seo/permalink',
            name: 'Permalinks',
            title: 'Permalinks Settings',
            component: Permalink,
          },
          {
            path: '/site/:sitecode/settings/seo/titles-meta',
            name: 'Titles & Meta',
            title: 'Titles & Meta Settings',
            component: SeoTitlesMetas,
            // Global Meta (seprator, noindex empty category and tags, local ka global me, posts ), Homepage Settings
          },
          {
            path: '/site/:sitecode/settings/seo/sitemap',
            name: 'Sitemap',
            title: 'Create Sitemap',
            component: NotFound,
          },
          {
            path: '/site/:sitecode/settings/seo/redirections',
            name: 'Redirections',
            title: 'Manage Redirects',
            component: NotFound,
          },
          {
            path: '/site/:sitecode/settings/seo/robotstxt',
            name: 'Robots.txt',
            title: 'Edit Robots.txt',
            component: NotFound,
          },
        ],
      },
      {
        path: '/site/:sitecode/settings/sharing',
        name: 'Sharing',
        title: 'Sharing Settings',
        icon: ShareIcon,
        component: NotFound,
      },
      {
        path: '/site/:sitecode/settings/email',
        name: 'Email',
        title: 'Email Settings',
        icon: AlternateEmailIcon,
        component: NotFound,
      },
      {
        path: '/site/:sitecode/settings/performance',
        name: 'Performance',
        title: 'Performance Settings',
        icon: SpeedIcon,
        component: NotFound,
      },
      {
        path: '/site/:sitecode/settings/security',
        name: 'Security',
        title: 'Security Settings',
        icon: SecurityIcon,
        component: NotFound,
      },
      {
        path: '/site/:sitecode/settings/maintenance',
        name: 'Maintenance',
        title: 'Maintenance Settings',
        icon: ComputerIcon,
        component: NotFound,
      },
      {
        collapse: true,
        name: 'Site Users',
        icon: PeopleAltIcon,
        state: 'Users',
        subchild: [
          {
            path: '/site/:sitecode/settings/user/list',
            name: 'All Users',
            title: 'Manage Site Users',
            component: NotFound,
          },
          {
            path: '/site/:sitecode/settings/user/add',
            name: 'Add New',
            title: 'Add New Site User',
            component: NotFound,
          },
          {
            path: '/site/:sitecode/settings/user/roles',
            name: 'Roles',
            title: 'Manage User Roles',
            component: NotFound,
          },
          {
            path: '/site/:sitecode/settings/user/permissions',
            name: 'Permissions',
            title: 'Manage User Permissions',
            component: NotFound,
          },
        ],
      },
    ],
  },
];

export default sitesettingsroutes;
