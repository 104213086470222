import React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'hopcms-ui';
import MuiTable from 'common/mui-table';
import { connect } from 'react-redux';
import {
  fetchCategories,
  setFetchCategoryError,
  deleteCategories,
} from '../actions/categoryActions';
import CategoryActionButtons from './CategoryActionButtons';
import OneCategory from './OneCategory';
import CategorySkeleeton from './CategorySkeleeton';

class CategoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { site: '', modal: false };
  }

  componentDidMount() {
    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
      });
      this.props.fetchCategories();
    }
  }

  componentDidUpdate() {
    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
      });
      this.props.fetchCategories();
    }
  }

  render() {
    const headCells = [
      // { id: "id", numeric: true, disablePadding: false, label: "#" },
      { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
      {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description',
      },
      { id: 'slug', numeric: false, disablePadding: false, label: 'Slug' },
      { id: 'count', numeric: false, disablePadding: false, label: 'Count' },
      { id: 'action', numeric: true, disablePadding: false, label: 'Action' },
    ];

    const createData = (id, name, description, slug, count) => {
      return { id, name, description, slug, count };
    };

    const onReload = (rowsPerPage, keyword) => {
      this.props.fetchCategories(1, rowsPerPage, keyword);
    };

    const handlePaginationChange = (pageNo, perPage, keyword) => {
      this.props.fetchCategories(pageNo, perPage, keyword);
    };

    const onMultiDelete = (postData, pageNo, perPage, keyword) => {
      this.props.deleteCategories(postData, pageNo, perPage, keyword);
    };

    const onSearch = (keyword, perPage) => {
      if (keyword.length > 2 || keyword.length === 0)
        this.props.fetchCategories(1, perPage, keyword);
    };

    var rows = [];
    if (this.props.categories) {
      this.props.categories.map((category) =>
        rows.push(
          createData(
            category.id,
            category.name,
            category.description,
            category.slug,
            category.post_count
          )
        )
      );
    }

    const toggle = () =>
      this.setState({ ...this.state, modal: !this.state.modal });

    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Create New Category</ModalHeader>
          <ModalBody>
            <OneCategory id='add' toggle={toggle} />
          </ModalBody>
        </Modal>
        <div className='page-header'>
          <Row>
            <Col className='page-title'>
              <h1>{this.props.title}</h1>
            </Col>
            <Col className='text-right page-header-button'>
              <button className='mb-3 btn btn-primary' onClick={toggle}>
                Add Category
              </button>
            </Col>
          </Row>
        </div>
        <div className='content-wrapper'>
          <MuiTable
            headCells={headCells}
            rows={rows}
            onReload={onReload} // comment this to disable refresh option
            isFetchPending={this.props.isFetchCategoryPending}
            // chooseColumn={false}   //=>to false display column option
            noOfRecords={this.props.noOfRecords}
            handlePaginationChange={handlePaginationChange}
            multipleDelete
            onMultiDelete={onMultiDelete}
            onSearch={onSearch}
            CustomSkeleton={CategorySkeleeton}
            Actions={CategoryActionButtons}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchCategoriesuccess: state.category.isFetchCategoriesuccess,
    isFetchCategoryPending: state.category.isFetchCategoryPending,
    fetchCategoryError: state.category.fetchCategoryError,
    categories: state.category.categories,
    noOfRecords: state.category.noOfRecords,
    site: state.siteSelector.selectedSite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCategories: (pageNo, perPage, keyword) => {
      dispatch(fetchCategories(pageNo, perPage, keyword));
    },
    setFetchCategoryError: (postData) => {
      dispatch(setFetchCategoryError(postData));
    },
    deleteCategories: (postData, pageNo, perPage, keyword) => {
      dispatch(deleteCategories(postData, pageNo, perPage, keyword));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
