import React, { Fragment } from 'react'
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody
} from 'reactstrap';
import { connect } from 'react-redux';
import { uploadFile } from '../actions/mediaActions';
import { history } from 'helpers/history';
import { appSecretKey } from 'utils/axios'
import FilesDropzone from 'common/FilesDropzone';
import { IconButton, Tooltip } from '@material-ui/core'
import MediaEdit from './MediaEdit';

import EditIcon from '@material-ui/icons/Edit';


const CustomAction = (props) => {
    const [modal1, setModal1] = React.useState(false);
    const toggle1 = () => setModal1(!modal1)

    const [deleted, setDeleted] = React.useState(false);

    const oneMedia = {};

    if (props.allMedia.length === 0 || !props.allMedia.find(media => media.id === props.oneMedia.id)) {
        oneMedia.id = props.oneMedia.id
        oneMedia.altText = props.oneMedia.image_alt
        oneMedia.titleOnly = props.oneMedia.post_title
        oneMedia.caption = props.oneMedia.post_excerpt
        oneMedia.description = props.oneMedia.post_content
        oneMedia.slug = props.oneMedia.post_guid
        oneMedia.postMimeType = props.oneMedia.post_mime_type
        oneMedia.date = props.oneMedia.post_created.split(" ")[0]
        oneMedia.meta = props.oneMedia.meta
        oneMedia.author = props.oneMedia.post_author
    }
    else {
        const updatedOneMedia = props.allMedia.find(media => media.id === props.oneMedia.id)
        oneMedia.id = updatedOneMedia.id
        oneMedia.altText = updatedOneMedia.image_alt
        oneMedia.titleOnly = updatedOneMedia.post_title
        oneMedia.caption = updatedOneMedia.post_excerpt
        oneMedia.description = updatedOneMedia.post_content
        oneMedia.slug = updatedOneMedia.post_guid
        oneMedia.postMimeType = updatedOneMedia.post_mime_type
        oneMedia.date = updatedOneMedia.post_created.split(" ")[0]
        oneMedia.meta = updatedOneMedia.meta
        oneMedia.author = updatedOneMedia.post_author
    }


    return (
        !deleted &&
        <Fragment>
            <Tooltip title="Edit Media">
                <IconButton edge="end" onClick={toggle1}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <Modal style={{ maxWidth: '100%', width: '95%', margin: '10px auto' }} size='xl' isOpen={modal1} toggle={toggle1}>
                <ModalHeader toggle={toggle1}>Update Media</ModalHeader>
                <ModalBody>
                    <MediaEdit
                        id={oneMedia.id}
                        page={1}
                        rowsPerPage={100}
                        oneMedia={oneMedia}
                        toggle={toggle1}
                        afterDelete={() => setDeleted(!deleted)}
                    />
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

class OneMedia extends React.Component {

    render() {

        const { uploadFile } = this.props;

        const onClickHandler = (files, cb) => {
            var data = new FormData()
            files.map((file, i) => {
                data.append(`media${i}`, file, file.name)
            })
            data.append('_token', appSecretKey)
            uploadFile(data, (successList, failedList) => cb(successList, failedList))
        }

        return (
            <div>
                <div className="page-header">
                    <Row>
                        <Col className="page-title">
                            <h1>{this.props.title}</h1>
                        </Col>
                        <Col className="text-right page-header-button">
                            <button className="btn btn-primary" onClick={() => { history.replace("library") }}>Media Library</button>
                        </Col>
                    </Row>
                </div>
                <div className="content-wrapper">
                    <Card>
                        <CardTitle>
                            Upload New Media
                        </CardTitle>
                        <CardBody>
                            <FilesDropzone onUploadClick={onClickHandler} CustomAction={CustomAction} />
                        </CardBody>
                    </Card>
                </div>
                {/* <Card>
                    <CardTitle>
                        Upload New Media
                </CardTitle>
                    <CardBody>
                        <FilesDropzone onUploadClick={onClickHandler} />
                    </CardBody>
                </Card> */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        media: state.media.media,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        uploadFile: (postData, cb) => { dispatch(uploadFile(postData, cb)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OneMedia);