import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { deleteUsers } from "../actions/userActions";
import { connect } from "react-redux";
import { history } from "helpers/history";
import CustomModal from "common/custommodal";
import { Popover, PopoverBody } from "reactstrap";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const UserActions = (props) => {
  const {
    setPage,
    setSelected,
    page,
    rowsPerPage,
    keyword,
    row,
    rowLength,
    deleteUsers,
  } = props;

  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);

  const [popover, setPopover] = React.useState(false);
  const togglePopover = () => setPopover(!popover);

  const onDelete = () => {
    toggle();
    if (rowLength === 1 && page !== 0) {
      setPage(page - 1);
      deleteUsers({ id: row.id }, page, rowsPerPage, keyword);
    } else deleteUsers({ id: row.id }, page + 1, rowsPerPage, keyword);
    setSelected([]);
  };

  return (
    <div>
      <IconButton
        aria-label="key"
        className="no-border-onfocus"
        onClick={togglePopover}
        id={`Popover-${row.id}`}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popover
        placement="left"
        trigger="legacy"
        isOpen={popover}
        target={`Popover-${row.id}`}
        toggle={togglePopover}
      >
        <PopoverBody>
          <a
            role="button"
            className="anchoreless-button"
            onClick={(e) => {
              e.stopPropagation();
              history.push(row.id);
            }}
          >
            <EditIcon /> Edit
          </a>
          <a
            role="button"
            className="anchoreless-button"
            onClick={(e) => {
              e.stopPropagation();
              toggle();
            }}
          >
            <DeleteIcon /> Delete
          </a>
        </PopoverBody>
      </Popover>

      <CustomModal
        onModalAccept={onDelete}
        onModalCancel={toggle}
        modal={modal}
        toggle={toggle}
        modalTitle="Delete"
        modalBody="Are you sure you want to delete?"
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteUsers: (postData, pageNo, perPage, keyword) => {
      dispatch(deleteUsers(postData, pageNo, perPage, keyword));
    },
  };
};

export default connect(null, mapDispatchToProps)(UserActions);
