import React from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  FormText,
  Label,
  Input,
  Row,
  Col,
} from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CustomSpinner from 'common/CustomSpinner';
import { connect } from 'react-redux';
import {
  updateGeneralSetting,
  fetchGeneralSetting,
} from '../actions/generalActions';
import Select from 'react-select';

import timeZones from './timezones';

const default_role = [
  { value: 'subscriber', label: 'Subscriber' },
  { value: 'contributor', label: 'Contributor' },
  { value: 'author', label: 'Author' },
  { value: 'editor', label: 'Editor' },
  { value: 'administrator', label: 'Administrator' },
];

const language = [
  { value: 'en', label: 'English' },
  { value: 'en-GB', label: 'English (UK)' },
  { value: 'fr-FR', label: 'Français' },
  { value: 'de-DE', label: 'Deutsch' },
];

const charset = [{ value: 'utf-8', label: 'UTF-8' }];

const start_of_week = [
  { value: '0', label: 'Sunday' },
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tuesday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' },
];

class General extends React.Component {
  constructor(props) {
    super(props);
    this.setDatePreview = this.setDatePreview.bind(this);
    this.state = {
      site: '',
      datePreview: '',
      timePreview: '',
      today: new Date(),
    };
  }

  componentDidMount() {
    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
      });
      this.props.fetchGeneralSetting();
    }
  }

  componentDidUpdate() {
    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
      });
      this.props.fetchGeneralSetting();
    }
    this.state.datePreview === '' &&
      this.props.generalSettingData.length !== 0 &&
      this.setDatePreview(this.props.generalSettingData.date_format);

    this.state.timePreview === '' &&
      this.props.generalSettingData.length !== 0 &&
      this.setTimePreview(this.props.generalSettingData.time_format);
  }

  setDatePreview(structure) {
    let preview = ' ';
    let today = this.state.today;
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    switch (structure) {
      case 'F j, Y':
        preview =
          monthNames[today.getMonth()] +
          ' ' +
          today.getDate() +
          ' ,' +
          today.getFullYear();
        break;

      case 'Y-m-d':
        preview =
          today.getFullYear() +
          '-' +
          ('0' + today.getMonth()).slice(-2) +
          '-' +
          today.getDate();
        break;

      case 'm/d/Y':
        preview =
          ('0' + today.getMonth()).slice(-2) +
          '/' +
          today.getDate() +
          '/' +
          today.getFullYear();
        break;

      case 'd/m/Y':
        preview =
          today.getDate() +
          '/' +
          ('0' + today.getMonth()).slice(-2) +
          '/' +
          today.getFullYear();
        break;

      case 'd-m-Y':
        preview =
          today.getDate() +
          '-' +
          ('0' + today.getMonth()).slice(-2) +
          '-' +
          today.getFullYear();
        break;

      default:
        return true;
    }

    this.setState({
      ...this.state,
      datePreview: preview,
    });
  }

  setTimePreview(timestructure) {
    let preview = ' ';
    let today = this.state.today;

    switch (timestructure) {
      case 'g:i a':
        preview = today
          .toLocaleTimeString()
          .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3')
          .toLowerCase();
        break;

      case 'g:i A':
        preview = today
          .toLocaleTimeString()
          .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3');
        break;

      case 'H:i':
        preview = ('0' + today.getHours()).slice(-2) + ':' + today.getMinutes();
        break;

      default:
        preview = today
          .toLocaleTimeString()
          .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3')
          .toLowerCase();
        break;
    }
    this.setState({
      ...this.state,
      timePreview: preview,
    });
  }

  render() {
    const {
      generalSettingData,
      updateGeneralSetting,
      isGeneralSettingDataPending,
      isUpdateGeneralSettingPending,
      site,
    } = this.props;

    const onDateRadioChangeHandler = (id, setFieldValue) => {
      let structure = '';

      switch (id) {
        case 0:
          structure = 'F j, Y';
          break;

        case 1:
          structure = 'Y-m-d';
          break;

        case 2:
          structure = 'm/d/Y';
          break;

        case 3:
          structure = 'd/m/Y';
          break;

        case 4:
          structure = 'd-m-Y';
          break;

        default:
          return true;
      }
      setFieldValue('date_format', structure);
      this.setDatePreview(structure);
    };

    const ifDateChecked = (id, structure) => {
      switch (id) {
        case 0:
          return structure === 'F j, Y' ? true : false;

        case 1:
          return structure === 'Y-m-d' ? true : false;

        case 2:
          return structure === 'm/d/Y' ? true : false;

        case 3:
          return structure === 'd/m/Y' ? true : false;

        case 4:
          return (
            structure !== 'F j, Y' &&
            structure !== 'Y-m-d' &&
            structure !== 'm/d/Y' &&
            structure !== 'd/m/Y'
          );

        default:
          return true;
      }
    };

    const onTimeRadioChangeHandler = (id, setFieldValue) => {
      let structure = '';

      switch (id) {
        case 0:
          structure = 'g:i a';
          break;

        case 1:
          structure = 'g:i A';
          break;

        case 2:
          structure = 'H:i';
          break;

        case 3:
          structure = 'h:i a';
          break;

        default:
          return true;
      }
      setFieldValue('time_format', structure);
      this.setTimePreview(structure);
    };

    const ifTimeChecked = (id, structure) => {
      switch (id) {
        case 0:
          return structure === 'g:i a' ? true : false;

        case 1:
          return structure === 'g:i A' ? true : false;

        case 2:
          return structure === 'H:i' ? true : false;

        case 3:
          return (
            structure !== 'g:i a' &&
            structure !== 'g:i A' &&
            structure !== 'H:i'
          );

        default:
          return true;
      }
    };

    if (
      !site ||
      (isGeneralSettingDataPending && !isUpdateGeneralSettingPending)
    ) {
      return <CustomSpinner />;
    }

    return (
      <div>
        <div>{isGeneralSettingDataPending && <CustomSpinner />}</div>

        <Formik
          enableReinitialize
          initialValues={{
            blogname: generalSettingData.blogname || '',
            blogdescription: generalSettingData.blogdescription || '',
            siteurl: generalSettingData.siteurl || site.website_url,
            admin_email: generalSettingData.admin_email || '',
            users_can_register: generalSettingData.users_can_register || '',
            default_role: generalSettingData.default_role || '',
            language: generalSettingData.language || '',
            charset: generalSettingData.charset || '',
            country: generalSettingData.country || '',
            timezone_string: generalSettingData.timezone_string || '',
            date_format: generalSettingData.date_format || '',
            time_format: generalSettingData.time_format || '',
            start_of_week: generalSettingData.start_of_week || '',
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={({
            blogname,
            blogdescription,
            siteurl,
            admin_email,
            users_can_register,
            default_role,
            language,
            charset,
            country,
            timezone_string,
            date_format,
            time_format,
            start_of_week,
          }) => {
            const postData = {
              blogname: blogname,
              blogdescription: blogdescription,
              siteurl: siteurl,
              admin_email: admin_email,
              users_can_register: users_can_register,
              default_role: default_role,
              language: language,
              charset: charset,
              country: country,
              timezone_string: timezone_string,
              date_format: date_format,
              time_format: time_format,
              start_of_week: start_of_week,
            };
            updateGeneralSetting(postData);
          }}
        >
          {({
            errors,
            touched,
            setFieldValue,
            values,
            setFieldTouched,
            dirty,
          }) => (
            <Form id='generalSettingForm'>
              <div className='page-header'>
                <Row>
                  <Col className='page-title'>
                    <h1>{this.props.title}</h1>
                  </Col>
                  <Col className='text-right page-header-button'>
                    <button
                      type='submit'
                      disabled={!dirty}
                      className='btn btn-primary'
                    >
                      Save Changes
                    </button>
                  </Col>
                </Row>
              </div>

              <div className='content-wrapper'>
                <Card>
                  <CardTitle>Website Settings</CardTitle>
                  <CardBody>
                    <Row>
                      <Col className='setting-label'>
                        <Label className='control-label' for='blogname'>
                          Site Title
                        </Label>
                      </Col>
                      <Col className='setting-value'>
                        <Field
                          id='blogname'
                          name='blogname'
                          type='text'
                          className={
                            'form-control' +
                            (errors.blogname && touched.blogname
                              ? ' is-invalid'
                              : '')
                          }
                        />
                        <ErrorMessage
                          name='blogname'
                          component='div'
                          className='invalid-feedback'
                        />
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col className='setting-label'>
                        <Label className='control-label' for='blogdescription'>
                          Tagline / Slogan
                        </Label>
                      </Col>
                      <Col className='setting-value'>
                        <Field
                          id='blogdescription'
                          name='blogdescription'
                          type='text'
                          className={
                            'form-control' +
                            (errors.blogdescription && touched.blogdescription
                              ? ' is-invalid'
                              : '')
                          }
                        />
                        <ErrorMessage
                          name='blogdescription'
                          component='div'
                          className='invalid-feedback'
                        />
                        <FormText color='muted'>
                          In a few words, explain what this site is about.
                        </FormText>
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col className='setting-label'>
                        <Label className='control-label' for='siteurl'>
                          Site Address (URL)
                        </Label>
                      </Col>
                      <Col className='setting-value'>
                        <Field
                          disabled
                          id='siteurl'
                          name='siteurl'
                          type='text'
                          className={
                            'form-control' +
                            (errors.siteurl && touched.siteurl
                              ? ' is-invalid'
                              : '')
                          }
                        />
                        <ErrorMessage
                          name='siteurl'
                          component='div'
                          className='invalid-feedback'
                        />
                        <FormText color='muted'>
                          You can change your site URL from Manage Webstie
                          section. Click here.
                        </FormText>
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col className='setting-label'>
                        <Label
                          className='control-label col-form-label'
                          for='admin_email'
                        >
                          Administration Email Address
                        </Label>
                      </Col>
                      <Col className='setting-value'>
                        <Field
                          id='admin_email'
                          name='admin_email'
                          type='email'
                          className={
                            'form-control' +
                            (errors.admin_email && touched.admin_email
                              ? ' is-invalid'
                              : '')
                          }
                        />
                        <ErrorMessage
                          name='admin_email'
                          component='div'
                          className='invalid-feedback'
                        />
                        <FormText color='muted'>
                          This address is used for admin purposes. If you change
                          this, we will send you an email at your new address to
                          confirm it. The new address will not become active
                          until confirmed.
                        </FormText>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardTitle>User Settings</CardTitle>
                  <CardBody>
                    <Row>
                      <Col className='setting-label'>
                        <Label className='control-label'>Membership</Label>
                      </Col>
                      <Col className='setting-value'>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type='checkbox'
                              checked={values.users_can_register === 'open'}
                              onChange={(e) =>
                                setFieldValue(
                                  'users_can_register',
                                  e.target.checked ? 'open' : 'close'
                                )
                              }
                            />{' '}
                            Anyone can register
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col className='setting-label'>
                        <Label className='control-label' for='default_role'>
                          New User Default Role
                        </Label>
                      </Col>
                      <Col className='setting-value'>
                        <Select
                          id='default_role'
                          options={default_role}
                          isSearchable={false}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              border: '1px solid #e9ecef',
                              borderRadius: '2px',
                            }),
                            singleValue: (provided, state) => ({
                              ...provided,
                              color: '#525f7f',
                            }),
                            valueContainer: (provided, state) => ({
                              ...provided,
                              padding: '0 0.75rem',
                            }),
                          }}
                          onChange={(option) => {
                            setFieldValue('default_role', option.value);
                          }}
                          onBlur={() => {
                            setFieldTouched('default_role');
                          }}
                          value={
                            default_role.find(
                              (option) => option.value === values.default_role
                            ) || ''
                          }
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardTitle>Localization Settings</CardTitle>
                  <CardBody>
                    <Row>
                      <Col className='setting-label'>
                        <Label className='control-label' for='language'>
                          Site Language
                        </Label>
                      </Col>
                      <Col className='setting-value'>
                        <Select
                          id='language'
                          options={language}
                          isSearchable={false}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              border: '1px solid #e9ecef',
                              borderRadius: '2px',
                            }),
                            singleValue: (provided, state) => ({
                              ...provided,
                              color: '#525f7f',
                            }),
                            valueContainer: (provided, state) => ({
                              ...provided,
                              padding: '0 0.75rem',
                            }),
                          }}
                          onChange={(option) => {
                            setFieldValue('language', option.value);
                          }}
                          onBlur={() => {
                            setFieldTouched('language');
                          }}
                          value={
                            language.find(
                              (option) => option.value === values.language
                            ) || ''
                          }
                        />
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col className='setting-label'>
                        <Label className='control-label' for='charset'>
                          Default Charset
                        </Label>
                      </Col>
                      <Col className='setting-value'>
                        <Select
                          id='charset'
                          options={charset}
                          isSearchable={false}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              border: '1px solid #e9ecef',
                              borderRadius: '2px',
                            }),
                            singleValue: (provided, state) => ({
                              ...provided,
                              color: '#525f7f',
                            }),
                            valueContainer: (provided, state) => ({
                              ...provided,
                              padding: '0 0.75rem',
                            }),
                          }}
                          onChange={(option) => {
                            setFieldValue('charset', option.value);
                          }}
                          onBlur={() => {
                            setFieldTouched('charset');
                          }}
                          value={
                            charset.find(
                              (option) => option.value === values.charset
                            ) || ''
                          }
                        />
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col className='setting-label'>
                        <Label className='control-label' for='timezone_string'>
                          Timezone
                        </Label>
                      </Col>

                      <Col className='setting-value'>
                        <Select
                          id='timezone_string'
                          options={timeZones}
                          isSearchable={true}
                          getOptionLabel={(option) => option.label}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              border: '1px solid #e9ecef',
                              borderRadius: '2px',
                            }),
                            singleValue: (provided, state) => ({
                              ...provided,
                              color: '#525f7f',
                            }),
                            valueContainer: (provided, state) => ({
                              ...provided,
                              padding: '0 0.75rem',
                            }),
                          }}
                          onChange={(option) => {
                            setFieldValue('timezone_string', option.value);
                          }}
                          onBlur={() => {
                            setFieldTouched('timezone_string');
                          }}
                          value={
                            timeZones.find(
                              (option) =>
                                option.value === values.timezone_string
                            ) || ''
                          }
                        />
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col className='setting-label'>
                        <Label className='control-label'>Date Format</Label>
                      </Col>

                      <Col className='setting-value'>
                        <FormGroup tag='fieldset'>
                          <FormGroup check>
                            <Row className='mb-2'>
                              <Col xs='5'>
                                <Label check>
                                  <Input
                                    type='radio'
                                    checked={ifDateChecked(
                                      0,
                                      values.date_format
                                    )}
                                    onChange={() =>
                                      onDateRadioChangeHandler(0, setFieldValue)
                                    }
                                    name='radio1'
                                  />{' '}
                                  June 27, 2020
                                </Label>
                              </Col>
                              <Col>
                                <code>F j, Y</code>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup check>
                            <Row className='mb-2'>
                              <Col xs='5'>
                                <Label check>
                                  <Input
                                    type='radio'
                                    checked={ifDateChecked(
                                      1,
                                      values.date_format
                                    )}
                                    onChange={() =>
                                      onDateRadioChangeHandler(1, setFieldValue)
                                    }
                                    name='radio1'
                                  />{' '}
                                  2020-06-27
                                </Label>
                              </Col>
                              <Col>
                                <code>Y-m-d</code>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup check>
                            <Row className='mb-2'>
                              <Col xs='5'>
                                <Label check>
                                  <Input
                                    type='radio'
                                    checked={ifDateChecked(
                                      2,
                                      values.date_format
                                    )}
                                    onChange={() =>
                                      onDateRadioChangeHandler(2, setFieldValue)
                                    }
                                    name='radio1'
                                  />{' '}
                                  06/27/2020
                                </Label>
                              </Col>
                              <Col>
                                <code>m/d/Y</code>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup check>
                            <Row className='mb-2'>
                              <Col xs='5'>
                                <Label check>
                                  <Input
                                    type='radio'
                                    checked={ifDateChecked(
                                      3,
                                      values.date_format
                                    )}
                                    onChange={() =>
                                      onDateRadioChangeHandler(3, setFieldValue)
                                    }
                                    name='radio1'
                                  />{' '}
                                  27/06/2020
                                </Label>
                              </Col>
                              <Col>
                                <code>d/m/Y</code>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup check>
                            <Row className='mb-2'>
                              <Col xs='5'>
                                <Label check>
                                  <Input
                                    type='radio'
                                    checked={ifDateChecked(
                                      4,
                                      values.date_format
                                    )}
                                    onChange={() =>
                                      onDateRadioChangeHandler(4, setFieldValue)
                                    }
                                    name='radio1'
                                  />{' '}
                                  Custom:
                                </Label>
                              </Col>
                              <Col xs='7'>
                                <Field
                                  name='date_format'
                                  type='text'
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                            <Row className='mb-2'>
                              <Label>
                                <strong>Preview: </strong>{' '}
                                <span id='previewDate'>
                                  {this.state.datePreview}
                                </span>
                              </Label>
                            </Row>
                          </FormGroup>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col className='setting-label'>
                        <Label className='control-label'>Time Format</Label>
                      </Col>
                      <Col className='setting-value'>
                        <FormGroup tag='fieldset'>
                          <FormGroup check>
                            <Row className='mb-2'>
                              <Col xs='5'>
                                <Label check>
                                  <Input
                                    type='radio'
                                    checked={ifTimeChecked(
                                      0,
                                      values.time_format
                                    )}
                                    onChange={() =>
                                      onTimeRadioChangeHandler(0, setFieldValue)
                                    }
                                    name='radio2'
                                  />{' '}
                                  8:22 am
                                </Label>
                              </Col>
                              <Col>
                                <code>g:i a</code>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup check>
                            <Row className='mb-2'>
                              <Col xs='5'>
                                <Label check>
                                  <Input
                                    type='radio'
                                    checked={ifTimeChecked(
                                      1,
                                      values.time_format
                                    )}
                                    onChange={() =>
                                      onTimeRadioChangeHandler(1, setFieldValue)
                                    }
                                    name='radio2'
                                  />{' '}
                                  8:22 AM
                                </Label>
                              </Col>
                              <Col>
                                <code>g:i A</code>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup check>
                            <Row className='mb-2'>
                              <Col xs='5'>
                                <Label check>
                                  <Input
                                    type='radio'
                                    checked={ifTimeChecked(
                                      2,
                                      values.time_format
                                    )}
                                    onChange={() =>
                                      onTimeRadioChangeHandler(2, setFieldValue)
                                    }
                                    name='radio2'
                                  />{' '}
                                  08:22
                                </Label>
                              </Col>
                              <Col>
                                <code>H:i</code>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup check>
                            <Row className='mb-2'>
                              <Col xs='5'>
                                <Label check>
                                  <Input
                                    type='radio'
                                    checked={ifTimeChecked(
                                      3,
                                      values.time_format
                                    )}
                                    onChange={() =>
                                      onTimeRadioChangeHandler(3, setFieldValue)
                                    }
                                    name='radio2'
                                  />{' '}
                                  Custom:
                                </Label>
                              </Col>
                              <Col xs='7'>
                                <Field
                                  name='time_format'
                                  type='text'
                                  className={'form-control'}
                                />
                              </Col>
                            </Row>
                            <Row className='mb-2'>
                              <Label>
                                <strong>Preview: </strong>
                                <span id='previewTime'>
                                  {this.state.timePreview}
                                </span>
                              </Label>
                            </Row>
                          </FormGroup>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col className='setting-label'>
                        <Label className='control-label' for='start_of_week'>
                          Week Starts On
                        </Label>
                      </Col>
                      <Col className='setting-value'>
                        <Select
                          id='start_of_week'
                          options={start_of_week}
                          isSearchable={false}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              border: '1px solid #e9ecef',
                              borderRadius: '2px',
                            }),
                            singleValue: (provided, state) => ({
                              ...provided,
                              color: '#525f7f',
                            }),
                            valueContainer: (provided, state) => ({
                              ...provided,
                              padding: '0 0.75rem',
                            }),
                          }}
                          onChange={(option) => {
                            setFieldValue('start_of_week', option.value);
                          }}
                          onBlur={() => {
                            setFieldTouched('start_of_week');
                          }}
                          value={
                            start_of_week.find(
                              (option) => option.value === values.start_of_week
                            ) || ''
                          }
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    generalSettingData: state.generalSetting.generalSettingData,
    isGeneralSettingDataPending:
      state.generalSetting.isGeneralSettingDataPending,
    isUpdateGeneralSettingPending:
      state.generalSetting.isUpdateGeneralSettingPending,
    site: state.siteSelector.selectedSite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGeneralSetting: (postData) => {
      dispatch(updateGeneralSetting(postData));
    },
    fetchGeneralSetting: () => {
      dispatch(fetchGeneralSetting());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(General);
