import React from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  CustomInput,
  FormText,
  Row,
  Col,
} from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CustomSpinner from 'common/CustomSpinner';
import { connect } from 'react-redux';
import {
  setUpdateSeoError,
  setUpdateSeoSuccess,
  updateSeo,
  fetchSeo,
} from '../actions/seoActions';

class SeoGeneral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      site: '',
    };
  }

  componentDidMount() {
    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
      });
      this.props.fetchSeo();
    }
  }

  componentDidUpdate() {
    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
      });
      this.props.fetchSeo();
    }
  }

  render() {
    const { seoData, updateSeo, isSeoDataPending, isUpdateSeoPending, site } =
      this.props;

    if (!site || (isSeoDataPending && !isUpdateSeoPending)) {
      return <CustomSpinner />;
    }

    return (
      <div>
        <div>{isSeoDataPending && <CustomSpinner />}</div>
        <Formik
          enableReinitialize
          initialValues={{
            blog_public: seoData.blog_public || 1,
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={({ blog_public }) => {
            const postData = {
              blog_public: blog_public,
            };
            updateSeo(postData);
          }}
        >
          {({ errors, touched, setFieldValue, values, setFieldTouched }) => (
            <Form id='seoForm'>
              <div className='page-header'>
                <Row>
                  <Col className='page-title'>
                    <h1>{this.props.title}</h1>
                  </Col>
                  <Col className='text-right page-header-button'>
                    <button type='submit' className='btn btn-primary'>
                      Save Changes
                    </button>
                  </Col>
                </Row>
              </div>
              <div className='content-wrapper'>
                <Card>
                  <CardTitle>General Settings</CardTitle>
                  <CardBody className=''>
                    <FormGroup tag='fieldset'>
                      <Row>
                        <Col className='setting-label'>
                          <FormGroup check>
                            <Label check className='control-label'>
                              Noindex Website
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col className='setting-value'>
                          <CustomInput
                            type='checkbox'
                            name='blog_public'
                            id='blog_public'
                            label='Discourage search engines from indexing this site'
                            checked={values.blog_public === '0'}
                            onChange={(e) =>
                              setFieldValue(
                                'blog_public',
                                e.target.checked ? '0' : '1'
                              )
                            }
                          />
                          <FormText color='muted'>
                            It is up to search engines to honor this request.
                          </FormText>
                        </Col>
                      </Row>
                    </FormGroup>
                  </CardBody>
                </Card>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    seoData: state.seo.seoData,
    isSeoDataPending: state.seo.isSeoDataPending,
    isUpdateSeoPending: state.seo.isUpdateSeoPending,
    isUpdateSeoSuccess: state.seo.isUpdateSeoSuccess,
    updateSeoError: state.seo.updateSeoError,
    site: state.siteSelector.selectedSite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUpdateSeoError: (updateSeoError) => {
      dispatch(setUpdateSeoError(updateSeoError));
    },
    setUpdateSeoSuccess: (isUpdateSeoSuccess) => {
      dispatch(setUpdateSeoSuccess(isUpdateSeoSuccess));
    },
    updateSeo: (postData) => {
      dispatch(updateSeo(postData));
    },
    fetchSeo: () => {
      dispatch(fetchSeo());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SeoGeneral);
