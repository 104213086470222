import React from 'react';
import {
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	CustomInput,
	FormGroup,
	Row,
	Col,
	UncontrolledTooltip,
	Button
} from 'reactstrap';
import img1 from 'assets/images/logo-icon.png';
import img2 from 'assets/images/background/login-register.jpg';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { login } from '../actions/authActions';
import { connect } from 'react-redux';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LockIcon from '@material-ui/icons/Lock';
import SvgIcon from '@material-ui/core/SvgIcon';


//custom icons
function FBIcon(props) {
	return (
		<SvgIcon {...props}>
			<path d="M 13.859375 24 L 13.859375 13.054688 L 17.53125 13.054688 L 18.082031 8.785156 L 13.859375 8.785156 L 13.859375 6.0625 C 13.859375 4.828125 14.199219 3.984375 15.972656 3.984375 L 18.230469 3.984375 L 18.230469 0.167969 C 17.839844 0.117188 16.5 0 14.941406 0 C 11.683594 0 9.453125 1.988281 9.453125 5.640625 L 9.453125 8.785156 L 5.769531 8.785156 L 5.769531 13.054688 L 9.453125 13.054688 L 9.453125 24 Z M 13.859375 24" />
		</SvgIcon>
	);
}

function GoogleIcon(props) {
	return (
		<SvgIcon {...props}>
			<g>
				<path d="M 23.429688 10.429688 L 21.921875 10.429688 L 21.921875 8.921875 C 21.921875 8.605469 21.664062 8.351562 21.347656 8.351562 L 19.3125 8.351562 C 18.996094 8.351562 18.742188 8.605469 18.742188 8.921875 L 18.742188 10.429688 L 17.234375 10.429688 C 16.917969 10.429688 16.664062 10.6875 16.664062 11 L 16.664062 13.035156 C 16.664062 13.351562 16.917969 13.609375 17.234375 13.609375 L 18.742188 13.609375 L 18.742188 15.117188 C 18.742188 15.433594 18.996094 15.6875 19.3125 15.6875 L 21.347656 15.6875 C 21.664062 15.6875 21.921875 15.433594 21.921875 15.117188 L 21.921875 13.609375 L 23.429688 13.609375 C 23.746094 13.609375 24 13.351562 24 13.035156 L 24 11 C 24 10.6875 23.746094 10.429688 23.429688 10.429688 Z M 23.429688 10.429688 " />
				<path d="M 7.765625 7.9375 C 8.457031 7.9375 9.121094 8.121094 9.691406 8.476562 L 10.339844 8.917969 C 10.566406 9.074219 10.867188 9.046875 11.0625 8.855469 L 12.953125 6.980469 C 13.074219 6.859375 13.136719 6.691406 13.121094 6.523438 C 13.105469 6.351562 13.015625 6.199219 12.875 6.101562 L 11.777344 5.347656 C 11.753906 5.332031 11.730469 5.320312 11.707031 5.308594 L 10.640625 4.78125 C 10.628906 4.773438 10.613281 4.769531 10.597656 4.761719 C 9.679688 4.402344 8.714844 4.21875 7.726562 4.21875 C 5.6875 4.21875 3.761719 5 2.308594 6.421875 C 0.835938 7.863281 0.015625 9.824219 0 11.945312 C -0.015625 14.0625 0.78125 16.035156 2.238281 17.5 C 3.683594 18.949219 5.730469 19.78125 7.863281 19.78125 C 9.96875 19.78125 11.945312 18.964844 13.285156 17.539062 C 14.347656 16.410156 15 14.898438 15.171875 13.171875 C 15.171875 13.15625 15.171875 13.140625 15.171875 13.125 L 15.191406 11.574219 C 15.195312 11.542969 15.191406 11.511719 15.1875 11.476562 L 15.101562 10.949219 C 15.058594 10.671875 14.820312 10.464844 14.539062 10.464844 L 7.933594 10.464844 C 7.621094 10.464844 7.363281 10.722656 7.363281 11.035156 L 7.363281 13.320312 C 7.363281 13.636719 7.621094 13.894531 7.933594 13.894531 L 11.300781 13.894531 C 11.148438 14.230469 10.902344 14.585938 10.597656 14.921875 C 9.925781 15.644531 8.925781 16.0625 7.851562 16.0625 C 6.757812 16.0625 5.707031 15.632812 4.972656 14.882812 C 3.445312 13.324219 3.464844 10.609375 5.011719 9.074219 C 5.738281 8.351562 6.742188 7.9375 7.765625 7.9375 Z M 7.765625 7.9375 " />
			</g>
		</SvgIcon>
	);
}

const sidebarBackground = {
	backgroundImage: "url(" + img2 + ")",
	backgroundRepeat: "no-repeat",
	backgroundPosition: "bottom center"
};

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick() {
		var elem = document.getElementById('loginform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		document.getElementById('recoverform').style.display = "block";
	}
	render() {
		const {
			isLoginPending,
			loginError,
			loginUser,
		} = this.props;

		return <div className="">
			{/*--------------------------------------------------------------------------------*/}
			{/*Login Cards*/}
			{/*--------------------------------------------------------------------------------*/}
			<div className="auth-wrapper d-flex no-block justify-content-center align-items-center" style={sidebarBackground}>
				<div className="auth-box on-sidebar">
					<div id="loginform">
						<div className="logo">
							<span className="db"><img src={img1} alt="logo" /></span>
							<h5 className="font-medium mb-3">Sign In to Dashboard</h5>
							<div className="alert alert-success">Welcome Back!</div>
						</div>
						<Row>
							<Col xs="12">
								<Formik
									initialValues={{
										email: '',
										password: ''
									}}
									validationSchema={Yup.object().shape({
										email: Yup.string().required('Email is required').email('Please enter valid email'),
										password: Yup.string().required('Password is required')
									})}
									onSubmit={({ email, password }) => {
										loginUser({ email, password }, this.props.location.state && this.props.location.state.from.pathname || '/')
									}}
									render={({ errors, touched }) => (
										<Form className="mt-3" id="loginform">
											<InputGroup className="mb-3">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<PermIdentityIcon fontSize='small' />
													</InputGroupText>
												</InputGroupAddon>

												<Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
												<ErrorMessage name="email" component="div" className="invalid-feedback" />
											</InputGroup>
											<InputGroup className="mb-3">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<VpnKeyIcon fontSize='small' />
													</InputGroupText>
												</InputGroupAddon>
												<Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
												<ErrorMessage name="password" component="div" className="invalid-feedback" />

											</InputGroup>
											<div className="d-flex no-block align-items-center mb-3">
												<CustomInput type="checkbox" id="exampleCustomCheckbox" label="Remember Me" />
												<div className="ml-auto">
													<a href="#recoverform" id="to-recover" onClick={this.handleClick} className="forgot text-dark float-right"><LockIcon fontSize='small' /> Forgot pwd?</a>
												</div>
											</div>
											<Row className="mb-3">
												<Col xs="12">
													<button type="submit" className="btn btn-block btn-primary" disabled={isLoginPending || !(Object.keys(errors).length === 0 && errors.constructor === Object) || (Object.keys(touched).length === 0 && touched.constructor === Object)}>Login</button>
												</Col>
											</Row>
											<span>By continuing, you agree to our Terms of Service.</span>

											<div className="text-center mb-2">
												<div className="social">
													<Button id="UncontrolledTooltipExample1" className="btn-facebook mr-2" color="primary">
														<FBIcon fontSize='small' />
													</Button>
													<UncontrolledTooltip placement="top" target="UncontrolledTooltipExample1">
														Facebook</UncontrolledTooltip>
													<Button id="UncontrolledTooltipExample2" className="btn-googleplus" color="danger">
														<GoogleIcon fontSize='small' />
													</Button>
													<UncontrolledTooltip placement="top" target="UncontrolledTooltipExample2">
														Google Plus</UncontrolledTooltip>
												</div>
											</div>
											{loginError &&
												<div className={'alert alert-danger'}>{loginError}</div>
											}
										</Form>
									)}
								/>
							</Col>
						</Row>
					</div>
					<div id="recoverform">
						<div className="logo">
							<span className="db"><img src={img1} alt="logo" /></span>
							<h5 className="font-medium mb-3">Recover Password</h5>
							<span>Enter your Email and instructions will be sent to you!</span>
						</div>
						<Row className="mt-3">
							<Col xs="12">
								<Form action="/dashbaord">
									<FormGroup>
										<Input type="text" name="uname" bsSize="lg" id="Name" placeholder="Email" required />
									</FormGroup>
									<Row className="mt-3">
										<Col xs="12">
											<Button color="danger" size="lg" type="submit" block>Reset</Button>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</div>;
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		isLoginPending: state.auth.isLoginPending,
		loginError: state.auth.loginError,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		loginUser: (postData, from) => { dispatch(login(postData, from)) },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
