import { instance2 } from 'utils/axios';
import { history } from 'helpers/history';

import {
  ADD_UPDATE_POST_ERROR,
  ADD_UPDATE_POST_PENDING,
  ADD_UPDATE_POST_SUCCESS,
  FETCH_POST_SUCCESS,
  FETCH_POST_PENDING,
  FETCH_POST_ERROR,
  FETCH_POST,
  FETCH_POST_COUNT,
  FETCH_ONE_POST,
  FETCH_ONE_POST_PENDING,
  FETCH_AUTHOR,
  FETCH_AUTHOR_PENDING,
} from './types';
import CustomSnackbar from 'common/customsnackbar';

const setFetchPostSuccess = (isFetchPostSuccess) => (dispatch) => {
  dispatch({
    type: FETCH_POST_SUCCESS,
    payload: isFetchPostSuccess,
  });
};

const setFetchPostPending = (isFetchPostPending) => (dispatch) => {
  dispatch({
    type: FETCH_POST_PENDING,
    payload: isFetchPostPending,
  });
};

const setFetchOnePostPending = (isPostDataPending) => (dispatch) => {
  dispatch({
    type: FETCH_ONE_POST_PENDING,
    payload: isPostDataPending,
  });
};

export const setFetchPostError = (fetchPostError) => (dispatch) => {
  dispatch({
    type: FETCH_POST_ERROR,
    payload: fetchPostError,
  });
};

const setAddUpdatePostPending = (isAddUpdatePostPending) => (dispatch) => {
  dispatch({
    type: ADD_UPDATE_POST_PENDING,
    payload: isAddUpdatePostPending,
  });
};

export const setAddUpdatePostError = (addUpdatePostError) => (dispatch) => {
  dispatch({
    type: ADD_UPDATE_POST_ERROR,
    payload: addUpdatePostError,
  });
};

export const setAddUpdatePostSuccess =
  (isAddUpdatePostSuccess) => (dispatch) => {
    dispatch({
      type: ADD_UPDATE_POST_SUCCESS,
      payload: isAddUpdatePostSuccess,
    });
  };

export const setFetchAuthorPending = (isFetchAuthorPending) => (dispatch) => {
  dispatch({
    type: FETCH_AUTHOR_PENDING,
    payload: isFetchAuthorPending,
  });
};

// https://site.hopwebsite.com/coreapi/post/create
export const addPost = (postData) => async (dispatch) => {
  dispatch(setAddUpdatePostSuccess(false));
  dispatch(setAddUpdatePostPending(true));

  instance2
    .post('post/slug', { string: postData.post_title })
    .then((response) => {
      postData = {
        ...postData,
        post_name: response.data.data,
      };
      instance2
        .post('post/create', postData)
        .then((response) => {
          dispatch(setAddUpdatePostSuccess(true));
          if (postData.post_status === 'draft')
            CustomSnackbar('success', 'Post saved to draft successfully');
          else CustomSnackbar('success', 'Post published successfully');
          history.replace(response.data.data.id);
        })
        .catch((err) => {
          if (err.response) {
            dispatch(setAddUpdatePostError(err.response.data.data));
            CustomSnackbar('error', err.response.data.data);
          }
        })
        .finally(() => {
          dispatch(setAddUpdatePostPending(false));
        });
    });
};

// https://site.hopwebsite.com/coreapi/post/getAll/:pageNo/:perPage/search=&order=&post_type=post&post_status=
export const fetchPosts =
  (
    pageNo = 1,
    perPage = 10,
    keyword = '',
    order = '',
    postType = 'post',
    post_status = 'all',
    terms = '',
    authors = '',
    dates = '',
    desc = false
  ) =>
  async (dispatch) => {
    dispatch(setFetchPostPending(true));
    dispatch({
      type: FETCH_POST_COUNT,
      payload: -1,
    });
    dispatch({
      type: FETCH_POST,
      payload: null,
    });

    instance2
      .post(
        `post/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}&post_type=${postType}&post_status=${post_status}&terms=${terms}&authors=${authors}&dates=${dates}${
          desc ? ' desc' : ''
        }`,
        {}
      )
      .then((response) => {
        dispatch(setFetchPostSuccess(true));
        dispatch({
          type: FETCH_POST_COUNT,
          payload: parseInt(response.data.data.count),
        });
        dispatch({
          type: FETCH_POST,
          payload: response.data.data.items,
        });
        dispatch(setFetchPostPending(false));
      })
      .catch((err) => {
        err.response && dispatch(setFetchPostError(err.response.data.data));
        dispatch(setFetchPostPending(false));
      });
  };

export const updatePost =
  (id, postData, firstTimePublish) => async (dispatch) => {
    dispatch(setAddUpdatePostSuccess(false));
    dispatch(setAddUpdatePostPending(true));
    dispatch(setFetchOnePostPending(true));

    instance2
      .post(`post/update/${id}`, postData)
      .then((response) => {
        dispatch({
          type: FETCH_ONE_POST,
          payload: response.data.data,
        });
        dispatch(setAddUpdatePostSuccess(true));
        if (postData.post_status === 'trash') {
          CustomSnackbar('success', 'Post moved to trash successfully');
          history.replace('list');
        } else if (postData.post_status === 'draft')
          CustomSnackbar('success', 'Post saved to draft successfully');
        else if (firstTimePublish)
          CustomSnackbar('success', 'Post published successfully');
        else CustomSnackbar('success', 'Post updated successfully');
      })
      .catch((err) => {
        if (err.response) {
          dispatch(setAddUpdatePostError(err.response.data.data));
          CustomSnackbar('error', err.response.data.data);
        }
      })
      .finally(() => {
        dispatch(setAddUpdatePostPending(false));
        dispatch(setFetchOnePostPending(false));
      });
  };

export const fetchPost = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_ONE_POST,
    payload: [],
  });
  dispatch(setFetchOnePostPending(true));

  instance2
    .post(`post/get/${id}`, {})
    .then((response) => {
      if (!response.data.data) {
        history.push('/not-found');
      }
      dispatch({
        type: FETCH_ONE_POST,
        payload: response.data.data,
      });
      dispatch(setFetchOnePostPending(false));
    })
    .catch((err) => {
      dispatch(setFetchOnePostPending(false));
    });
};

export const deletePosts =
  (
    postData,
    pageNo,
    perPage,
    keyword = '',
    order = '',
    postType = 'post',
    post_status
  ) =>
  async (dispatch) => {
    instance2
      .post('post/delete', postData)
      .then((response) => {
        dispatch(
          fetchPosts(pageNo, perPage, keyword, order, postType, post_status)
        );
      })
      .catch((err) => {
        err.response && dispatch(setFetchPostError(err.response.data.data));
      });
  };

export const changePostStatus =
  (
    postData,
    pageNo,
    perPage,
    keyword = '',
    order = '',
    postType = 'post',
    post_status = 'all'
  ) =>
  async (dispatch) => {
    instance2
      .post('post/change_status', postData)
      .then((response) => {
        dispatch(
          fetchPosts(pageNo, perPage, keyword, order, postType, post_status)
        );
        if (postData.status === 'trash')
          CustomSnackbar('success', 'Moved to trash successfully');
        else if (postData.status === 'draft')
          CustomSnackbar('success', 'Moved to draft successfully');
        else if (postData.status === 'publish')
          CustomSnackbar('success', 'Post published successfully');
        else CustomSnackbar('success', 'Post updated successfully');
      })
      .catch((err) => {
        if (err.response) {
          dispatch(setFetchPostError(err.response.data.data));
          CustomSnackbar('error', err.response.data.data);
        }
      });
  };

export const fetchAuthors =
  (pageNo = 1, perPage = 10, keyword = '', order = '', desc = false) =>
  async (dispatch) => {
    dispatch(setFetchAuthorPending(true));

    instance2
      .post(
        `author/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${
          desc ? ' desc' : ''
        }`,
        {}
      )
      // instance2.post(`/post/get_authors`, {})
      .then((response) => {
        dispatch({
          type: FETCH_AUTHOR,
          payload: response.data.data.items,
        });
        dispatch(setFetchAuthorPending(false));
      })
      .catch((err) => {
        dispatch(setFetchAuthorPending(false));
      });
  };
