import { instance2 } from 'utils/axios'
import CustomSnackbar from 'common/customsnackbar'

import {
    UPDATE_THEMES_ERROR,
    UPDATE_THEMES_PENDING,
    UPDATE_THEMES_SUCCESS,
    FETCH_ONE_THEMES,
    FETCH_THEMES,
    FETCH_ONE_THEMES_PENDING,
} from './types';

const setUpdateThemesPending = isUpdateThemesPending => dispatch => {
    dispatch({
        type: UPDATE_THEMES_PENDING,
        payload: isUpdateThemesPending
    });
}

const setFetchOneThemesPending = isThemesDataPending => dispatch => {
    dispatch({
        type: FETCH_ONE_THEMES_PENDING,
        payload: isThemesDataPending
    });
}

export const setUpdateThemesError = updateThemesError => dispatch => {
    dispatch({
        type: UPDATE_THEMES_ERROR,
        payload: updateThemesError
    });
}

export const setUpdateThemesSuccess = isUpdateThemesSuccess => dispatch => {
    dispatch({
        type: UPDATE_THEMES_SUCCESS,
        payload: isUpdateThemesSuccess
    });
}

// https://site.hopwebsite.com/coreapi/meta/save/{seting_group}
export const updateThemes = (postData) => async dispatch => {

    dispatch(setUpdateThemesSuccess(false));
    dispatch(setUpdateThemesPending(true));
    dispatch(setFetchOneThemesPending(true));

    instance2.post('meta/save_theme', postData)
        .then(response => {
            dispatch({
                type: FETCH_THEMES,
                payload: response.data.data
            });
            dispatch(setUpdateThemesSuccess(true));
            CustomSnackbar('success', 'Themes updated successfully');
        })
        .catch(err => {
            if (err.response) {
                dispatch(setUpdateThemesError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        })
        .finally(() => {
            dispatch(setUpdateThemesPending(false));
            dispatch(setFetchOneThemesPending(false));
        });

}

// https://site.hopwebsite.com/coreapi/meta/get/{seting_group}
export const fetchThemes = () => async dispatch => {
    
    dispatch(setFetchOneThemesPending(true));

    instance2.post('meta/get_themes', {})
        .then(response => {
            dispatch({
                type: FETCH_ONE_THEMES,
                payload: response.data.data
            });
            dispatch(setFetchOneThemesPending(false));
        })
        .catch(err => {
            dispatch(setFetchOneThemesPending(false));
        });
}