import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete';
import {
    IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { deleteMedia } from '../actions/mediaActions';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Popover, PopoverBody, } from 'reactstrap';
import CustomModal from 'common/custommodal'
import { history } from 'helpers/history';
import MediaEdit from './MediaEdit';

const PostActionButtons = (props) => {
    const { setPage, setSelected, page, rowsPerPage, keyword, row, rowLength, deleteMedia } = props;

    const [modal, setModal] = React.useState(false);
    const toggle = () => setModal(!modal)

    const [modal1, setModal1] = React.useState(false);
    const toggle1 = () => setModal1(!modal1)

    const [popover, setPopover] = React.useState(false);
    const togglePopover = () => setPopover(!popover)

    const onDelete = () => {
        toggle();
        if (rowLength === 1 && page !== 0) {
            setPage(page - 1);
            deleteMedia({ id: row.id }, page, rowsPerPage, keyword);
        }
        else
            deleteMedia({ id: row.id }, page + 1, rowsPerPage, keyword);
        setSelected([]);
    };

    return (
        <div>
            <IconButton aria-label='key' className='no-border-onfocus' onClick={togglePopover} id={`Popover-${row.id}`}>
                <MoreHorizIcon />
            </IconButton>
            <Popover placement='left' trigger="legacy" isOpen={popover} target={`Popover-${row.id}`} toggle={togglePopover}>
                <PopoverBody>

                    <a role='button' className='anchoreless-button' onClick={(e) => {
                        e.stopPropagation();
                        const win = window.open(props.site.website_url + '/' + row.slug, '_blank');
                        if (win != null) {
                            win.focus();
                        }
                    }}>
                        <VisibilityIcon />{' '}View Media
                    </a>

                    <a role='button' className='anchoreless-button' onClick={(e) => {
                        togglePopover();
                        toggle1();
                        e.stopPropagation();
                    }}>
                        <EditIcon />{' '}Edit Media
                    </a>

                    <a role='button' className='anchoreless-button' onClick={(e) => {
                        togglePopover();
                        e.stopPropagation();
                        toggle();
                    }}>
                        <DeleteIcon />{' '}Delete Media
                    </a>

                </PopoverBody>
            </Popover>
            <CustomModal
                onModalAccept={onDelete}
                onModalCancel={toggle}
                modal={modal}
                toggle={toggle}
                modalTitle="Delete Media"
                modalBody="Are you sure you want to delete this media?"
            />
            <Modal style={{ maxWidth: '100%', width: '95%', margin: '10px auto' }} size='xl' isOpen={modal1} toggle={toggle1}>
                <ModalHeader toggle={toggle1}>Update Media</ModalHeader>
                <ModalBody>
                    <MediaEdit
                        id={row.id}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        keyword={keyword}
                        oneMedia={row}
                        toggle={toggle1}
                    />
                </ModalBody>
            </Modal>
        </div >

    )
}

const mapStateToProps = (state) => {
    return {
        site: state.siteSelector.selectedSite,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deleteMedia: (postData, pageNo, perPage, keyword) => { dispatch(deleteMedia(postData, pageNo, perPage, keyword)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostActionButtons);