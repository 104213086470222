//Import Components for CMS Nav
import NotFound from "common/NotFound";
import SiteDashboard from "app/site/cms/dashboard/views/SiteDashboard";
import CategoryList from "app/site/cms/post/views/CategoryList";
import TagList from "app/site/cms/post/views/TagList";
import AllPost from "app/site/cms/post/views/AllPost";
import PublishedPost from "app/site/cms/post/views/PublishedPost";
import DraftPost from "app/site/cms/post/views/DraftPost";
import TrashPost from "app/site/cms/post/views/TrashPost";
import ScheduledPost from "app/site/cms/post/views/ScheduledPost";
import CommentList from "app/site/cms/comment/views/CommentList";
import OneMedia from "app/site/cms/media/views/OneMedia";
import MediaList from "app/site/cms/media/views/MediaList";
import OnePost from "app/site/cms/post/views/OnePost";

//Import icons for CMS Nav
import DashboardIcon from "@material-ui/icons/Dashboard";
import WebIcon from "@material-ui/icons/Web";
import PostAddIcon from "@material-ui/icons/PostAdd";
import CommentIcon from "@material-ui/icons/Comment";
import DescriptionIcon from "@material-ui/icons/Description";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import BallotIcon from "@material-ui/icons/Ballot";
import PageList from 'app/site/cms/post/views/page/PageList';
import OnePage from 'app/site/cms/post/views/page/OnePage';

const cmsroutes = [
  {
    path: "/site/cms",
    name: "CMS - Manage Posts and Pages",
    title: "CMS",
    id: "site-cms",
    icon: WebIcon,
    child: [
      {
        path: "/site/:sitecode/cms/dashboard",
        name: "Dashboard",
        title: "Site Dashboard",
        icon: DashboardIcon,
        component: SiteDashboard,
      },
      {
        collapse: true,
        name: "Posts",
        icon: PostAddIcon,
        state: "Posts",
        subchild: [
          {
            path: "/site/:sitecode/cms/post/add",
            name: "Add New",
            title: "Add New Post",
          },
          {
            path: "/site/:sitecode/cms/post/all",
            name: "All Posts",
            title: "allPosts.label", //'All Posts',
            component: AllPost,
          },
          {
            path: "/site/:sitecode/cms/post/published",
            name: "Published",
            title: "Published Posts",
            component: PublishedPost,
          },
          {
            path: "/site/:sitecode/cms/post/drafts",
            name: "Drafts",
            title: "Drafts Posts",
            component: DraftPost,
          },
          {
            path: "/site/:sitecode/cms/post/scheduled",
            name: "Scheduled",
            title: "Scheduled Posts",
            component: ScheduledPost,
          },
          {
            path: "/site/:sitecode/cms/post/templates",
            name: "Templates",
            title: "Post Templates",
            component: NotFound,
          },
          {
            path: "/site/:sitecode/cms/post/trash",
            name: "Trash",
            title: "Trash Posts",
            component: TrashPost,
          },
        ],
      },
      {
        path: "/site/:sitecode/cms/post/category",
        name: "Category",
        title: "Manage Category",
        icon: BallotIcon,
        component: CategoryList,
      },
      {
        path: "/site/:sitecode/cms/tag",
        name: "Tags",
        title: "Manage Tags",
        icon: LocalOfferIcon,
        component: TagList,
      },
      {
        path: "/site/:sitecode/cms/comment",
        name: "Comments",
        title: "Manage Comments",
        icon: CommentIcon,
        component: CommentList,
      },
      {
        collapse: true,
        name: "Pages",
        icon: DescriptionIcon,
        state: "Pages",
        subchild: [
          {
            path: "/site/:sitecode/cms/page/list",
            name: "All Pages",
            title: "All Pages",
            component: PageList,
          },
          {
            path: "/site/:sitecode/cms/page/add",
            name: "Add New",
            title: "Add New Page",
          },
        ],
      },
      {
        collapse: true,
        name: "Media",
        icon: PermMediaIcon,
        state: "Media",
        subchild: [
          {
            path: "/site/:sitecode/cms/media/library",
            name: "Library",
            title: "Media Library",
            component: MediaList,
          },
          {
            path: "/site/:sitecode/cms/media/add",
            name: "Add New",
            title: "Add New Media",
            component: OneMedia,
          },
        ],
      },
    ],
  },
  {
    path: "/site/:sitecode/cms/post/:id",
    name: "Add New Post",
    component: OnePost,
    routeonly: true,
  },
  {
    path: "/site/:sitecode/cms/page/:id",
    name: "Add New Page",
    component: OnePage,
    routeonly: true,
  },
  // { path: '/site/cms/media/:id', name: 'Add Media', component: NotFound, routeonly: true },
];

export default cmsroutes;
