import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import CustomSpinner from "common/CustomSpinner";
import { connect } from "react-redux";
import {
  addWebsite,
  fetchWebsite,
  fetchAllServers,
  updateWebsite,
} from "../actions/websiteActions";
import { fetchAllGroups } from "../actions/groupActions";
import HttpIcon from "@material-ui/icons/Http";
import WebIcon from "@material-ui/icons/Web";
import FlagIcon from "@material-ui/icons/Flag";
import PublicIcon from "@material-ui/icons/Public";

const emptyFormValues = {
  name: "",
  url: "",
  _groups: [],
};
const httpsOptions = [
  { value: "yes", label: "HTTPS" },
  { value: "no", label: "HTTP" },
];
const wwwOptions = [
  { value: "no", label: "non-WWW" },
  { value: "yes", label: "WWW" },
];

class OneWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.didUpdated = false;
    this.state = {
      modal: false,
      formData: {
        groups: [],
      },
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchAllServers();
    if (id !== "add") this.props.fetchWebsite(id);
  }

  componentDidUpdate() {
    if (!this.didUpdated && this.props.websiteData.groups) {
      this.didUpdated = true;
      this.setState({
        ...this.state,
        formData: {
          ...this.state.formData,
          groups: this.props.websiteData.groups,
        },
      });
    }
  }

  render() {
    const { id } = this.props.match.params;
    const { websiteData } = this.props;

    if (
      id !== "add" &&
      this.props.isWebsiteDataPending &&
      !this.props.isAddUpdateWebsitePending
    ) {
      return <CustomSpinner />;
    }

    let _groups = [];

    websiteData.groups &&
      websiteData.groups.map((group) => {
        return _groups.push(group.id);
      });

    return (
      <div>
        <div>{this.props.isWebsiteDataPending && <CustomSpinner />}</div>
        <div className="page-header">
          <Row>
            <Col className="page-title">
              <h1 className="page-title">
                {id === "add" ? "Create New Website" : "Update Website"}
              </h1>
            </Col>
          </Row>
        </div>
        <div className="content-wrapper">
          <Card>
            <CardTitle>
              {id === "add" ? "Create New Website" : "Update Website"}
            </CardTitle>
            <CardBody className="">
              <div>
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: websiteData.website_name || "",
                    url: websiteData.website_domain || "",
                    server_id: websiteData.server_id || "",
                    is_www: websiteData.is_www || "no",
                    is_https: websiteData.is_https || "yes",
                    _groups: _groups || "",
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required("Website name is required"),
                    url: Yup.string().required("Website url is required"),
                    server_id: Yup.string().required("Please select server"),
                  })}
                  onSubmit={({ name, url, is_www, _groups, server_id }) => {
                    var postData = {
                      website_name: name,
                      website_domain: url,
                    };
                    postData = {
                      ...postData,
                      website_app_secret_key:
                        websiteData.website_app_secret_key,
                      is_https: "yes",
                      is_www: is_www,
                      server_id: server_id,
                      _groups: _groups,
                    };
                    this.props.updateWebsite(id, postData);
                  }}
                >
                  {({
                    errors,
                    touched,
                    setFieldValue,
                    values,
                    setFieldTouched,
                    resetForm,
                  }) => (
                    <Form id="loginform">
                      <Row>
                        <Col lg="6" xs="12">
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <WebIcon fontSize="small" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Field
                              placeholder="Website Name"
                              name="name"
                              type="text"
                              className={
                                "form-control" +
                                (errors.name && touched.name
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="invalid-feedback"
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="6" xs="12">
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <HttpIcon fontSize="small" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Field
                              placeholder="Website Domain"
                              readOnly
                              name="url"
                              type="text"
                              className={
                                "form-control" +
                                (errors.url && touched.url ? " is-invalid" : "")
                              }
                            />
                            <ErrorMessage
                              name="url"
                              component="div"
                              className="invalid-feedback"
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="6" xs="12">
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <PublicIcon fontSize="small" />
                              </InputGroupText>
                            </InputGroupAddon>

                            <Select
                              placeholder="WWW/non-WWW"
                              styles={{
                                container: (provided, state) => ({
                                  ...provided,
                                  flex: "1 1 auto",
                                  width: "1%",
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  border:
                                    errors.role && touched.role
                                      ? "1px solid #f62d51"
                                      : "1px solid #e9ecef",
                                  borderRadius: "2px",
                                }),
                                singleValue: (provided, state) => ({
                                  ...provided,
                                  color: "#525f7f",
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  padding: "0 0.75rem",
                                }),
                              }}
                              options={wwwOptions}
                              onChange={(wwwOptions) => {
                                setFieldValue("is_www", wwwOptions.value);
                              }}
                              onBlur={() => {
                                setFieldTouched("is_www");
                              }}
                              value={
                                wwwOptions.find(
                                  (option) => option.value === values.is_www
                                ) || "no"
                              }
                            />
                            <ErrorMessage
                              name="wwwOptions"
                              component="div"
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            />
                          </InputGroup>
                        </Col>

                        <Col lg="6" xs="12">
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <FlagIcon fontSize="small" />
                              </InputGroupText>
                            </InputGroupAddon>

                            <Select
                              id="server_id"
                              name="server_id"
                              placeholder="Select Server"
                              options={this.props.servers}
                              clearable={false}
                              getOptionLabel={(option) =>
                                `${option.server_code} - ${option.server_name}`
                              }
                              getOptionValue={(option) => option.id}
                              styles={{
                                container: (provided, state) => ({
                                  ...provided,
                                  flex: "1 1 auto",
                                  width: "1%",
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  border: "1px solid #e9ecef",
                                  borderRadius: "2px",
                                }),
                                singleValue: (provided, state) => ({
                                  ...provided,
                                  color: "#525f7f",
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  padding: "0 0.75rem",
                                }),
                              }}
                              onChange={(options) => {
                                setFieldValue("server_id", options.id);
                              }}
                              isLoading={this.props.isFetchServerPending}
                              value={this.state.formData.server_id}
                            />

                            <ErrorMessage
                              name="server_id"
                              component="div"
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            />
                          </InputGroup>
                        </Col>

                        <Col lg="6" xs="12">
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <PublicIcon fontSize="small" />
                              </InputGroupText>
                            </InputGroupAddon>

                            <Select
                              id="groups"
                              isMulti
                              name="groups"
                              isClearable={true}
                              options={this.props.groups}
                              getOptionLabel={(option) =>
                                option.website_group_name
                              }
                              getOptionValue={(option) => option.id}
                              styles={{
                                container: (provided, state) => ({
                                  ...provided,
                                  flex: "1 1 auto",
                                  width: "1%",
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  border: "1px solid #e9ecef",
                                  borderRadius: "2px",
                                }),
                                singleValue: (provided, state) => ({
                                  ...provided,
                                  color: "#525f7f",
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  padding: "0 0.75rem",
                                }),
                              }}
                              isLoading={this.props.isFetchGroupPending}
                              onFocus={() => {
                                this.props.fetchAllGroups();
                              }}
                              onChange={(options, { action }) => {
                                if (options) {
                                  let differences =
                                    this.state.formData.groups.filter(
                                      (x) => !options.includes(x)
                                    );
                                  let newGroups = [...values._groups];
                                  if (differences.length > 0) {
                                    differences.map((difference) => {
                                      return (newGroups = newGroups.filter(
                                        function (value, index, arr) {
                                          return value !== difference.id;
                                        }
                                      ));
                                    });
                                  } else
                                    newGroups.push(
                                      options[options.length - 1].id
                                    );
                                  setFieldValue("_groups", newGroups);
                                }
                                this.setState({
                                  ...this.state,
                                  formData: {
                                    ...this.state.formData,
                                    groups: options ? [...options] : [],
                                  },
                                });
                              }}
                              value={this.state.formData.groups}
                            />

                            <ErrorMessage
                              name="groups"
                              component="div"
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            />
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="3" xs="6" className="mb-3">
                          <button
                            type="submit"
                            className="btn btn-block btn-primary"
                            disabled={this.props.isAddUpdateWebsitePending}
                          >
                            {id === "add" ? "Create" : "Update"}
                          </button>
                        </Col>
                        <Col lg="3" xs="6" className="mb-3">
                          <button
                            type="reset"
                            className="btn btn-block btn-outline-primary"
                            disabled={this.props.isAddUpdateWebsitePending}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                ...this.state,
                                formData: {
                                  ...this.state.formData,
                                  groups: [],
                                },
                              });
                              resetForm({
                                ...emptyFormValues,
                                url: websiteData.website_domain,
                              });
                            }}
                          >
                            Clear
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    servers: state.website.servers,
    isFetchServerPending: state.website.isFetchServerPending,
    websiteData: state.website.websiteData,
    isAddUpdateWebsitePending: state.website.isAddUpdateWebsitePending,
    isWebsiteDataPending: state.website.isWebsiteDataPending,
    groups: state.group.groups,
    isFetchGroupPending: state.group.isFetchGroupPending,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addWebsite: (postData) => {
      dispatch(addWebsite(postData));
    },
    fetchWebsite: (id) => {
      dispatch(fetchWebsite(id));
    },
    updateWebsite: (id, postData) => {
      dispatch(updateWebsite(id, postData));
    },
    fetchAllGroups: () => {
      dispatch(fetchAllGroups());
    },
    fetchAllServers: () => {
      dispatch(fetchAllServers());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OneWebsite);
