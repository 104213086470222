import { instance2 } from "utils/axios";
import { history } from "helpers/history";

import {
  FETCH_COMMENT_SUCCESS,
  FETCH_COMMENT_PENDING,
  FETCH_COMMENT_ERROR,
  FETCH_COMMENT,
  FETCH_COMMENT_COUNT,
  ADD_UPDATE_COMMENT_ERROR,
  ADD_UPDATE_COMMENT_PENDING,
  ADD_UPDATE_COMMENT_SUCCESS,
  FETCH_ONE_COMMENT,
  FETCH_ONE_COMMENT_PENDING,
} from "./types";

const setFetchCommentSuccess = (isFetchCommentSuccess) => (dispatch) => {
  dispatch({
    type: FETCH_COMMENT_SUCCESS,
    payload: isFetchCommentSuccess,
  });
};

const setFetchCommentPending = (isFetchCommentPending) => (dispatch) => {
  dispatch({
    type: FETCH_COMMENT_PENDING,
    payload: isFetchCommentPending,
  });
};

const setAddUpdateCommentPending =
  (isAddUpdateCommentPending) => (dispatch) => {
    dispatch({
      type: ADD_UPDATE_COMMENT_PENDING,
      payload: isAddUpdateCommentPending,
    });
  };

const setFetchOneCommentPending = (isCommentDataPending) => (dispatch) => {
  dispatch({
    type: FETCH_ONE_COMMENT_PENDING,
    payload: isCommentDataPending,
  });
};

export const setAddUpdateCommentError =
  (addUpdateCommentError) => (dispatch) => {
    dispatch({
      type: ADD_UPDATE_COMMENT_ERROR,
      payload: addUpdateCommentError,
    });
  };

export const setAddUpdateCommentSuccess =
  (isAddUpdateCommentSuccess) => (dispatch) => {
    dispatch({
      type: ADD_UPDATE_COMMENT_SUCCESS,
      payload: isAddUpdateCommentSuccess,
    });
  };

export const setFetchCommentError = (fetchCommentError) => (dispatch) => {
  dispatch({
    type: FETCH_COMMENT_ERROR,
    payload: fetchCommentError,
  });
};

export const fetchComments =
  (
    pageNo = 1,
    perPage = 10,
    keyword = "",
    order = "",
    postType = "post",
    desc = false
  ) =>
  async (dispatch) => {
    dispatch(setFetchCommentPending(true));
    dispatch({
      type: FETCH_COMMENT_COUNT,
      payload: -1,
    });
    dispatch({
      type: FETCH_COMMENT,
      payload: [],
    });

    instance2
      .post(
        `comment/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}&post_type=${postType}${
          desc ? " desc" : ""
        }`,
        {}
      )
      .then((response) => {
        dispatch(setFetchCommentSuccess(true));
        dispatch({
          type: FETCH_COMMENT_COUNT,
          payload: parseInt(response.data.data.count),
        });
        dispatch({
          type: FETCH_COMMENT,
          payload: response.data.data.items,
        });
        dispatch(setFetchCommentPending(false));
      })
      .catch((err) => {
        err.response && dispatch(setFetchCommentError(err.response.data.data));
        dispatch(setFetchCommentPending(false));
      });
  };

export const addComment = (postData) => async (dispatch) => {
  dispatch(setAddUpdateCommentSuccess(false));
  dispatch(setAddUpdateCommentPending(true));
  instance2
    .post("comment/create", postData)
    .then((response) => {
      dispatch(setAddUpdateCommentSuccess(true));
    })
    .catch((err) => {
      err.response &&
        dispatch(setAddUpdateCommentError(err.response.data.data));
    })
    .finally(() => {
      dispatch(setAddUpdateCommentPending(false));
    });
};

export const updateComment = (id, postData) => async (dispatch) => {
  dispatch(setAddUpdateCommentSuccess(false));
  dispatch(setAddUpdateCommentPending(true));
  dispatch(setFetchOneCommentPending(true));

  instance2
    .post(`comment/update/${id}`, postData)
    .then((response) => {
      dispatch({
        type: FETCH_ONE_COMMENT,
        payload: response.data.data,
      });
      dispatch(setAddUpdateCommentSuccess(true));
    })
    .catch((err) => {
      err.response &&
        dispatch(setAddUpdateCommentError(err.response.data.data));
    })
    .finally(() => {
      dispatch(setAddUpdateCommentPending(false));
      dispatch(setFetchOneCommentPending(false));
    });
};

export const fetchComment = (id) => async (dispatch) => {
  dispatch(setFetchOneCommentPending(true));

  instance2
    .post(`comment/get/${id}`, {})
    .then((response) => {
      if (!response.data.data) {
        history.push("/not-found");
      }
      dispatch({
        type: FETCH_ONE_COMMENT,
        payload: response.data.data,
      });
      dispatch(setFetchOneCommentPending(false));
    })
    .catch((err) => {
      dispatch(setFetchOneCommentPending(false));
    });
};

export const deleteComments =
  (postData, pageNo, perPage, keyword = "") =>
  async (dispatch) => {
    instance2
      .post("comment/delete", postData)
      .then((response) => {
        dispatch(fetchComments(pageNo, perPage, keyword));
      })
      .catch((err) => {
        err.response && dispatch(setFetchCommentError(err.response.data.data));
      });
  };

export const fetchSlug = (postData, setFieldValue) => async (dispatch) => {
  instance2.post("comment/slug", postData).then((response) => {
    setFieldValue("slug", response.data.data);
  });
};

export const liveAddComment =
  (commentName, setState, state, instance) => async (dispatch) => {
    dispatch(setFetchCommentPending(true));
    instance2
      .post("comment/slug", { string: commentName })
      .then((response) => {
        instance2
          .post("comment/create", {
            name: commentName,
            slug: response.data.data,
          })
          .then((response) => {
            instance.setState({
              ...instance.state,
              formData: {
                ...instance.state.formData,
                comments: [
                  ...instance.state.formData.comments,
                  { id: response.data.data.id, name: response.data.data.name },
                ],
              },
            });
          });
      })
      .catch((err) => {
        //error
      })
      .finally(() => {
        dispatch(setFetchCommentPending(false));
      });
  };
