import {
    FETCH_CATEGORY_SUCCESS,
    FETCH_CATEGORY_PENDING,
    FETCH_CATEGORY_ERROR,
    FETCH_CATEGORY,
    FETCH_CATEGORY_COUNT,
    ADD_UPDATE_CATEGORY_ERROR,
    FETCH_ONE_CATEGORY,
    FETCH_ONE_CATEGORY_PENDING,
    ADD_UPDATE_CATEGORY_PENDING,
    ADD_UPDATE_CATEGORY_SUCCESS,
    FETCH_PARENT_CATEGORY,
    FETCH_PARENT_CATEGORY_PENDING,
} from '../actions/types';

const initialState = {
    isFetchCategorySuccess: false,
    isFetchCategoryPending: false,
    fetchCategoryError: null,
    addUpdateCategoryError: null,
    categoryData: [],
    isCategoryDataPending: false,
    categories: [],
    noOfRecords: 0,
    isAddUpdateCategoryPending: false,
    isAddUpdateCategorySuccess: false,
    isFetchParentCategoryPending: false,
    parentCategories: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_CATEGORY:
            return {
                ...state,
                categories: action.payload
            };

        case FETCH_CATEGORY_SUCCESS:
            return {
                ...state,
                isFetchCategorySuccess: action.payload
            };

        case FETCH_CATEGORY_PENDING:
            return {
                ...state,
                isFetchCategoryPending: action.payload
            };

        case FETCH_CATEGORY_ERROR:
            return {
                ...state,
                fetchCategoryError: action.payload
            };

        case FETCH_CATEGORY_COUNT:
            return {
                ...state,
                noOfRecords: action.payload
            };

        case FETCH_ONE_CATEGORY:
            return {
                ...state,
                categoryData: action.payload
            };

        case ADD_UPDATE_CATEGORY_ERROR:
            return {
                ...state,
                addUpdateCategoryError: action.payload
            };

        case FETCH_ONE_CATEGORY_PENDING:
            return {
                ...state,
                isCategoryDataPending: action.payload
            };

        case ADD_UPDATE_CATEGORY_PENDING:
            return {
                ...state,
                isAddUpdateCategoryPending: action.payload
            };

        case ADD_UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                isAddUpdateCategorySuccess: action.payload
            };

        case FETCH_PARENT_CATEGORY:
            return {
                ...state,
                parentCategories: action.payload
            };

        case FETCH_PARENT_CATEGORY_PENDING:
            return {
                ...state,
                isFetchParentCategoryPending: action.payload
            };

        default:
            return state;
    }
}