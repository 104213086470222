import React from 'react'
import {
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Row,
    Col,
} from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CustomSpinner from 'common/CustomSpinner';
import { connect } from 'react-redux';
import { setUpdateThemesError, setUpdateThemesSuccess, updateThemes, fetchThemes } from '../actions/themesActions';
import { fetchGeneralSetting, updateGeneralSetting } from 'app/site/settings/general/actions/generalActions';

import Select from 'react-select';


const themesOtion = [];

class Themes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            site: '',
        }
    }

    componentDidMount() {

        if (this.state.site !== this.props.site) {
            this.setState({
                ...this.state,
                site: this.props.site
            })
            this.props.fetchThemes();
            this.props.fetchGeneralSetting();
        }

    }

    componentDidUpdate() {

        if (this.state.site !== this.props.site) {
            this.setState({
                ...this.state,
                site: this.props.site
            })
            this.props.fetchThemes();
            this.props.fetchGeneralSetting();
        }
    }

    render() {
        const {
            themesData,
            generalSettingData,
            updateThemes,
            isThemesDataPending,
            isUpdateThemesPending,
            site,
        } = this.props;

        if (!site || (isThemesDataPending && !isUpdateThemesPending)) {
            return <CustomSpinner />;
        }

        return (

            <div >
                <div >
                    {isThemesDataPending && <CustomSpinner />}
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{
                        template: generalSettingData.template || 'default',
                    }}
                    // validationSchema={Yup.object().shape({
                    // })}
                    onSubmit={({ template }) => {
                        const postData = {
                            template: template,
                        };
                        updateThemes(postData)
                    }}
                >
                    {({ errors, touched, setFieldValue, values, setFieldTouched }) => (

                        <Form id="themesForm">

                            <div className="page-header">
                                <Row>
                                    <Col className='page-title'>
                                        <h1>{this.props.title}</h1>
                                    </Col>
                                    <Col className="text-right page-header-button">
                                        <button type="submit" className="btn btn-primary">Save Changes</button>
                                    </Col>
                                </Row>
                            </div>

                            <div className="content-wrapper">
                                <Card>
                                    <CardTitle className="p-3 mb-0">
                                        Choose Themes
                                </CardTitle>
                                    <CardBody>
                                        <Row className="mt-3">
                                            <Col lg="4" xs="12">
                                                <Select
                                                    id='template'
                                                    // optionremapping={{value: 'path', label: 'name'}}
                                                    // options={themesData.map((item) => {
                                                    //     return { value: item.path, label: item.name };
                                                    // })}
                                                    options={themesData}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.path}
                                                    value={themesData.find(option => option.path === values.template) || 'default'}
                                                    onChange={(option) => { setFieldValue("template", option.path); }}
                                                    onBlur={() => { setFieldTouched("template"); }}
                                                // defaultValue='default'
                                                />
                                                {/* {themesData.map(o => <option key={o.name} value={o.path}>{o.name}</option>)} */}

                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        themesData: state.themes.themesData,
        generalSettingData: state.generalSetting.generalSettingData,
        isThemesDataPending: state.themes.isThemesDataPending,
        isUpdateThemesPending: state.themes.isUpdateThemesPending,
        isUpdateThemesSuccess: state.themes.isUpdateThemesSuccess,
        updateThemesError: state.themes.updateThemesError,
        site: state.siteSelector.selectedSite,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setUpdateThemesError: (updateThemesError) => { dispatch(setUpdateThemesError(updateThemesError)) },
        setUpdateThemesSuccess: (isUpdateThemesSuccess) => { dispatch(setUpdateThemesSuccess(isUpdateThemesSuccess)) },
        updateThemes: (postData) => { dispatch(updateGeneralSetting(postData)) },
        fetchThemes: () => { dispatch(fetchThemes()) },
        fetchGeneralSetting: () => { dispatch(fetchGeneralSetting()) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Themes);