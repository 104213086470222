import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { deleteCategories } from '../actions/categoryActions';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Popover,
  PopoverBody,
} from 'reactstrap';
import CustomModal from 'common/custommodal';
import OneCategory from './OneCategory';

const CategoryActionButtons = (props) => {
  const { emptySelected, row, deleteCategories } = props;

  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);

  const [modal1, setModal1] = React.useState(false);
  const toggle1 = () => setModal1(!modal1);

  const [popover, setPopover] = React.useState(false);
  const togglePopover = () => setPopover(!popover);

  const onDelete = () => {
    toggle();
    deleteCategories({ id: row.id }, 1, 100, '', 'name');
    emptySelected();
  };

  return (
    <div>
      <IconButton
        aria-label='key'
        className='no-border-onfocus'
        onClick={togglePopover}
        id={`Popover-${row.id}`}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popover
        placement='left'
        trigger='legacy'
        isOpen={popover}
        target={`Popover-${row.id}`}
        toggle={togglePopover}
      >
        <PopoverBody>
          <a
            role='button'
            className='anchoreless-button'
            onClick={(e) => {
              togglePopover();
              toggle1();
              e.stopPropagation();
            }}
          >
            <EditIcon /> Edit Category
          </a>

          <a
            role='button'
            className='anchoreless-button'
            onClick={(e) => {
              togglePopover();
              e.stopPropagation();
              toggle();
            }}
          >
            <DeleteIcon /> Delete Category
          </a>
        </PopoverBody>
      </Popover>
      {row.id === '1' ? (
        <CustomModal
          onModalAccept={toggle}
          successButtonText='Ok'
          modal={modal}
          toggle={toggle}
          modalTitle='Info'
          modalBody="You can't delete Uncategorized"
        />
      ) : (
        <CustomModal
          onModalAccept={onDelete}
          onModalCancel={toggle}
          modal={modal}
          toggle={toggle}
          modalTitle='Delete'
          modalBody='Are you sure you want to delete?'
        />
      )}
      <Modal isOpen={modal1} toggle={toggle1}>
        <ModalHeader toggle={toggle1}>Update Category</ModalHeader>
        <ModalBody>
          <OneCategory id={row.id} toggle={toggle1} />
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteCategories: (postData, pageNo, perPage, keyword, order) => {
      dispatch(deleteCategories(postData, pageNo, perPage, keyword, order));
    },
  };
};

export default connect(null, mapDispatchToProps)(CategoryActionButtons);
