import React from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    FormText,
    Label,
    Input,
    Row,
    Col,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    CardText,
    Button,
} from 'reactstrap';
import classnames from 'classnames';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import CustomSnackbar from 'common/customsnackbar';

const weekday = [
    { value: '0', label: 'Sunday' },
    { value: '1', label: 'Monday' },
    { value: '2', label: 'Tuesday' },
    { value: '3', label: 'Wednesday' },
    { value: '4', label: 'Thursday' },
    { value: '5', label: 'Friday' },
    { value: '6', label: 'Saturday' },
];

class CommonTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            'activeTab': '1'
        };
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                'activeTab': tab
            });
        }
    }
    render() {
        return (
            <div>
                <Formik
                    enableReinitialize
                    initialValues={{
                        someinput: '',
                        start_of_week: 0
                    }}
                    validationSchema={Yup.object().shape({
                        someinput: Yup.string().required('Input is required'),
                    })}
                    onSubmit={({ someinput }) => {
                        const postData = {
                        };
                        CustomSnackbar('success', 'Success Message');
                        CustomSnackbar('error', 'Error Message');
                    }}
                >
                    {({ errors, touched, setFieldValue, values, setFieldTouched, dirty }) => (

                        <Form id="generalSettingForm">

                            <div className="page-header">
                                <Row>
                                    <Col xs="12" sm="6" className="page-title-container">
                                        <h1 className='page-title'>{this.props.title}</h1>
                                    </Col>
                                    <Col xs="12" sm="6" className="text-right page-header-button">
                                        <button type="submit" disabled={!dirty} className="btn btn-primary">Save Changes</button>
                                    </Col>
                                </Row>
                            </div>
                            <div className="content-wrapper">
                                <Card>
                                    <CardTitle>
                                        Form Element
                                    </CardTitle>
                                    <CardBody>
                                        <FormGroup row>
                                            <Label lg="3" xs="12" className="control-label" for="someinput">Some Label</Label>
                                            <Col lg="4" xs="12">
                                                <Field id="someinput" name="someinput" type="text" className={'form-control' + (errors.someinput && touched.someinput ? ' is-invalid' : '')} />
                                                <ErrorMessage name="someinput" component="div" className="invalid-feedback" />
                                            </Col>
                                        </FormGroup>
                                        <Row form>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label className="control-label" for="exampleEmail">Email</Label>
                                                    <Field placeholder="with a placeholder" id="exampleEmail" name="email" type="email" className="form-control" />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <Label className="control-label" for="weekday">Start of week</Label>
                                                <Select
                                                    id='weekday'
                                                    options={weekday}
                                                    styles={{
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            border: '1px solid #e9ecef',
                                                            borderRadius: '2px',
                                                        }),
                                                        singleValue: (provided, state) => ({
                                                            ...provided,
                                                            color: '#525f7f',
                                                        }),
                                                        valueContainer: (provided, state) => ({
                                                            ...provided,
                                                            padding: '0 0.75rem',
                                                        }),
                                                    }}
                                                    onChange={(option) => { setFieldValue("start_of_week", option.value); }}
                                                    onBlur={() => { setFieldTouched("start_of_week"); }}
                                                    value={weekday.find(option => option.value === values.start_of_week) || ''}
                                                />
                                            </Col>
                                            <Col md="3">
                                                <Label className="control-label">Radio Button</Label>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" />
                                                         Select Me
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <Label className="control-label">Check Box</Label>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="checkbox" />
                                                         Check Me
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>

                            </div>
                        </Form>
                    )}
                </Formik>
            </div >
        );
    }
}

export default CommonTemplate;
