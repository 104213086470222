import React from 'react';
import Helmet from 'react-helmet';
import { appName } from 'utils/axios'
import { useTranslation } from 'react-i18next'

const TitleProvider = props => {
    const { component: Component, title, ...rest } = props;
    const { t } = useTranslation()

    var defaultTitle = 'hopCMS App';

    return (
        <React.Fragment>
            <Helmet>
                <title>{title ? t(title) + appName : defaultTitle}</title>
            </Helmet>
            <Component {...rest} title={title} />
        </React.Fragment>
    );
};

export default TitleProvider;