import React from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardBody,
  Row,
  Col
} from 'reactstrap';
import classnames from 'classnames';

class TabsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.vtoggle = this.vtoggle.bind(this);
    this.state = {
      'activeTab': '1',
      'vactiveTab': 'v1',
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        'activeTab': tab
      });
    }
  }

  vtoggle(tab) {
    if (this.state.vactiveTab !== tab) {
      this.setState({
        'vactiveTab': tab
      });
    }
  }

  render() {
    return (
      <div>
        {/* --------------------------------------------------------------------------------*/}
        {/* Start Inner Div*/}
        {/* --------------------------------------------------------------------------------*/}
        {/* --------------------------------------------------------------------------------*/}
        {/* Row*/}
        {/* --------------------------------------------------------------------------------*/}
        <Card>
          <CardTitle className="p-3 mb-0">
            <i className="mdi mdi-tab-unselected mr-2"> </i>
            Tabs
          </CardTitle>

          <CardBody className="">
            <div className="tabs customtab">
              <Nav tabs className="tabs-horizontal">
                <NavItem>
                  <NavLink
                    className={classnames({
                      'active': this.state.activeTab === '1'
                    })}
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                    Tab1
                </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      'active': this.state.activeTab === '2'
                    })}
                    onClick={() => {
                      this.toggle('2');
                    }}
                  >
                    Moar Tabs
                </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <h4>Tab 1 Contents</h4>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="6">
                      <h3>Special Title Treatment</h3>
                      <p>
                        With supporting text below as a natural lead-in to
                        additional content.
                      </p>
                      <Button>Go somewhere</Button>
                    </Col>
                    <Col sm="6">
                      <h3>Special Title Treatment</h3>
                      <p>
                        With supporting text below as a natural lead-in to
                        additional content.
                    </p>
                      <Button>Go somewhere</Button>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardTitle>
            Verical Pills
          </CardTitle>
          <CardBody>
            <div className="vtabs customvtab">
              <Nav tabs className="tabs-vertical">
                <NavItem>
                  <NavLink
                    className={classnames({
                      'active': this.state.vactiveTab === 'v1'
                    })}
                    onClick={() => {
                      this.vtoggle('v1');
                    }}
                  >
                    Tab1
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      'active': this.state.vactiveTab === 'v2'
                    })}
                    onClick={() => {
                      this.vtoggle('v2');
                    }}
                  >
                    Tab 2
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.vactiveTab}>
                <TabPane tabId="v1">
                  <Row>
                    <Col sm="12">
                      <h4>Tab 1 Contents</h4>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="v2">
                  <Row>
                    <Col sm="12">
                      <h4>Tab 2 Contents</h4>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </CardBody>
        </Card>
        {/* --------------------------------------------------------------------------------*/}
        {/* Row*/}
        {/* --------------------------------------------------------------------------------*/}

        {/* --------------------------------------------------------------------------------*/}
        {/* End Inner Div*/}
        {/* --------------------------------------------------------------------------------*/}
      </div>
    );
  }
}

export default TabsComponent;
