import React from 'react'
import {
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Col,
} from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CustomSpinner from 'common/CustomSpinner';
import { connect } from 'react-redux';
import { fetchSlug, addComment, fetchComments, fetchComment, setAddUpdateCommentError, updateComment, setAddUpdateCommentSuccess } from '../actions/commentActions';
import CustomModal from 'common/custommodal'

//icons
import PersonIcon from '@material-ui/icons/Person';


const emptyFormValues = {
    name: '',
    slug: '',
    description: '',
}

class OneComment extends React.Component {

    componentDidMount() {
        const { id } = this.props;
        if (id !== "add")
            this.props.fetchComment(id);
    }

    render() {

        const { id, toggle } = this.props;
        const {
            commentData,
            isCommentDataPending,
            isAddUpdateCommentPending,
            addUpdateCommentError,
            setAddUpdateCommentError,
            isAddUpdateCommentSuccess,
            setAddUpdateCommentSuccess,
            addComment,
            updateComment,
            fetchComments,
            fetchSlug,
        } = this.props;

        if (id !== "add" && isCommentDataPending && !isAddUpdateCommentPending) {
            return <CustomSpinner />;
        }

        return (
            <div>
                {addUpdateCommentError &&
                    <CustomModal
                        onModalAccept={() => { setAddUpdateCommentError(null); }}
                        modal={true}
                        successButtonText="Ok"
                        modalTitle="Oops..."
                        modalBody={addUpdateCommentError}
                    />
                }
                {isAddUpdateCommentSuccess &&
                    <CustomModal
                        onModalAccept={() => {
                            setAddUpdateCommentSuccess(false);
                            toggle();
                            fetchComments();
                        }}
                        modal={true}
                        modalTitle="Success"
                        successButtonText="Ok"
                        modalBody={id !== "add" ? 'Comment updated successfully' : 'Comment added successfully'}
                    />
                }
                <div >
                    {isCommentDataPending && <CustomSpinner />}
                </div>
                <div >
                    <Formik
                        enableReinitialize
                        initialValues={
                            id === "add" ?
                                emptyFormValues : {
                                    name: commentData.name || '',
                                    slug: commentData.slug || '',
                                    description: commentData.description || '',
                                }
                        }
                        validationSchema={Yup.object().shape({
                            name: Yup.string().required('Name is required'),
                            slug: Yup.string().required('Slug is required'),
                        })}
                        onSubmit={({ name, slug, description }) => {
                            const postData = {
                                name: name,
                                slug: slug,
                                description: description,
                            };
                            id === "add" ? addComment(postData) : updateComment(id, postData)
                        }}
                    >
                        {({ errors, touched, setFieldValue, values, setFieldTouched, resetForm }) => (
                            <Form id="loginform">
                                <Row>
                                    <Col xs="12">
                                        <InputGroup className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <PersonIcon fontSize='small' />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Field onBlur={(e) => { fetchSlug({ string: values.name }, setFieldValue); }} placeholder="Name" name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                        </InputGroup>
                                    </Col>
                                    <Col xs="12">
                                        <InputGroup className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <PersonIcon fontSize='small' />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Field placeholder="Slug" name="slug" type="text" className={'form-control' + (errors.slug && touched.slug ? ' is-invalid' : '')} />
                                            <ErrorMessage name="slug" component="div" className="invalid-feedback" />
                                        </InputGroup>
                                    </Col>
                                    <Col xs="12">
                                        <InputGroup className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <PersonIcon fontSize='small' />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Field style={{ height: '100px' }} component="textarea" placeholder="description" name="description" type="text" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                                            <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                        </InputGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs="6" className="mb-3">
                                        <button type="submit" className="btn btn-block btn-primary" disabled={isAddUpdateCommentPending}>{id === "add" ? "Create" : "Update"}</button>
                                    </Col>
                                    <Col xs="6" className="mb-3">
                                        <button type="reset" className="btn btn-block btn-outline-primary" disabled={isAddUpdateCommentPending} onClick={(e) => {
                                            e.preventDefault();
                                            resetForm(emptyFormValues);
                                        }}>Clear</button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        slug: state.comment.slug,
        commentData: state.comment.commentData,
        isFetchCommentPending: state.comment.isFetchCommentPending,
        comments: state.comment.comments,
        isAddUpdateCommentPending: state.comment.isAddUpdateCommentPending,
        addUpdateCommentError: state.comment.addUpdateCommentError,
        isCommentDataPending: state.comment.isCommentDataPending,
        isAddUpdateCommentSuccess: state.comment.isAddUpdateCommentSuccess,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addComment: (postData) => { dispatch(addComment(postData)) },
        fetchComments: (pageNo, perPage, keyword) => { dispatch(fetchComments(pageNo, perPage, keyword)) },
        fetchComment: (id) => { dispatch(fetchComment(id)) },
        setAddUpdateCommentError: (error) => { dispatch(setAddUpdateCommentError(error)) },
        updateComment: (id, postData) => { dispatch(updateComment(id, postData)) },
        fetchSlug: (keyword, setFieldValue) => { dispatch(fetchSlug(keyword, setFieldValue)) },
        setAddUpdateCommentSuccess: (isAddUpdateCommentSuccess) => { dispatch(setAddUpdateCommentSuccess(isAddUpdateCommentSuccess)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OneComment);