import React from 'react';
import i18n from 'common/i18n';
import { I18nextProvider } from 'react-i18next';
import indexRoutes from 'routes';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from 'redux/store';
import { history } from 'helpers/history';
import PrivateRoute from 'routes/privateroutes';
import GuestRoute from 'routes/guestroutes';
import Blanklayout from 'layouts/blanklayout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
    };
  }

  render() {
    return (
      <Provider store={configureStore()}>
        <I18nextProvider i18n={i18n}>
          <ToastContainer
            position='bottom-right'
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            autoClose={2500}
          />
          <Router basename='/' history={history}>
            <Switch>
              <GuestRoute
                path='/authentication/login'
                component={Blanklayout}
              />
              ;
              {indexRoutes.map((prop, key) => {
                return (
                  <PrivateRoute
                    path={prop.path}
                    key={key}
                    component={prop.component}
                  />
                );
              })}
            </Switch>
          </Router>
        </I18nextProvider>
      </Provider>
    );
  }
}

export default App;
