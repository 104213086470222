//website
export const FETCH_WEBSITE_SUCCESS = 'FETCH_WEBSITE_SUCCESS';
export const FETCH_WEBSITE_PENDING = 'FETCH_WEBSITE_PENDING';
export const FETCH_WEBSITE_ERROR = 'FETCH_WEBSITE_ERROR';
export const FETCH_WEBSITE = 'FETCH_WEBSITE';
export const FETCH_WEBSITE_COUNT = 'FETCH_WEBSITE_COUNT';
export const ADD_UPDATE_WEBSITE_ERROR = 'ADD_UPDATE_WEBSITE_ERROR';
export const FETCH_ONE_WEBSITE = 'FETCH_ONE_WEBSITE';
export const FETCH_ONE_WEBSITE_PENDING = 'FETCH_ONE_WEBSITE_PENDING';
export const ADD_UPDATE_WEBSITE_PENDING = 'ADD_UPDATE_WEBSITE_PENDING';
export const ADD_WEBSITE_SUCCESS = 'ADD_WEBSITE_SUCCESS';
export const UPDATE_WEBSITE_SUCCESS = 'UPDATE_WEBSITE_SUCCESS';
export const SUSPEND_ACTIVE_WEBSITE_SUCCESS = 'SUSPEND_ACTIVE_WEBSITE_SUCCESS';
export const DELETE_WEBSITE_SUCCESS = 'DELETE_WEBSITE_SUCCESS';

export const FETCH_SERVER = 'FETCH_SERVER';
export const FETCH_SERVER_PENDING = 'FETCH_SERVER_PENDING';
//group
export const FETCH_GROUP_SUCCESS = 'FETCH_GROUP_SUCCESS';
export const FETCH_GROUP_PENDING = 'FETCH_GROUP_PENDING';
export const FETCH_GROUP_ERROR = 'FETCH_GROUP_ERROR';
export const FETCH_GROUP = 'FETCH_GROUP';
export const FETCH_GROUP_COUNT = 'FETCH_GROUP_COUNT';
export const ADD_UPDATE_GROUP_ERROR = 'ADD_UPDATE_GROUP_ERROR';
export const FETCH_ONE_GROUP = 'FETCH_ONE_GROUP';
export const FETCH_ONE_GROUP_PENDING = 'FETCH_ONE_GROUP_PENDING';
export const ADD_UPDATE_GROUP_PENDING = 'ADD_UPDATE_GROUP_PENDING';
export const ADD_UPDATE_GROUP_SUCCESS = 'ADD_UPDATE_GROUP_SUCCESS';
export const FETCH_PARENT_GROUP = 'FETCH_PARENT_GROUP';
export const FETCH_PARENT_GROUP_PENDING = 'FETCH_PARENT_GROUP_PENDING';