import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import authRoutes from 'routes/authroutes.js';
import TitleProvider from 'common/titleprovider'


class Blanklayout extends React.Component {
  render() {
    return (
      <div className="authentications">
        <Switch>
          {authRoutes.map((prop, key) => {
            if (prop.redirect)
              return (
                <Redirect from={prop.path} to={prop.pathTo} key={key} />
              );
            return (
              <Route path={prop.path} render={(props) => <TitleProvider component={prop.component} title='Sign In to Dashboard' {...props} />} key={key} />
            );
          })}
        </Switch>
      </div>

    )
  }
}
export default Blanklayout;    