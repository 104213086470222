import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/Edit';
import { deleteGroups } from '../actions/groupActions';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import CustomModal from 'common/custommodal'
import OneGroup from './OneGroup'
import { history } from 'helpers/history';
import { Popover, PopoverBody, } from 'reactstrap';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const GroupActionButtons = (props) => {

    const { setPage, setSelected, page, rowsPerPage, keyword, row, rowLength, deleteGroups } = props;

    const [modal, setModal] = React.useState(false);
    const toggle = () => setModal(!modal)

    const [modal1, setModal1] = React.useState(false);
    const toggle1 = () => setModal1(!modal1)

    const [popover, setPopover] = React.useState(false);
    const togglePopover = () => setPopover(!popover)

    const onDelete = () => {
        toggle();
        if (rowLength === 1 && page !== 0) {
            setPage(page - 1);
            deleteGroups({ id: row.id }, page, rowsPerPage, keyword);
        }
        else
            deleteGroups({ id: row.id }, page + 1, rowsPerPage, keyword);
        setSelected([]);
    };


    return (
        <div>
            <IconButton aria-label='key' className='no-border-onfocus' onClick={togglePopover} id={`Popover-${row.id}`}>
                <MoreHorizIcon />
            </IconButton>
            <Popover placement='left' trigger="legacy" isOpen={popover} target={`Popover-${row.id}`} toggle={togglePopover}>
                <PopoverBody>
                    <a role='button' className='anchoreless-button' onClick={(e) => {
                        e.stopPropagation();
                        toggle1();
                    }}>
                        <EditIcon />{' '}Edit
                    </a>
                    <a role='button' className='anchoreless-button' onClick={(e) => {
                        e.stopPropagation();
                        toggle();
                    }}>
                        <DeleteIcon />{' '}Delete
                    </a>
                </PopoverBody>
            </Popover>
            <CustomModal
                onModalAccept={onDelete}
                onModalCancel={toggle}
                modal={modal}
                toggle={toggle}
                modalTitle="Delete"
                modalBody="Are you sure you want to delete?"
            />
            <Modal isOpen={modal1} toggle={toggle1}>
                <ModalHeader toggle={toggle1}>Update Group</ModalHeader>
                <ModalBody>
                    <OneGroup
                        id={row.id}
                        toggle={toggle1}
                    />
                </ModalBody>
            </Modal>
        </div >

    )
}

const mapDispatchToProps = dispatch => {
    return {
        deleteGroups: (postData, pageNo, perPage, keyword) => { dispatch(deleteGroups(postData, pageNo, perPage, keyword)) },
    };
};

export default connect(null, mapDispatchToProps)(GroupActionButtons);