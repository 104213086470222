import { instance2 } from "utils/axios";

import {
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_PENDING,
  UPLOAD_FILE_ERROR,
  FETCH_MEDIA_SUCCESS,
  FETCH_MEDIA_PENDING,
  FETCH_MEDIA_ERROR,
  FETCH_ONE_MEDIA,
  FETCH_ONE_MEDIA_PENDING,
  FETCH_MEDIA,
  FETCH_MEDIA_COUNT,
} from "./types";

import CustomSnackbar from "common/customsnackbar";

const setUploadFilePending = (isUploadFilePending) => (dispatch) => {
  dispatch({
    type: UPLOAD_FILE_PENDING,
    payload: isUploadFilePending,
  });
};

const setUploadFileError = (addUpdateWebsiteError) => (dispatch) => {
  dispatch({
    type: UPLOAD_FILE_ERROR,
    payload: addUpdateWebsiteError,
  });
};

const setUploadFileSuccess = (isUploadFileSuccess) => (dispatch) => {
  dispatch({
    type: UPLOAD_FILE_SUCCESS,
    payload: isUploadFileSuccess,
  });
};

const setFetchOneMediaPending = (isFetchOneMediaPending) => (dispatch) => {
  dispatch({
    type: FETCH_ONE_MEDIA_PENDING,
    payload: isFetchOneMediaPending,
  });
};

const setFetchMediaSuccess = (isFetchMediaSuccess) => (dispatch) => {
  dispatch({
    type: FETCH_MEDIA_SUCCESS,
    payload: isFetchMediaSuccess,
  });
};

const setFetchMediaPending = (isFetchMediaPending) => (dispatch) => {
  dispatch({
    type: FETCH_MEDIA_PENDING,
    payload: isFetchMediaPending,
  });
};

export const setFetchMediaError = (fetchMediaError) => (dispatch) => {
  dispatch({
    type: FETCH_MEDIA_ERROR,
    payload: fetchMediaError,
  });
};

// https://site.hopwebsite.com/coreapi/media/upload
export const uploadFile = (postData, cb) => async (dispatch) => {
  dispatch(setUploadFileSuccess(false));
  dispatch(setUploadFilePending(true));
  instance2.defaults.headers.post["Content-Type"] = "multipart/form-data";

  instance2
    .post("media/upload", postData)
    .then((response) => {
      dispatch(setUploadFileSuccess(true));
      if (response.data.upload_failed.length > 0)
        CustomSnackbar("error", response.data.error);
      else CustomSnackbar("success", "Media(s) uploaded successfully");
      cb(response.data.upload_sucess, response.data.upload_failed);
      dispatch(fetchMedia(1, 100));
    })
    .catch((err) => {
      err.response && dispatch(setUploadFileError(err.response.data.data));
    })
    .finally(() => {
      dispatch(setUploadFilePending(false));
    });
};

// https://site.hopwebsite.com/coreapi/post/getAll/1/10/search=&order=&post_type=attachment&post_attachment=&post_status=all&terms=&authors=&dates=
export const fetchMedia =
  (
    pageNo = 1,
    perPage = 10,
    keyword = "",
    order = "",
    postType = "attachment",
    postAttachment = "",
    post_status = "",
    terms = "",
    authors = "",
    dates = "",
    desc = false
  ) =>
  async (dispatch) => {
    dispatch(setFetchMediaPending(true));
    dispatch({
      type: FETCH_MEDIA_COUNT,
      payload: -1,
    });
    dispatch({
      type: FETCH_MEDIA,
      payload: [],
    });

    instance2
      .post(
        `post/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}&post_type=${postType}&post_attachment=${postAttachment}&post_status=${post_status}&terms=${terms}&authors=${authors}&dates=${dates}${
          desc ? " desc" : ""
        }`,
        {}
      )
      .then((response) => {
        dispatch(setFetchMediaSuccess(true));
        dispatch({
          type: FETCH_MEDIA_COUNT,
          payload: parseInt(response.data.data.count),
        });
        dispatch({
          type: FETCH_MEDIA,
          payload: response.data.data.items,
        });
        dispatch(setFetchMediaPending(false));
      })
      .catch((err) => {
        err.response && dispatch(setFetchMediaError(err.response.data.data));
        dispatch(setFetchMediaPending(false));
      });
  };

export const fetchOneMedia = (id, oldData) => async (dispatch) => {
  // dispatch({
  //     type: FETCH_ONE_MEDIA,
  //     payload: []
  // });
  dispatch(setFetchOneMediaPending(true));

  instance2
    .post(`post/get/${id}`, {})
    .then((response) => {
      let oneMedia = response.data.data;
      let updatedNode = [];
      if (oldData.length !== 0) {
        let index = oldData.findIndex((item) => item.id === id);
        updatedNode = [...oldData];
        updatedNode[index].image_alt = oneMedia.image_alt;
        updatedNode[index].post_title = oneMedia.post_title;
        updatedNode[index].post_excerpt = oneMedia.post_excerpt;
        updatedNode[index].post_content = oneMedia.post_content;
      } else updatedNode = [oneMedia];
      dispatch({
        type: FETCH_ONE_MEDIA,
        payload: updatedNode,
      });
      dispatch(setFetchOneMediaPending(false));
    })
    .catch((err) => {
      dispatch(setFetchOneMediaPending(false));
    });
};

export const updateOneMedia = (id, postData, oldData) => async (dispatch) => {
  dispatch(setFetchOneMediaPending(true));

  instance2
    .post(`media/update/${id}`, postData)
    .then((response) => {
      dispatch(fetchOneMedia(id, oldData));
      CustomSnackbar("success", "Media updated successfully");
      dispatch(setFetchOneMediaPending(false));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setUploadFileError(err.response.data.data));
        CustomSnackbar("error", err.response.data.data);
      }
      dispatch(setFetchOneMediaPending(false));
    });
};

export const deleteMedia =
  (postData, pageNo, perPage, keyword = "", refresh = true) =>
  async (dispatch) => {
    instance2
      .post("media/delete", postData)
      .then((response) => {
        refresh && dispatch(fetchMedia(pageNo, perPage, keyword));
      })
      .catch((err) => {
        err.response && dispatch(setFetchMediaError(err.response.data.data));
      });
  };
