import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
  colors
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import addFile from 'assets/images/undraw_add_file2_gvbb.svg';

import { bytesToSize } from 'utils/fun';

const useStyles = makeStyles(theme => ({
  root: {},
  dropZone: {
    border: `1px dashed ${colors.grey[200]}`,
    padding: '48px',
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  image: {
    width: 130
  },
  info: {
    marginTop: '8px'
  },
  list: {
    maxHeight: 320
  },
  icon: {
    minWidth: '32px'
  },
  actions: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: '16px'
    }
  }
}));

const FilesDropzone = props => {
  const { onUploadClick, CustomAction, media, className, ...rest } = props;

  const classes = useStyles();

  const [files, setFiles] = useState([]);
  const [successList, setSuccessList] = useState([]);
  const [failedList, setFailedList] = useState([]);

  const [result, setResult] = useState([]);

  const handleDrop = useCallback(acceptedFiles => {
    setFiles(files => [...files].concat(acceptedFiles));
  }, []);

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop
  });

  return (
    <div
      // {...rest}
      className={clsx(classes.root, className)}
    >
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div>
          <img
            alt="Select file"
            className={classes.image}
            src={addFile}
          />
        </div>
        <div>
          <Typography
            gutterBottom
            variant="h3"
          >
            Select files
          </Typography>
          <Typography
            className={classes.info}
            color="textSecondary"
            variant="body1"
          >
            Drop files here or click <Link underline="always">browse</Link>{' '}
            thorough your machine
          </Typography>
        </div>
      </div>
      {files.length > 0 && (
        <Fragment>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {files.map((file, i) => (
                <ListItem
                  divider={i < files.length - 1}
                  key={i}
                >
                  <ListItemIcon className={classes.icon}>
                    <FileCopyIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.fileName}
                    primary={file.name}
                    secondary={bytesToSize(file.size)}
                  />
                  <Tooltip title="Remove">
                    <IconButton edge="end" onClick={() => setFiles([...files].slice(0, i).concat([...files].slice(i + 1)))} >
                      <HighlightOffIcon />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
          <div className={classes.actions}>
            <Button
              onClick={handleRemoveAll}
              size="small"
            >
              Remove all
            </Button>
            <Button
              onClick={() => onUploadClick(files, (successList, failedList) => { setResult([...files]); setFiles([]); setSuccessList(successList); setFailedList(failedList); })}
              color="secondary"
              size="small"
              variant="contained"
            >
              Upload files
            </Button>
          </div>
        </Fragment>
      )}
      <br />
      <Typography
        gutterBottom
        variant="h6"
      >
        Uploaded Media
          </Typography>
      {result.length > 0 && (
        <Fragment>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {result.map((file, i) => (
                <ListItem
                  divider={i < files.length - 1}
                  key={i}
                >
                  {successList.some(media => media.name === `media${i}`) ? <CheckCircleIcon /> : <ErrorIcon />}
                  <ListItemIcon className={classes.icon}>
                    {/* <FileCopyIcon /> */}
                    <img width='75' height='75' src={file.type.includes("image") && URL.createObjectURL(file) || "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg"} />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.fileName}
                    primary={file.name}
                    secondary={bytesToSize(file.size)}
                  />
                  {successList.find(media => media.name === `media${i}`) && <CustomAction allMedia={media} oneMedia={successList.find(media => media.name === `media${i}`).data} {...rest} />}
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
        </Fragment>
      )}
    </div>
  );
};

FilesDropzone.propTypes = {
  className: PropTypes.string
};


const mapStateToProps = (state) => {
  return {
    media: state.media.media,
  };
};

const mapDispatchToProps = dispatch => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilesDropzone);
