import React from 'react';
import MuiTable from 'common/mui-table';
import { connect } from 'react-redux';
import {
  fetchPosts,
  setFetchPostError,
  deletePosts,
} from '../../actions/postActions';
import PageActionButtons from './PageActionButtons';
import PageMassActionButtons from './PageMassActionButtons';
import { history } from 'helpers/history';
import { Badge, Row, Col, Label } from 'hopcms-ui';
import { Formik, Form, ErrorMessage } from 'formik';
import Select from 'react-select';

import { Button, Drawer, TextField } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PageSkeleton from './PageSkeleton';

function CustomBadge(props) {
  const { text, ...rest } = props;

  return (
    <div>
      <Badge {...rest}>{text}</Badge>
    </div>
  );
}

class PageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      site: '',
      open: false,
      formData: { categories: [], tags: [] },
    };
  }

  componentDidMount() {
    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
      });
      this.props.fetchPosts(1, 10, '', '', 'page', this.props.status);
    }
  }

  componentDidUpdate() {
    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
      });
      this.props.fetchPosts(1, 10, '', '', 'page', this.props.status);
    }
  }

  render() {
    const emptyFormValues = {
      author: [],
      category: [],
      tag: [],
      from: '',
      to: '',
    };

    const headCells = [
      {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: '#',
        hidden: true,
      },
      {
        id: 'title',
        numeric: false,
        disablePadding: false,
        label: 'Title',
        width: '90%',
      },
      {
        id: 'author',
        numeric: false,
        disablePadding: false,
        label: 'Author',
        hidden: true,
      },
      {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'Date',
        width: '11%',
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        component: CustomBadge,
      },
      {
        id: 'action',
        numeric: true,
        disablePadding: false,
        label: 'Action',
        width: '1%',
      },
    ];

    const createData = (id, slug, title, author, date, status, customProps) => {
      return {
        id,
        slug,
        title,
        author,
        date,
        status,
        customProps,
      };
    };

    const onReload = (rowsPerPage, keyword) => {
      this.props.fetchPosts(
        1,
        rowsPerPage,
        keyword,
        '',
        'page',
        this.props.status
      );
    };

    const handlePaginationChange = (pageNo, perPage, keyword) => {
      this.props.fetchPosts(
        pageNo,
        perPage,
        keyword,
        '',
        'page',
        this.props.status
      );
    };

    const onMultiDelete = (postData, pageNo, perPage, keyword) => {
      this.props.deletePosts(
        postData,
        pageNo,
        perPage,
        keyword,
        '',
        'page',
        this.props.status
      );
    };

    const onSearch = (keyword, perPage) => {
      if (keyword.length > 2 || keyword.length === 0)
        this.props.fetchPosts(
          1,
          perPage,
          keyword,
          '',
          'page',
          this.props.status
        );
    };

    const generatePostTitleField = (title, author) => {
      return `
        <table>
            <tr>
                <td class='MuiTableCell-root MuiTableCell-body special'>
                    <table>
                        <tr>
                            <td class='MuiTableCell-root MuiTableCell-body special'><svg class="MuiSvgIcon-root jss67 ic-sm" focusable="false" viewBox="0 0 24 24" aria-hidden="true" tabindex="-1" title="Person" data-ga-event-category="material-icons" data-ga-event-action="click" data-ga-event-label="Person"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></svg><span>${author}</span></td>
                        </tr>
                        <tr>
                            <td class='MuiTableCell-root MuiTableCell-body special'><span style="font-size: 1.2rem; font-weight: 600;">${title}</span></td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>`;
    };

    var rows = [];
    if (this.props.posts) {
      this.props.posts.map((post) => {
        return rows.push(
          createData(
            post.id,
            post.post_url,
            generatePostTitleField(
              post.post_title,
              post.post_author,
              post.post_created.split(' ')[0],
              post.thumbnail_guide
            ),
            post.post_author,
            post.post_created.split(' ')[0],
            post.post_status,
            {
              color:
                post.post_status === 'draft'
                  ? 'secondary'
                  : post.post_status === 'trash'
                  ? 'warning'
                  : 'success',
            }
          )
        );
      });
    }

    const onClose = () => {
      this.setState({ ...this.state, open: !this.state.open });
    };

    return (
      <div>
        <div className='page-header'>
          <Row>
            <Col className='page-title'>
              <h1>{this.props.t(this.props.title)}</h1>
            </Col>
            <Col className='text-right page-header-button'>
              <button
                className='btn btn-primary'
                onClick={() => {
                  history.push('add');
                }}
              >
                <AddCircleOutlineIcon className='ic-sm' /> Add Page
              </button>
            </Col>
          </Row>
        </div>

        <div className='content-wrapper'>
          <MuiTable
            headCells={headCells}
            rows={rows}
            onReload={onReload} // comment this to disable refresh option
            isFetchPending={this.props.isFetchPostPending}
            // chooseColumn={false}   //=>to false display column option
            noOfRecords={this.props.noOfRecords}
            handlePaginationChange={handlePaginationChange}
            multipleDelete={this.props.status === 'trash'}
            onMultiDelete={onMultiDelete}
            onSearch={onSearch}
            Actions={PageActionButtons}
            CustomSkeleton={PageSkeleton}
            MassActions={this.props.status !== 'trash' && PageMassActionButtons}
            otherProps={{ status: this.props.status }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchPostsuccess: state.post.isFetchPostsuccess,
    isFetchPostPending: state.post.isFetchPostPending,
    fetchPostError: state.post.fetchPostError,
    posts: state.post.posts,
    noOfRecords: state.post.noOfRecords,
    site: state.siteSelector.selectedSite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPosts: (
      pageNo,
      perPage,
      keyword = '',
      order = '',
      postType = 'page',
      post_status = 'all'
    ) => {
      dispatch(
        fetchPosts(pageNo, perPage, keyword, order, postType, post_status)
      );
    },
    setFetchPostError: (postData) => {
      dispatch(setFetchPostError(postData));
    },
    deletePosts: (
      postData,
      pageNo,
      perPage,
      keyword,
      order,
      postType,
      post_status
    ) => {
      dispatch(
        deletePosts(
          postData,
          pageNo,
          perPage,
          keyword,
          order,
          postType,
          post_status
        )
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PageList));
