import {
    UPDATE_THEMES_ERROR,
    UPDATE_THEMES_PENDING,
    UPDATE_THEMES_SUCCESS,
    FETCH_ONE_THEMES,
    FETCH_ONE_THEMES_PENDING,
} from '../actions/types';

const initialState = {
    themesData: [],
    updateThemesError: null,
    isThemesDataPending: false,
    isUpdateThemesPending: false,
    isUpdateThemesSuccess: false,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case FETCH_ONE_THEMES:
            return {
                ...state,
                themesData: action.payload
            };

        case UPDATE_THEMES_ERROR:
            return {
                ...state,
                updateThemesError: action.payload
            };

        case FETCH_ONE_THEMES_PENDING:
            return {
                ...state,
                isThemesDataPending: action.payload
            };

        case UPDATE_THEMES_PENDING:
            return {
                ...state,
                isUpdateThemesPending: action.payload
            };

        case UPDATE_THEMES_SUCCESS:
            return {
                ...state,
                isUpdateThemesSuccess: action.payload
            };

        default:
            return state;
    }
}