import { instance } from "utils/axios";
import { history } from "helpers/history";
import CustomSnackbar from "common/customsnackbar";

import {
  FETCH_USER_SUCCESS,
  FETCH_USER_PENDING,
  FETCH_USER_ERROR,
  FETCH_USER,
  FETCH_USER_COUNT,
  ADD_UPDATE_USER_ERROR,
  ADD_UPDATE_USER_PENDING,
  ADD_UPDATE_USER_SUCCESS,
  FETCH_ONE_USER,
  FETCH_ONE_USER_PENDING,
  FETCH_USER_SHIFT,
  FETCH_USER_SHIFT_PENDING,
} from "./types";
import { FETCH_USER_DETAIL } from "app/auth/actions/types";

const setFetchUserSuccess = (isFetchUserSuccess) => (dispatch) => {
  dispatch({
    type: FETCH_USER_SUCCESS,
    payload: isFetchUserSuccess,
  });
};

const setFetchUserPending = (isFetchUserPending) => (dispatch) => {
  dispatch({
    type: FETCH_USER_PENDING,
    payload: isFetchUserPending,
  });
};

const setAddUpdateUserPending = (isAddUpdateUserPending) => (dispatch) => {
  dispatch({
    type: ADD_UPDATE_USER_PENDING,
    payload: isAddUpdateUserPending,
  });
};

const setFetchOneUserPending = (isUserDataPending) => (dispatch) => {
  dispatch({
    type: FETCH_ONE_USER_PENDING,
    payload: isUserDataPending,
  });
};

const setFetchUserShiftPending = (isFetchUserShiftPending) => (dispatch) => {
  dispatch({
    type: FETCH_USER_SHIFT_PENDING,
    payload: isFetchUserShiftPending,
  });
};

export const setAddUpdateUserError = (addUpdateUserError) => (dispatch) => {
  dispatch({
    type: ADD_UPDATE_USER_ERROR,
    payload: addUpdateUserError,
  });
};

export const setAddUpdateUserSuccess =
  (isAddUpdateUserSuccess) => (dispatch) => {
    dispatch({
      type: ADD_UPDATE_USER_SUCCESS,
      payload: isAddUpdateUserSuccess,
    });
  };

export const setFetchUserError = (fetchUserError) => (dispatch) => {
  dispatch({
    type: FETCH_USER_ERROR,
    payload: fetchUserError,
  });
};

export const fetchUsers =
  (pageNo = 1, perPage = 10, keyword = "", order = "", desc = false) =>
  async (dispatch) => {
    dispatch(setFetchUserPending(true));
    dispatch({
      type: FETCH_USER_COUNT,
      payload: -1,
    });
    dispatch({
      type: FETCH_USER,
      payload: null,
    });
    instance
      .post(
        `users/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${
          desc ? " desc" : ""
        }`,
        {}
      )
      .then((response) => {
        dispatch(setFetchUserSuccess(true));
        dispatch({
          type: FETCH_USER_COUNT,
          payload: parseInt(response.data.data.count),
        });
        dispatch({
          type: FETCH_USER,
          payload: response.data.data.items,
        });
        dispatch(setFetchUserPending(false));
      })
      .catch((err) => {
        if (err.response) {
          dispatch(setFetchUserError(err.response.data.data));
          CustomSnackbar("error", err.response.data.data);
        }
        dispatch(setFetchUserPending(false));
      });
  };

export const addUser = (postData) => async (dispatch) => {
  dispatch(setAddUpdateUserSuccess(false));
  dispatch(setAddUpdateUserPending(true));
  instance
    .post("users/create", postData)
    .then((response) => {
      CustomSnackbar("success", "User added successfully");
      dispatch(setAddUpdateUserSuccess(true));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setAddUpdateUserError(err.response.data.data));
        CustomSnackbar("error", err.response.data.data);
      }
    })
    .finally(() => {
      dispatch(setAddUpdateUserPending(false));
    });
};

export const updateUser = (id, postData, currentUser) => async (dispatch) => {
  dispatch(setAddUpdateUserSuccess(false));
  dispatch(setAddUpdateUserPending(true));
  dispatch(setFetchOneUserPending(true));

  instance
    .post(`users/update/${id}`, postData)
    .then((response) => {
      dispatch({
        type: FETCH_ONE_USER,
        payload: response.data.data,
      });
      currentUser &&
        dispatch({
          type: FETCH_USER_DETAIL,
          payload: response.data.data,
        });
      CustomSnackbar("success", "User updated successfully");
      dispatch(setAddUpdateUserSuccess(true));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setAddUpdateUserError(err.response.data.data));
        CustomSnackbar("error", err.response.data.data);
      }
    })
    .finally(() => {
      dispatch(setAddUpdateUserPending(false));
      dispatch(setFetchOneUserPending(false));
    });
};

export const fetchUser = (id) => async (dispatch) => {
  dispatch(setFetchOneUserPending(true));

  instance
    .post(`users/get/${id}`, {})
    .then((response) => {
      if (!response.data.data) {
        history.push("/not-found");
      }
      dispatch({
        type: FETCH_ONE_USER,
        payload: response.data.data,
      });
      dispatch(setFetchOneUserPending(false));
    })
    .catch((err) => {
      dispatch(setFetchOneUserPending(false));
    });
};

export const activeUsers =
  (postData, limit = 10, skip = 0) =>
  async (dispatch) => {
    postData = {
      objectIds: postData,
    };

    instance
      .post("users/activate", postData)
      .catch((err) => {
        dispatch(setFetchUserError(err.response.data));
      })
      .finally(() => {
        dispatch(fetchUsers(limit, skip));
      });
  };

export const deactiveUsers =
  (postData, limit = 10, skip = 0) =>
  async (dispatch) => {
    postData = {
      objectIds: postData,
    };

    instance
      .post("users/deactivate", postData)
      .catch((err) => {
        dispatch(setFetchUserError(err.response.data));
      })
      .finally(() => {
        dispatch(fetchUsers(limit, skip));
      });
  };

export const archiveUsers = (postData) => async (dispatch) => {
  console.log(postData);
};

export const deleteUsers =
  (postData, pageNo, perPage, keyword = "") =>
  async (dispatch) => {
    instance
      .post("users/delete", postData)
      .then((response) => {
        dispatch(fetchUsers(pageNo, perPage, keyword));
      })
      .catch((err) => {
        if (err.response) {
          dispatch(setFetchUserError(err.response.data.data));
          CustomSnackbar("error", err.response.data.data);
        }
      });
  };
