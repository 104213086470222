import React from 'react'
import { Spinner } from 'reactstrap'

const spinnerStyle = {
    position: 'fixed',
    zIndex: '999',
    top: '35%',
    left: '50%',
};

export default function CustomSpinner() {
    return (
        <div style={spinnerStyle}>
            <Spinner type="grow" color="primary" />
        </div>
    )
}
