//user
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_PENDING = "FETCH_USER_PENDING";
export const FETCH_USER_ERROR = "FETCH_USER_ERROR";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_COUNT = "FETCH_USER_COUNT";
export const ADD_UPDATE_USER_ERROR = "ADD_UPDATE_USER_ERROR";
export const FETCH_ONE_USER = "FETCH_ONE_USER";
export const FETCH_ONE_USER_PENDING = "FETCH_ONE_USER_PENDING";
export const FETCH_USER_SHIFT = "FETCH_USER_SHIFT";
export const FETCH_USER_SHIFT_PENDING = "FETCH_USER_SHIFT_PENDING";
export const ADD_UPDATE_USER_PENDING = "ADD_UPDATE_USER_PENDING";
export const ADD_UPDATE_USER_SUCCESS = "ADD_UPDATE_USER_SUCCESS";
