// Import Required Components
import NotFound from 'common/NotFound';

//import all routes files
import eliteroutes from './eliteroutes';
import manageroutes from './manageroutes';
import cmsroutes from './sitecmsroutes';
import sitesettingsroutes from './sitesettingsroutes'

var ThemeRoutes = [
	...manageroutes,
	...cmsroutes,
	...sitesettingsroutes,
	...eliteroutes,
	{ path: '/', pathTo: '/manage/dashboard', name: 'Dashboard', redirect: true },
	{ path: '/not-found', name: 'Not Found', component: NotFound, routeonly: true },
];
export default ThemeRoutes;


// doc. (kind of)
/***
{
	path: '/attendance',
	name: 'Attendance',
	icon: 'mdi mdi-calendar-check',   //icon display in mini sidebar
	child: [
		{
			{
				navlabel: 'Main'      // to specify title of navigation
			},
			{
				path: '/attendance/all',
				name: 'All Attendance',
				component: AttendanceList,
			},
			{
				path: '/attendance/add',    // if no component provided than it is not included in route just link
				name: 'Add Attendance',
			},
			{
				collapse: true,     //if true we get collapsable item
				name: 'Settings',
				state: 'Settings',
				subchild: [
					{
						path: '/attendance/shift/list',
						name: 'Shift Manager',
						component: ShiftList,
					}
				]
			},
		}
	]
},
 */