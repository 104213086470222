import React from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
} from 'reactstrap';

class Dashboard extends React.Component {
	render() {
		return (
			<div>
				<div className="page-header">
					<Row>
						<Col className="page-title">
							<h1>{this.props.title}</h1>
						</Col>
					</Row>
				</div>

				<div className="content-wrapper">
					<Card>
						<CardBody>
						{this.props.title}
						</CardBody>
					</Card>
				</div>
			</div>
		);
	}
}

export default Dashboard;