import {
  FETCH_WEBSITE_SUCCESS,
  FETCH_WEBSITE_PENDING,
  FETCH_WEBSITE_ERROR,
  FETCH_WEBSITE,
  FETCH_WEBSITE_COUNT,
  ADD_UPDATE_WEBSITE_ERROR,
  FETCH_ONE_WEBSITE,
  FETCH_ONE_WEBSITE_PENDING,
  ADD_UPDATE_WEBSITE_PENDING,
  ADD_WEBSITE_SUCCESS,
  UPDATE_WEBSITE_SUCCESS,
  DELETE_WEBSITE_SUCCESS,
  SUSPEND_ACTIVE_WEBSITE_SUCCESS,
  FETCH_SERVER,
  FETCH_SERVER_PENDING,
} from "../actions/types";

const initialState = {
  isFetchWebsiteSuccess: false,
  isFetchWebsitePending: false,
  fetchWebsiteError: null,
  addUpdateWebsiteError: null,
  websiteData: [],
  isWebsiteDataPending: false,
  websites: null,
  noOfRecords: 0,
  isAddUpdateWebsitePending: false,
  isUpdateWebsiteSuccess: false,
  isAddWebsiteSuccess: false,
  isSuspendActiveWebsiteSuccess: false,
  isDeleteWebsiteSuccess: false,

  servers: null,
  isFetchServerPending: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SERVER:
      return {
        ...state,
        servers: action.payload,
      };
    case FETCH_SERVER_PENDING:
      return {
        ...state,
        isFetchServerPending: action.payload,
      };

    case FETCH_WEBSITE:
      return {
        ...state,
        websites: action.payload,
      };

    case FETCH_WEBSITE_SUCCESS:
      return {
        ...state,
        isFetchWebsiteSuccess: action.payload,
      };

    case FETCH_WEBSITE_PENDING:
      return {
        ...state,
        isFetchWebsitePending: action.payload,
      };

    case FETCH_WEBSITE_ERROR:
      return {
        ...state,
        fetchWebsiteError: action.payload,
      };

    case FETCH_WEBSITE_COUNT:
      return {
        ...state,
        noOfRecords: action.payload,
      };

    case FETCH_ONE_WEBSITE:
      return {
        ...state,
        websiteData: action.payload,
      };

    case ADD_UPDATE_WEBSITE_ERROR:
      return {
        ...state,
        addUpdateWebsiteError: action.payload,
      };

    case FETCH_ONE_WEBSITE_PENDING:
      return {
        ...state,
        isWebsiteDataPending: action.payload,
      };

    case ADD_UPDATE_WEBSITE_PENDING:
      return {
        ...state,
        isAddUpdateWebsitePending: action.payload,
      };

    case ADD_WEBSITE_SUCCESS:
      return {
        ...state,
        isAddWebsiteSuccess: action.payload,
      };

    case UPDATE_WEBSITE_SUCCESS:
      return {
        ...state,
        isUpdateWebsiteSuccess: action.payload,
      };

    case SUSPEND_ACTIVE_WEBSITE_SUCCESS:
      return {
        ...state,
        isSuspendActiveWebsiteSuccess: action.payload,
      };

    case DELETE_WEBSITE_SUCCESS:
      return {
        ...state,
        isDeleteWebsiteSuccess: action.payload,
      };

    default:
      return state;
  }
}
