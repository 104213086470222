import {
    ADD_UPDATE_POST_ERROR,
    ADD_UPDATE_POST_PENDING,
    ADD_UPDATE_POST_SUCCESS,
    FETCH_POST_SUCCESS,
    FETCH_POST_PENDING,
    FETCH_POST_ERROR,
    FETCH_POST,
    FETCH_POST_COUNT,
    FETCH_ONE_POST,
    FETCH_ONE_POST_PENDING,
    FETCH_AUTHOR,
    FETCH_AUTHOR_PENDING,
} from '../actions/types';

const initialState = {
    isFetchPostSuccess: false,
    isFetchPostPending: false,
    fetchPostError: null,
    postData: [],
    isPostDataPending: false,
    posts: null,
    noOfRecords: 0,
    addUpdatePostError: null,
    isAddUpdatePostPending: false,
    isAddUpdatePostSuccess: false,
    authors: [],
    isFetchAuthorPending: false,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case FETCH_POST:
            return {
                ...state,
                posts: action.payload
            };

        case FETCH_POST_SUCCESS:
            return {
                ...state,
                isFetchPostSuccess: action.payload
            };

        case FETCH_POST_PENDING:
            return {
                ...state,
                isFetchPostPending: action.payload
            };

        case FETCH_POST_ERROR:
            return {
                ...state,
                fetchPostError: action.payload
            };

        case FETCH_POST_COUNT:
            return {
                ...state,
                noOfRecords: action.payload
            };

        case FETCH_ONE_POST:
            return {
                ...state,
                postData: action.payload
            };

        case ADD_UPDATE_POST_ERROR:
            return {
                ...state,
                addUpdatePostError: action.payload
            };

        case FETCH_ONE_POST_PENDING:
            return {
                ...state,
                isPostDataPending: action.payload
            };

        case ADD_UPDATE_POST_PENDING:
            return {
                ...state,
                isAddUpdatePostPending: action.payload
            };

        case ADD_UPDATE_POST_SUCCESS:
            return {
                ...state,
                isAddUpdatePostSuccess: action.payload
            };


        case FETCH_AUTHOR:
            return {
                ...state,
                authors: action.payload
            };

        case FETCH_AUTHOR_PENDING:
            return {
                ...state,
                isFetchAuthorPending: action.payload
            };


        default:
            return state;
    }
}