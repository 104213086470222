import React from 'react';
import { Editor } from 'tinymce-react-hopcms';
import i18n from 'common/i18n'

class Footer extends React.Component {
  render() {

    const changeLanguage = (event) => {
      i18n.changeLanguage(event.target.value)
    }

    return (
      <footer className="footer text-center ">
        All Rights Reserved by hopCMS. Designed and Developed by{' '}
        <a href="https://www.maskoid.com">Maskoid</a>.

        <div onChange={changeLanguage}>
          <input type="radio" value="en" name="language" defaultChecked /> English{' '}
          <input type="radio" value="gj" name="language" /> Gujarati
        </div>

        <div style={{ display: 'none' }}>
          <Editor
            init={{
              menubar: false,
              plugins: [
                'advlist autolink lists link image',
                'charmap print preview anchor help',
                'searchreplace visualblocks code',
                'insertdatetime media table paste wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat table | code'
            }}
          />
        </div>
      </footer>
    );
  }
}
export default Footer;
