import {
  UPDATE_SEO_ERROR,
  UPDATE_SEO_PENDING,
  UPDATE_SEO_SUCCESS,
  FETCH_ONE_SEO,
  FETCH_ONE_SEO_PENDING,
} from '../actions/types';

const initialState = {
  updateSeoError: null,
  isSeoDataPending: false,
  isUpdateSeoPending: false,
  isUpdateSeoSuccess: false,
  seoData: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ONE_SEO:
      return {
        ...state,
        seoData: action.payload,
      };

    case UPDATE_SEO_ERROR:
      return {
        ...state,
        updateSeoError: action.payload,
      };

    case FETCH_ONE_SEO_PENDING:
      return {
        ...state,
        isSeoDataPending: action.payload,
      };

    case UPDATE_SEO_PENDING:
      return {
        ...state,
        isUpdateSeoPending: action.payload,
      };

    case UPDATE_SEO_SUCCESS:
      return {
        ...state,
        isUpdateSeoSuccess: action.payload,
      };

    default:
      return state;
  }
}
