import React from 'react';
import MuiTable from 'common/mui-table';
import { connect } from 'react-redux';
import { fetchWebsites, deleteWebsites } from '../actions/websiteActions';
import WebsiteActionButtons from './WebsiteActionButtons';
import { history } from 'helpers/history';
import WebsiteSkeleeton from './WebsiteSkeleeton';

import { Badge, Row, Col, Label } from 'hopcms-ui';

class WebsiteList extends React.Component {
  componentDidMount() {
    this.props.fetchWebsites();
  }

  render() {
    const headCells = [
      { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
      { id: 'url', numeric: false, disablePadding: false, label: 'URL' },
      {
        id: 'group',
        numeric: false,
        disablePadding: false,
        label: 'Group',
      },
      { id: 'user', numeric: false, disablePadding: false, label: 'User' },
      {
        id: 'created',
        numeric: true,
        disablePadding: false,
        label: 'Created At',
      },
      { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
      { id: 'action', numeric: true, disablePadding: false, label: 'Action' },
    ];

    const createData = (id, key, name, url, group, user, created, status) => {
      return { id, key, name, url, group, user, created, status };
    };

    const onReload = (rowsPerPage, keyword) => {
      this.props.fetchWebsites(1, rowsPerPage, keyword);
    };

    const handlePaginationChange = (pageNo, perPage, keyword) => {
      this.props.fetchWebsites(pageNo, perPage, keyword);
    };

    const onMultiDelete = (postData, pageNo, perPage, keyword) => {
      this.props.deleteWebsites(postData, pageNo, perPage, keyword);
    };

    const onSearch = (keyword, perPage) => {
      if (keyword.length > 2 || keyword.length === 0)
        this.props.fetchWebsites(1, perPage, keyword);
    };

    var rows = [];
    if (this.props.websites) {
      this.props.websites.map((website) => {
        return rows.push(
          createData(
            website.id,
            website.app_secret_key,
            website.website_name,
            website.website_url,
            website.groups.map((group) => {
              const gg = [];
              gg.push(group.website_group_name);
              return gg.join();
            }),
            website.customer_name,
            website.created_at,
            website.website_status
          )
        );
      });
    }
    return (
      <div>
        <div className='page-header'>
          <Row>
            <Col className='page-title'>
              <h1>{this.props.title}</h1>
            </Col>
            <Col className='text-right page-header-button'>
              <button
                className='mb-3 btn btn-primary'
                onClick={() => {
                  history.push('add');
                }}
              >
                Add Website
              </button>
            </Col>
          </Row>
        </div>
        <div className='content-wrapper'>
          <MuiTable
            headCells={headCells}
            rows={rows}
            onReload={onReload} // comment this to disable refresh option
            isFetchPending={this.props.isFetchWebsitePending}
            noOfRecords={this.props.noOfRecords}
            handlePaginationChange={handlePaginationChange}
            multipleDelete
            onMultiDelete={onMultiDelete}
            onSearch={onSearch}
            CustomSkeleton={WebsiteSkeleeton}
            Actions={WebsiteActionButtons}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchWebsitePending: state.website.isFetchWebsitePending,
    websites: state.website.websites,
    noOfRecords: state.website.noOfRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchWebsites: (pageNo, perPage, keyword) => {
      dispatch(fetchWebsites(pageNo, perPage, keyword));
    },
    deleteWebsites: (postData, pageNo, perPage, keyword) => {
      dispatch(deleteWebsites(postData, pageNo, perPage, keyword));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteList);
