import {
    UPDATE_GENERAL_SETTING_ERROR,
    UPDATE_GENERAL_SETTING_PENDING,
    UPDATE_GENERAL_SETTING_SUCCESS,
    FETCH_ONE_GENERAL_SETTING,
    FETCH_ONE_GENERAL_SETTING_PENDING,
} from '../actions/types';

const initialState = {
    updateGeneralSettingError: null,
    isGeneralSettingDataPending: false,
    isUpdateGeneralSettingPending: false,
    isUpdateGeneralSettingSuccess: false,
    generalSettingData: [],
};

export default function (state = initialState, action) {
    switch (action.type) {

        case FETCH_ONE_GENERAL_SETTING:
            return {
                ...state,
                generalSettingData: action.payload
            };

        case UPDATE_GENERAL_SETTING_ERROR:
            return {
                ...state,
                updateGeneralSettingError: action.payload
            };

        case FETCH_ONE_GENERAL_SETTING_PENDING:
            return {
                ...state,
                isGeneralSettingDataPending: action.payload
            };

        case UPDATE_GENERAL_SETTING_PENDING:
            return {
                ...state,
                isUpdateGeneralSettingPending: action.payload
            };

        case UPDATE_GENERAL_SETTING_SUCCESS:
            return {
                ...state,
                isUpdateGeneralSettingSuccess: action.payload
            };

        default:
            return state;
    }
}