import { instance2 } from "utils/axios";
import { history } from "helpers/history";
import CustomSnackbar from "common/customsnackbar";

import {
  FETCH_TAG_SUCCESS,
  FETCH_TAG_PENDING,
  FETCH_TAG_ERROR,
  FETCH_TAG,
  FETCH_TAG_COUNT,
  ADD_UPDATE_TAG_ERROR,
  ADD_UPDATE_TAG_PENDING,
  ADD_UPDATE_TAG_SUCCESS,
  FETCH_ONE_TAG,
  FETCH_ONE_TAG_PENDING,
} from "./types";

const setFetchTagSuccess = (isFetchTagSuccess) => (dispatch) => {
  dispatch({
    type: FETCH_TAG_SUCCESS,
    payload: isFetchTagSuccess,
  });
};

const setFetchTagPending = (isFetchTagPending) => (dispatch) => {
  dispatch({
    type: FETCH_TAG_PENDING,
    payload: isFetchTagPending,
  });
};

const setAddUpdateTagPending = (isAddUpdateTagPending) => (dispatch) => {
  dispatch({
    type: ADD_UPDATE_TAG_PENDING,
    payload: isAddUpdateTagPending,
  });
};

const setFetchOneTagPending = (isTagDataPending) => (dispatch) => {
  dispatch({
    type: FETCH_ONE_TAG_PENDING,
    payload: isTagDataPending,
  });
};

export const setAddUpdateTagError = (addUpdateTagError) => (dispatch) => {
  dispatch({
    type: ADD_UPDATE_TAG_ERROR,
    payload: addUpdateTagError,
  });
};

export const setAddUpdateTagSuccess = (isAddUpdateTagSuccess) => (dispatch) => {
  dispatch({
    type: ADD_UPDATE_TAG_SUCCESS,
    payload: isAddUpdateTagSuccess,
  });
};

export const setFetchTagError = (fetchTagError) => (dispatch) => {
  dispatch({
    type: FETCH_TAG_ERROR,
    payload: fetchTagError,
  });
};

export const fetchTags =
  (
    pageNo = 1,
    perPage = 10,
    keyword = "",
    order = "",
    postType = "post",
    has_post = 0,
    desc = false
  ) =>
  async (dispatch) => {
    dispatch(setFetchTagPending(true));
    dispatch({
      type: FETCH_TAG_COUNT,
      payload: -1,
    });
    dispatch({
      type: FETCH_TAG,
      payload: [],
    });

    instance2
      .post(
        `term/getAll/tag/${pageNo}/${perPage}/search=${keyword}&order=${order}&post_type=${postType}&has_post=${has_post}${
          desc ? " desc" : ""
        }`,
        {}
      )
      .then((response) => {
        dispatch(setFetchTagSuccess(true));
        dispatch({
          type: FETCH_TAG_COUNT,
          payload: parseInt(response.data.data.count),
        });
        dispatch({
          type: FETCH_TAG,
          payload: response.data.data.items,
        });
        dispatch(setFetchTagPending(false));
      })
      .catch((err) => {
        if (err.response) {
          dispatch(setFetchTagError(err.response.data.data));
          CustomSnackbar("error", err.response.data.data);
        }
        dispatch(setFetchTagPending(false));
      });
  };

export const addTag = (postData, toggle) => async (dispatch) => {
  dispatch(setAddUpdateTagSuccess(false));
  dispatch(setAddUpdateTagPending(true));
  instance2
    .post("term/create/tag", postData)
    .then((response) => {
      dispatch(setAddUpdateTagSuccess(true));
      toggle();
      CustomSnackbar("success", "Tag added successfully");
      dispatch(fetchTags());
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setAddUpdateTagError(err.response.data.data));
        CustomSnackbar("error", err.response.data.data);
      }
    })
    .finally(() => {
      dispatch(setAddUpdateTagPending(false));
    });
};

export const updateTag = (id, postData, toggle) => async (dispatch) => {
  dispatch(setAddUpdateTagSuccess(false));
  dispatch(setAddUpdateTagPending(true));
  dispatch(setFetchOneTagPending(true));

  instance2
    .post(`term/update/tag/${id}`, postData)
    .then((response) => {
      dispatch({
        type: FETCH_ONE_TAG,
        payload: response.data.data,
      });
      dispatch(setAddUpdateTagSuccess(true));
      toggle();
      CustomSnackbar("success", "Tag updated successfully");
      dispatch(fetchTags());
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setAddUpdateTagError(err.response.data.data));
        CustomSnackbar("error", err.response.data.data);
      }
    })
    .finally(() => {
      dispatch(setAddUpdateTagPending(false));
      dispatch(setFetchOneTagPending(false));
    });
};

export const fetchTag = (id) => async (dispatch) => {
  dispatch(setFetchOneTagPending(true));

  instance2
    .post(`term/get/tag/${id}`, {})
    .then((response) => {
      if (!response.data.data) {
        history.push("/not-found");
      }
      dispatch({
        type: FETCH_ONE_TAG,
        payload: response.data.data,
      });
      dispatch(setFetchOneTagPending(false));
    })
    .catch((err) => {
      dispatch(setFetchOneTagPending(false));
    });
};

export const deleteTags =
  (postData, pageNo, perPage, keyword = "") =>
  async (dispatch) => {
    instance2
      .post("term/delete", postData)
      .then((response) => {
        dispatch(fetchTags(pageNo, perPage, keyword));
        CustomSnackbar("success", "Tag(s) deleted successfully");
      })
      .catch((err) => {
        if (err.response) {
          dispatch(setFetchTagError(err.response.data.data));
          CustomSnackbar("error", err.response.data.data);
        }
      });
  };

export const fetchSlug = (postData, setFieldValue) => async (dispatch) => {
  instance2.post("term/slug/tag", postData).then((response) => {
    setFieldValue("slug", response.data.data);
  });
};

export const liveAddTag =
  (tagName, setState, state, instance) => async (dispatch) => {
    dispatch(setFetchTagPending(true));
    instance2
      .post("term/slug/tag", { string: tagName })
      .then((response) => {
        instance2
          .post("term/create/tag", { name: tagName, slug: response.data.data })
          .then((response) => {
            CustomSnackbar("success", "Tag added successfully");
            instance.setState({
              ...instance.state,
              formData: {
                ...instance.state.formData,
                tags: [
                  ...instance.state.formData.tags,
                  { id: response.data.data.id, name: response.data.data.name },
                ],
              },
            });
          });
      })
      .catch((err) => {
        err.response && CustomSnackbar("error", err.response.data.data);
      })
      .finally(() => {
        dispatch(setFetchTagPending(false));
      });
  };
