import {
    FETCH_COMMENT_SUCCESS,
    FETCH_COMMENT_PENDING,
    FETCH_COMMENT_ERROR,
    FETCH_COMMENT,
    FETCH_COMMENT_COUNT,
    ADD_UPDATE_COMMENT_ERROR,
    ADD_UPDATE_COMMENT_PENDING,
    ADD_UPDATE_COMMENT_SUCCESS,
    FETCH_ONE_COMMENT,
    FETCH_ONE_COMMENT_PENDING,
} from '../actions/types';

const initialState = {
    isFetchCommentSuccess: false,
    isFetchCommentPending: false,
    fetchCommentError: null,
    addUpdateCommentError: null,
    commentData: [],
    isCommentDataPending: false,
    comments: [],
    noOfRecords: 0,
    isAddUpdateCommentPending: false,
    isAddUpdateCommentSuccess: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_COMMENT:
            return {
                ...state,
                comments: action.payload
            };

        case FETCH_COMMENT_SUCCESS:
            return {
                ...state,
                isFetchCommentSuccess: action.payload
            };

        case FETCH_COMMENT_PENDING:
            return {
                ...state,
                isFetchCommentPending: action.payload
            };

        case FETCH_COMMENT_ERROR:
            return {
                ...state,
                fetchCommentError: action.payload
            };

        case FETCH_COMMENT_COUNT:
            return {
                ...state,
                noOfRecords: action.payload
            };

        case FETCH_ONE_COMMENT:
            return {
                ...state,
                commentData: action.payload
            };

        case ADD_UPDATE_COMMENT_ERROR:
            return {
                ...state,
                addUpdateCommentError: action.payload
            };

        case FETCH_ONE_COMMENT_PENDING:
            return {
                ...state,
                isCommentDataPending: action.payload
            };

        case ADD_UPDATE_COMMENT_PENDING:
            return {
                ...state,
                isAddUpdateCommentPending: action.payload
            };

        case ADD_UPDATE_COMMENT_SUCCESS:
            return {
                ...state,
                isAddUpdateCommentSuccess: action.payload
            };

        default:
            return state;
    }
}