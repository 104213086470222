import React, { Component, Fragment } from 'react'
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Card,
    CardBody,
    CardTitle,
    Col,
    Collapse,
    Button,
    FormGroup,
    Label,
    Input,
    FormText,
    UncontrolledPopover,
    PopoverHeader,
    PopoverBody,
    Modal,
    ModalHeader,
    ModalBody,

} from 'reactstrap';
import { connect } from 'react-redux'
import { uploadFile, fetchMedia, updateOneMedia, deleteMedia } from '../actions/mediaActions';
import { appSecretKey } from 'utils/axios'
import FilesDropzone from 'common/FilesDropzone';
import { bytesToSize } from 'utils/fun';
import CustomModal from 'common/custommodal'
import { IconButton, Tooltip } from '@material-ui/core'


import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';


const CustomAction = (props) => {

    const onEdit = () => {
        console.log(props)
        if (props.allMedia.length === 0 || !props.allMedia.find(media => media.id === props.oneMedia.id))
            props.switchTabChangeMedia('2', props.oneMedia);
        else
            props.switchTabChangeMedia('2', props.allMedia.find(media => media.id === props.oneMedia.id));
    }

    const oneMedia = {};

    if (props.allMedia.length === 0 || !props.allMedia.find(media => media.id === props.oneMedia.id)) {
        oneMedia.id = props.oneMedia.id
        oneMedia.altText = props.oneMedia.image_alt
        oneMedia.titleOnly = props.oneMedia.post_title
        oneMedia.caption = props.oneMedia.post_excerpt
        oneMedia.description = props.oneMedia.post_content
        oneMedia.slug = props.oneMedia.post_guid
        oneMedia.postMimeType = props.oneMedia.post_mime_type
        oneMedia.date = props.oneMedia.post_created.split(" ")[0]
        oneMedia.meta = props.oneMedia.meta
        oneMedia.author = props.oneMedia.post_author
    }
    else {
        const updatedOneMedia = props.allMedia.find(media => media.id === props.oneMedia.id)
        oneMedia.id = updatedOneMedia.id
        oneMedia.altText = updatedOneMedia.image_alt
        oneMedia.titleOnly = updatedOneMedia.post_title
        oneMedia.caption = updatedOneMedia.post_excerpt
        oneMedia.description = updatedOneMedia.post_content
        oneMedia.slug = updatedOneMedia.post_guid
        oneMedia.postMimeType = updatedOneMedia.post_mime_type
        oneMedia.date = updatedOneMedia.post_created.split(" ")[0]
        oneMedia.meta = updatedOneMedia.meta
        oneMedia.author = updatedOneMedia.post_author
    }

    if (props.allMedia.find(media => media.id === props.oneMedia.id)) {
        return (
            <Fragment>
                <Tooltip title="Edit Media">
                    <IconButton edge="end" onClick={onEdit}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            </Fragment>
        )
    }

    return null;
}

class MediaModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedImgURL: '',
            activePrimaryTab: '1',
            selectedMedia: props.selectedMedia || '',
            deleteModal: false,
            formData: {
                altText: '',
                title: '',
                caption: '',
                description: '',
                initialized: false
            },
        }
    }

    componentDidMount() {
        this.props.fetchMedia(1, 100);
    }

    togglePrimary(tab) {
        if (this.state.activePrimaryTab !== tab) this.setState({ ...this.state, activePrimaryTab: tab });
    }

    render() {

        const { uploadFile, site, updateOneMedia, deleteMedia, media, setImageState, toggle } = this.props;
        const { selectedMedia } = this.state;

        const onMediaSelect = (mediaItem) => {
            this.setState({
                ...this.state,
                selectedMedia: mediaItem,
                formData: {
                    ...this.state.formData,
                    altText: mediaItem.image_alt,
                    title: mediaItem.post_title,
                    caption: mediaItem.post_excerpt,
                    description: mediaItem.post_content,
                }
            })
        }

        const onClickHandler = (files, cb) => {
            var data = new FormData()
            files.map((file, i) => {
                data.append(`media${i}`, file, file.name)
            })
            data.append('_token', appSecretKey)
            uploadFile(data, (successList, failedList) => cb(successList, failedList))
        }

        const handleChange = (e) => {
            this.setState({
                ...this.state,
                formData: {
                    ...this.state.formData,
                    [e.target.name]: e.target.value
                }
            })
        }

        const toggleDeleteModal = (e) => {
            this.setState({
                ...this.state,
                deleteModal: !this.state.deleteModal
            })
        }

        const updateForm = (e) => {
            updateOneMedia(selectedMedia.id, {
                caption: this.state.formData.caption,
                'alt-text': this.state.formData.altText,
                description: this.state.formData.description,
                title: this.state.formData.title
            }, media)
        }

        const onDelete = (e) => {
            this.setState({
                ...this.state,
                selectedMedia: '',
                deleteModal: !this.state.deleteModal
            })
            deleteMedia({ id: selectedMedia.id }, 1, 100, "", true);
        }

        return (
            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={this.state.activePrimaryTab === '1' ? 'active' : ''}
                            onClick={() => { this.togglePrimary('1'); }}
                        >
                            Upload Files
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={this.state.activePrimaryTab === '2' ? 'active' : ''}
                            onClick={() => { this.togglePrimary('2'); }}
                        >
                            Media Library
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={this.state.activePrimaryTab === '3' ? 'active' : ''}
                            onClick={() => { this.togglePrimary('3'); }}
                        >
                            From URL
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activePrimaryTab}>
                    <TabPane tabId="1">
                        <FilesDropzone onUploadClick={onClickHandler} CustomAction={CustomAction} switchTabChangeMedia={(tabId, mediaItem) => this.setState({
                            ...this.state,
                            activePrimaryTab: tabId,
                            selectedMedia: mediaItem,
                            formData: {
                                ...this.state.formData,
                                altText: mediaItem.image_alt,
                                title: mediaItem.post_title,
                                caption: mediaItem.post_excerpt,
                                description: mediaItem.post_content,
                            }
                        })} />
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                            <Col xs="9">
                                {media.map(mediaItem => {
                                    return (
                                        <div key={mediaItem.id} style={{ display: 'inline-block', position: 'relative', cursor: 'pointer' }} onClick={() => onMediaSelect(mediaItem)} >
                                            {selectedMedia.id === mediaItem.id && <CheckCircleIcon color='primary' style={{ position: 'absolute', top: '0', right: '0' }} />}
                                            <img className='m-1' height='150' width='150' src={`${this.props.site.website_url + "/" + mediaItem.post_guid.substring(0, mediaItem.post_guid.lastIndexOf('.')) + "-150X150." + mediaItem.post_guid.substring(mediaItem.post_guid.lastIndexOf('.') + 1)}`} />
                                        </div>
                                    )
                                })}
                            </Col>
                            <Col xs="3">
                                {selectedMedia !== '' && (
                                    <Fragment>
                                        <b>ATTACHMENT DETAILS</b><br />
                                        <img height='150' width='150' src={`${this.props.site.website_url + "/" + selectedMedia.post_guid.substring(0, selectedMedia.post_guid.lastIndexOf('.')) + "-150X150." + selectedMedia.post_guid.substring(selectedMedia.post_guid.lastIndexOf('.') + 1)}`} /><br />
                                        <b>{selectedMedia.post_guid.split("/").slice(-1)[0]}</b><br />
                                        {selectedMedia.post_created.split(" ")[0]}<br />
                                        {bytesToSize(selectedMedia.meta.filesize)}<br />
                                        {`${selectedMedia.meta.width} by ${selectedMedia.meta.height} pixels`}<br />
                                        <hr />
                                        <FormGroup>
                                            <Label className="control-label" for="Alt Text">Alt Text</Label>
                                            <Input onChange={handleChange} value={this.state.formData.altText} type="text" name="altText" id="Alt Text" />
                                            <FormText color="muted">Leave empty if the image is purely decorative.</FormText>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="control-label" for="Title">Title</Label>
                                            <Input onChange={handleChange} value={this.state.formData.title} type="text" name="title" id="Title" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="control-label" for="Caption">Caption</Label>
                                            <Input onChange={handleChange} value={this.state.formData.caption} type="text" name="caption" id="Caption" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="control-label" for="Description">Description</Label>
                                            <Input onChange={handleChange} value={this.state.formData.description} type="text" name="description" id="Description" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="control-label" for="Copy Link">Copy Link</Label>
                                            <Input readOnly type="text" name="Copy Link" id="Copy Link" value={site.website_url + '/' + selectedMedia.post_guid} />
                                        </FormGroup>
                                        <button type="submit" onClick={updateForm} className="mt-4 btn btn-primary">Update</button>
                                        <button type="button" onClick={toggleDeleteModal} className="mt-4 btn btn-outline-danger pull-right">Delete</button>
                                        <br />
                                        <button type="button" onClick={() => { setImageState(selectedMedia); toggle(); }} className="mt-4 btn btn-primary pull-right">Set featured image</button>
                                    </Fragment>
                                )}

                            </Col>
                        </Row>

                    </TabPane>

                    <TabPane tabId="3">
                    </TabPane>
                </TabContent>
                <CustomModal
                    onModalAccept={onDelete}
                    onModalCancel={toggleDeleteModal}
                    modal={this.state.deleteModal}
                    toggle={toggleDeleteModal}
                    modalTitle="Delete Media"
                    modalBody="Are you sure you want to delete this media?"
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        media: state.media.media,
        site: state.siteSelector.selectedSite,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        uploadFile: (postData, cb) => { dispatch(uploadFile(postData, cb)) },
        fetchMedia: (pageNo, perPage, keyword, order, postType, postAttachment, post_status, terms, authors, dates) => { dispatch(fetchMedia(pageNo, perPage, keyword, order, postType, postAttachment, post_status, terms, authors, dates)) },
        updateOneMedia: (id, postData, oldData) => { dispatch(updateOneMedia(id, postData, oldData)) },
        deleteMedia: (postData, pageNo, perPage, keyword, refresh) => { dispatch(deleteMedia(postData, pageNo, perPage, keyword, refresh)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaModal)
