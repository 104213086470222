import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
} from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import CustomSpinner from "common/CustomSpinner";
import { connect } from "react-redux";
import { updateUser } from "../actions/userActions";

import { fetchUserDetail } from "app/auth/actions/userDetailActions";

//icons
import PersonIcon from "@material-ui/icons/Person";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import PhoneIcon from "@material-ui/icons/Phone";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
const emptyFormValues = {
  fname: "",
  lname: "",
  username: "",
  email: "",
  phone: "",
  password: "",
  role: "",
};

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modal: false };
  }

  componentDidMount() {
    this.props.fetchUserDetail();
  }
  render() {
    const { userData } = this.props;

    if (this.props.isUserDataPending && !this.props.isAddUpdateUserPending) {
      return <CustomSpinner />;
    }

    return (
      <div>
        <div>{this.props.isUserDataPending && <CustomSpinner />}</div>
        <div className="page-header">
          <Row>
            <Col className="page-title">
              <h1 className="page-title">User Profile</h1>
            </Col>
          </Row>
        </div>
        <div className="content-wrapper">
          <Card>
            <CardTitle>User Profile</CardTitle>
            <CardBody className="">
              <div>
                <Formik
                  enableReinitialize
                  initialValues={{
                    fname: userData.customer_fname || "",
                    lname: userData.customer_lname || "",
                    username: userData.customer_username || "",
                    email: userData.customer_email || "",
                    phone: userData.customer_phone || "",
                    password: "",
                    role: userData.role_id || "1",
                  }}
                  validationSchema={Yup.object().shape({
                    fname: Yup.string().required("First Name is required"),
                    lname: Yup.string().required("Last Name is required"),
                    username: Yup.string().required("Username is required"),
                    email: Yup.string()
                      .email("Email is invalid")
                      .required("Email is required"),
                    phone: Yup.string()
                      .matches(phoneRegExp, "Phone number is invalid")
                      .required("Phone number is required"),
                    // password: Yup.string().required('Password is required'),
                    role: Yup.string().required("Role is required"),
                  })}
                  onSubmit={({
                    fname,
                    lname,
                    username,
                    email,
                    phone,
                    password,
                    role,
                  }) => {
                    const postData = {
                      user_fname: fname,
                      user_lname: lname,
                      user_username: username,
                      user_email: email,
                      user_phone: phone,
                      user_password: password,
                      role: role,
                    };
                    this.props.updateUser(userData.id, postData);
                  }}
                >
                  {({
                    errors,
                    touched,
                    setFieldValue,
                    values,
                    setFieldTouched,
                    resetForm,
                  }) => (
                    <Form id="loginform">
                      <Row>
                        <Col lg="6" xs="12">
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <PersonIcon fontSize="small" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Field
                              placeholder="First Name"
                              name="fname"
                              type="text"
                              className={
                                "form-control" +
                                (errors.fname && touched.fname
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="fname"
                              component="div"
                              className="invalid-feedback"
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="6" xs="12">
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <PersonIcon fontSize="small" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Field
                              placeholder="Last Name"
                              name="lname"
                              type="text"
                              className={
                                "form-control" +
                                (errors.lname && touched.lname
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="lname"
                              component="div"
                              className="invalid-feedback"
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="6" xs="12">
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <AccountCircleIcon fontSize="small" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Field
                              placeholder="Username"
                              name="username"
                              disabled
                              type="text"
                              className={
                                "form-control" +
                                (errors.username && touched.username
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="username"
                              component="div"
                              className="invalid-feedback"
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="6" xs="12">
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <AlternateEmailIcon fontSize="small" />
                              </InputGroupText>
                            </InputGroupAddon>

                            <Field
                              placeholder="Email"
                              name="email"
                              type="text"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="6" xs="12">
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <PhoneIcon fontSize="small" />
                              </InputGroupText>
                            </InputGroupAddon>

                            <Field
                              placeholder="Phone Number"
                              name="phone"
                              type="text"
                              className={
                                "form-control" +
                                (errors.phone && touched.phone
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="invalid-feedback"
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="6" xs="12">
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <VpnKeyIcon fontSize="small" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Field
                              placeholder="Password"
                              name="password"
                              type="password"
                              className={
                                "form-control" +
                                (errors.password && touched.password
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="3" xs="6" className="mb-3">
                          <button
                            type="submit"
                            className="btn btn-block btn-primary"
                            disabled={this.props.isAddUpdateUserPending}
                          >
                            Update
                          </button>
                        </Col>
                        <Col lg="3" xs="6" className="mb-3">
                          <button
                            type="reset"
                            className="btn btn-block btn-outline-primary"
                            disabled={this.props.isAddUpdateUserPending}
                            onClick={(e) => {
                              e.preventDefault();
                              resetForm({
                                ...emptyFormValues,
                                username: values.username,
                              });
                            }}
                          >
                            Clear
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.authUser.userData,
    isAddUpdateUserPending: state.user.isAddUpdateUserPending,
    isUserDataPending: state.user.isUserDataPending,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserDetail: () => {
      dispatch(fetchUserDetail());
    },
    updateUser: (id, postData) => {
      dispatch(updateUser(id, postData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
