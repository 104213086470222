//Import Components for Manage Routes
import Dashboard from "app/manage/dashboard/views/dashboard";
import UserList from "app/manage/user/views/UserList";
import Profile from "app/manage/user/views/Profile";
import OneUser from "app/manage/user/views/OneUser";
import NotFound from "common/NotFound";
import WebsiteList from "app/manage/website/views/WebsiteList";
import OneWebsite from "app/manage/website/views/OneWebsite";
import WebsiteWizard from "app/manage/website/views/WebsiteWizard";
import GroupList from "app/manage/website/views/GroupList";

//Import icons for Manage Nav Section
import VerticalSplitIcon from "@material-ui/icons/VerticalSplit";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LanguageIcon from "@material-ui/icons/Language";
import BarChartIcon from "@material-ui/icons/BarChart";
import UpdateIcon from "@material-ui/icons/Update";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

const manageroutes = [
  {
    path: "/manage",
    name: "Manage Sites and Users",
    title: "Manage",
    id: "manage",
    icon: VerticalSplitIcon,
    child: [
      {
        path: "/manage/dashboard",
        name: "Dashboard",
        title: "Main Dashboard",
        icon: DashboardIcon,
        component: Dashboard,
      },
      {
        collapse: true,
        name: "Websites",
        icon: LanguageIcon,
        state: "Websites",
        subchild: [
          {
            path: "/manage/website/all",
            name: "All Websites",
            title: "All Websites",
            component: WebsiteList,
          },
          {
            path: "/manage/website/add",
            name: "Add New",
            title: "Create New Websites",
            component: WebsiteWizard,
          },
          {
            path: "/manage/website/group/all",
            title: "Manage Website Groups",
            name: "Groups",
            component: GroupList,
          },
        ],
      },
      {
        collapse: true,
        name: "Users",
        icon: PeopleAltIcon,
        state: "Users",
        subchild: [
          {
            path: "/manage/user/me",
            name: "Your Profile",
            title: "Your Profile",
            component: Profile,
          },
          {
            path: "/manage/user/list",
            title: "Manage Users",
            name: "All Users",
            component: UserList,
          },
          {
            path: "/manage/user/add",
            title: "Create New User",
            name: "Add New",
          },
        ],
      },
      {
        path: "/manage/monitor",
        name: "Monitor",
        title: "Monitor",
        icon: BarChartIcon,
        component: NotFound,
      },
      {
        path: "/manage/updates",
        name: "Updates",
        title: "Updates",
        icon: UpdateIcon,
        component: NotFound,
      },
    ],
  },
  {
    path: "/manage/user/:id",
    name: "Create New User",
    component: OneUser,
    routeonly: true,
  },
  {
    path: "/manage/website/:id",
    name: "Manage Website",
    component: OneWebsite,
    routeonly: true,
  },
];

export default manageroutes;
