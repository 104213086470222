import React from 'react';
import {
  Row,
  Col,
  Label,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import CustomSpinner from 'common/CustomSpinner';
import { connect } from 'react-redux';
import {
  fetchSlug,
  addCategory,
  fetchParentCategories,
  fetchCategories,
  fetchCategory,
  setAddUpdateCategoryError,
  updateCategory,
  setAddUpdateCategorySuccess,
} from '../actions/categoryActions';
import { Editor } from 'tinymce-react-hopcms';
import { fetchSeo } from 'app/site/settings/seo/actions/seoActions';
import { FormGroup } from '@material-ui/core';

const emptyFormValues = {
  name: '',
  slug: '',
  parent: '',
  description: '',
};

class OneCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modal: false };
  }

  componentDidMount() {
    const { id } = this.props;
    this.props.fetchSeo();
    if (id !== 'add') {
      this.props.fetchCategory(id);
      this.props.fetchParentCategories(id);
    }
  }

  render() {
    const { id, toggle } = this.props;
    const {
      categoryData,
      isCategoryDataPending,
      isAddUpdateCategoryPending,
      addCategory,
      updateCategory,
      parentCategories,
      fetchParentCategories,
      isFetchParentCategoryPending,
      seoData,
    } = this.props;

    if (id !== 'add' && isCategoryDataPending && !isAddUpdateCategoryPending) {
      return <CustomSpinner />;
    }

    return (
      <div>
        <div>{isCategoryDataPending && <CustomSpinner />}</div>
        <div>
          <Formik
            enableReinitialize
            initialValues={
              id === 'add'
                ? emptyFormValues
                : {
                    name: categoryData.name || '',
                    slug: categoryData.slug || '',
                    parent: categoryData.parent_id || '',
                    description: categoryData.description || '',
                  }
            }
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Name is required'),
              // slug: Yup.string().required('Slug is required'),
            })}
            onSubmit={({ name, slug, parent, description }) => {
              const postData = {
                name: name,
                slug: slug,
                parent_id: parent || '0',
                description: description,
              };
              id === 'add'
                ? addCategory(postData, toggle)
                : updateCategory(id, postData, toggle);
            }}
          >
            {({
              errors,
              touched,
              setFieldValue,
              values,
              setFieldTouched,
              resetForm,
            }) => (
              <Form id='category-form'>
                <Row>
                  <Col className='mb-2' xs='12'>
                    <FormGroup>
                      <Label className='control-label' for='name'>
                        Name
                      </Label>
                      <Field
                        id='name'
                        name='name'
                        type='text'
                        className={
                          'form-control' +
                          (errors.name && touched.name ? ' is-invalid' : '')
                        }
                      />
                      <ErrorMessage
                        name='name'
                        component='div'
                        className='invalid-feedback'
                      />
                    </FormGroup>
                  </Col>
                  <Col className='mb-2' xs='12'>
                    <FormGroup>
                      <Label className='control-label' for='slug'>
                        Slug
                      </Label>
                      <InputGroup>
                        {seoData.category_base && (
                          <InputGroupAddon addonType='append'>
                            <InputGroupText>
                              {'/' + seoData.category_base + '/'}
                            </InputGroupText>
                          </InputGroupAddon>
                        )}
                        <Field
                          id='slug'
                          name='slug'
                          type='text'
                          onBlur={(e) => {
                            setFieldValue(
                              'slug',
                              e.target.value
                                .toLowerCase()
                                .trim()
                                .replace(/[\W_]+/g, '-')
                            );
                          }}
                          className={
                            'form-control' +
                            (errors.slug && touched.slug ? ' is-invalid' : '')
                          }
                        />
                        <ErrorMessage
                          name='slug'
                          component='div'
                          className='invalid-feedback'
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  <Col className='mb-2' xs='12'>
                    <Label className='control-label' for='parent-category'>
                      Select Parent Category
                    </Label>
                    <Select
                      id='parent-category'
                      placeholder='Select Parent Category'
                      isClearable={true}
                      options={parentCategories}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      styles={{
                        container: (provided, state) => ({
                          ...provided,
                          flex: '1 1 auto',
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          border:
                            errors.shift && touched.shift
                              ? '1px solid #f62d51'
                              : '1px solid #e9ecef',
                          borderRadius: '2px',
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          color: '#525f7f',
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          padding: '0 0.75rem',
                        }),
                      }}
                      isLoading={isFetchParentCategoryPending}
                      onFocus={() => {
                        id === 'add'
                          ? fetchParentCategories()
                          : fetchParentCategories(id);
                      }}
                      onChange={(option) => {
                        setFieldValue('parent', option ? option.id : '');
                      }}
                      onBlur={() => {
                        setFieldTouched('parent');
                      }}
                      value={
                        parentCategories.find(
                          (option) => option.id === values.parent
                        ) ||
                        [{ id: categoryData.id, name: categoryData.name }].find(
                          (option) => option.id === values.parent
                        ) ||
                        ''
                      }
                    />
                    <ErrorMessage
                      name='parent'
                      component='div'
                      className='invalid-feedback'
                      style={{ display: 'block' }}
                    />
                  </Col>

                  <Col className='mb-2' xs='12'>
                    <FormGroup>
                      <Label className='control-label' for='description'>
                        Description
                      </Label>
                      <Editor
                        init={{
                          height: 250,
                          menubar: false,
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat table | code',
                        }}
                        onEditorChange={(content, editor) => {
                          setFieldValue('description', content);
                        }}
                        value={values.description}
                      />
                      <ErrorMessage
                        name='description'
                        component='div'
                        className='invalid-feedback'
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs='6' className='mb-3'>
                    <button
                      type='submit'
                      className='btn btn-block btn-primary'
                      disabled={isAddUpdateCategoryPending}
                    >
                      {id === 'add' ? 'Create' : 'Update'}
                    </button>
                  </Col>
                  <Col xs='6' className='mb-3'>
                    <button
                      type='reset'
                      className='btn btn-block btn-outline-primary'
                      disabled={isAddUpdateCategoryPending}
                      onClick={(e) => {
                        e.preventDefault();
                        resetForm(emptyFormValues);
                      }}
                    >
                      Clear
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    slug: state.category.slug,
    categoryData: state.category.categoryData,
    isFetchCategoryPending: state.category.isFetchCategoryPending,
    categories: state.category.categories,
    isFetchParentCategoryPending: state.category.isFetchParentCategoryPending,
    parentCategories: state.category.parentCategories,
    isAddUpdateCategoryPending: state.category.isAddUpdateCategoryPending,
    addUpdateCategoryError: state.category.addUpdateCategoryError,
    isCategoryDataPending: state.category.isCategoryDataPending,
    isAddUpdateCategorySuccess: state.category.isAddUpdateCategorySuccess,
    seoData: state.seo.seoData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCategory: (postData, toggle) => {
      dispatch(addCategory(postData, toggle));
    },
    fetchCategories: (pageNo, perPage, keyword) => {
      dispatch(fetchCategories(pageNo, perPage, keyword));
    },
    fetchParentCategories: (id) => {
      dispatch(fetchParentCategories(id));
    },
    fetchCategory: (id) => {
      dispatch(fetchCategory(id));
    },
    setAddUpdateCategoryError: (error) => {
      dispatch(setAddUpdateCategoryError(error));
    },
    updateCategory: (id, postData, toggle) => {
      dispatch(updateCategory(id, postData, toggle));
    },
    fetchSlug: (keyword, setFieldValue) => {
      dispatch(fetchSlug(keyword, setFieldValue));
    },
    setAddUpdateCategorySuccess: (isAddUpdateCategorySuccess) => {
      dispatch(setAddUpdateCategorySuccess(isAddUpdateCategorySuccess));
    },
    fetchSeo: () => {
      dispatch(fetchSeo());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OneCategory);
