import {
    FETCH_TAG_SUCCESS,
    FETCH_TAG_PENDING,
    FETCH_TAG_ERROR,
    FETCH_TAG,
    FETCH_TAG_COUNT,
    ADD_UPDATE_TAG_ERROR,
    ADD_UPDATE_TAG_PENDING,
    ADD_UPDATE_TAG_SUCCESS,
    FETCH_ONE_TAG,
    FETCH_ONE_TAG_PENDING,
} from '../actions/types';

const initialState = {
    isFetchTagSuccess: false,
    isFetchTagPending: false,
    fetchTagError: null,
    addUpdateTagError: null,
    tagData: [],
    isTagDataPending: false,
    tags: [],
    noOfRecords: 0,
    isAddUpdateTagPending: false,
    isAddUpdateTagSuccess: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_TAG:
            return {
                ...state,
                tags: action.payload
            };

        case FETCH_TAG_SUCCESS:
            return {
                ...state,
                isFetchTagSuccess: action.payload
            };

        case FETCH_TAG_PENDING:
            return {
                ...state,
                isFetchTagPending: action.payload
            };

        case FETCH_TAG_ERROR:
            return {
                ...state,
                fetchTagError: action.payload
            };

        case FETCH_TAG_COUNT:
            return {
                ...state,
                noOfRecords: action.payload
            };

        case FETCH_ONE_TAG:
            return {
                ...state,
                tagData: action.payload
            };

        case ADD_UPDATE_TAG_ERROR:
            return {
                ...state,
                addUpdateTagError: action.payload
            };

        case FETCH_ONE_TAG_PENDING:
            return {
                ...state,
                isTagDataPending: action.payload
            };

        case ADD_UPDATE_TAG_PENDING:
            return {
                ...state,
                isAddUpdateTagPending: action.payload
            };

        case ADD_UPDATE_TAG_SUCCESS:
            return {
                ...state,
                isAddUpdateTagSuccess: action.payload
            };

        default:
            return state;
    }
}