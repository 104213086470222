import React from 'react';
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Label,
} from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CustomSpinner from 'common/CustomSpinner';
import { connect } from 'react-redux';
import {
  fetchSlug,
  addTag,
  fetchTags,
  fetchTag,
  setAddUpdateTagError,
  updateTag,
  setAddUpdateTagSuccess,
} from '../actions/tagActions';
import { fetchSeo } from 'app/site/settings/seo/actions/seoActions';
import { Editor } from 'tinymce-react-hopcms';
import { FormGroup } from '@material-ui/core';

const emptyFormValues = {
  name: '',
  slug: '',
  description: '',
};

class OneTag extends React.Component {
  componentDidMount() {
    const { id } = this.props;
    this.props.fetchSeo();
    if (id !== 'add') this.props.fetchTag(id);
  }

  render() {
    const { id, toggle } = this.props;
    const {
      tagData,
      isTagDataPending,
      isAddUpdateTagPending,
      addTag,
      updateTag,
      seoData,
    } = this.props;

    if (id !== 'add' && isTagDataPending && !isAddUpdateTagPending) {
      return <CustomSpinner />;
    }

    return (
      <div>
        <div>{isTagDataPending && <CustomSpinner />}</div>
        <div>
          <Formik
            enableReinitialize
            initialValues={
              id === 'add'
                ? emptyFormValues
                : {
                    name: tagData.name || '',
                    slug: tagData.slug || '',
                    description: tagData.description || '',
                  }
            }
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Name is required'),
              // slug: Yup.string().required('Slug is required'),
            })}
            onSubmit={({ name, slug, description }) => {
              const postData = {
                name: name,
                slug: slug,
                description: description,
              };
              id === 'add'
                ? addTag(postData, toggle)
                : updateTag(id, postData, toggle);
            }}
          >
            {({
              errors,
              touched,
              setFieldValue,
              values,
              setFieldTouched,
              resetForm,
            }) => (
              <Form id='tagform'>
                <Row>
                  <Col className='mb-2' xs='12'>
                    <FormGroup>
                      <Label className='control-label' for='name'>
                        Name
                      </Label>
                      <Field
                        id='name'
                        name='name'
                        type='text'
                        className={
                          'form-control' +
                          (errors.name && touched.name ? ' is-invalid' : '')
                        }
                      />
                      <ErrorMessage
                        name='name'
                        component='div'
                        className='invalid-feedback'
                      />
                    </FormGroup>
                  </Col>
                  <Col className='mb-2' xs='12'>
                    <FormGroup>
                      <Label className='control-label' for='slug'>
                        Slug
                      </Label>
                      <InputGroup>
                        {seoData.tag_base && (
                          <InputGroupAddon addonType='append'>
                            <InputGroupText>
                              {'/' + seoData.tag_base + '/'}
                            </InputGroupText>
                          </InputGroupAddon>
                        )}
                        <Field
                          id='slug'
                          name='slug'
                          type='text'
                          onBlur={(e) => {
                            setFieldValue(
                              'slug',
                              e.target.value
                                .toLowerCase()
                                .trim()
                                .replace(/[\W_]+/g, '-')
                            );
                          }}
                          className={
                            'form-control' +
                            (errors.slug && touched.slug ? ' is-invalid' : '')
                          }
                        />
                        <ErrorMessage
                          name='slug'
                          component='div'
                          className='invalid-feedback'
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col className='mb-2' xs='12'>
                    <FormGroup>
                      <Label className='control-label' for='description'>
                        Description
                      </Label>
                      <Editor
                        init={{
                          height: 250,
                          menubar: false,
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat table | code',
                        }}
                        onEditorChange={(content, editor) => {
                          setFieldValue('description', content);
                        }}
                        value={values.description}
                      />
                      <ErrorMessage
                        name='description'
                        component='div'
                        className='invalid-feedback'
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs='6' className='mb-3'>
                    <button
                      type='submit'
                      className='btn btn-block btn-primary'
                      disabled={isAddUpdateTagPending}
                    >
                      {id === 'add' ? 'Create' : 'Update'}
                    </button>
                  </Col>
                  <Col xs='6' className='mb-3'>
                    <button
                      type='reset'
                      className='btn btn-block btn-outline-primary'
                      disabled={isAddUpdateTagPending}
                      onClick={(e) => {
                        e.preventDefault();
                        resetForm(emptyFormValues);
                      }}
                    >
                      Clear
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    slug: state.tag.slug,
    tagData: state.tag.tagData,
    isFetchTagPending: state.tag.isFetchTagPending,
    tags: state.tag.tags,
    isAddUpdateTagPending: state.tag.isAddUpdateTagPending,
    addUpdateTagError: state.tag.addUpdateTagError,
    isTagDataPending: state.tag.isTagDataPending,
    isAddUpdateTagSuccess: state.tag.isAddUpdateTagSuccess,
    seoData: state.seo.seoData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTag: (postData, toggle) => {
      dispatch(addTag(postData, toggle));
    },
    fetchTags: (pageNo, perPage, keyword) => {
      dispatch(fetchTags(pageNo, perPage, keyword));
    },
    fetchTag: (id) => {
      dispatch(fetchTag(id));
    },
    setAddUpdateTagError: (error) => {
      dispatch(setAddUpdateTagError(error));
    },
    updateTag: (id, postData, toggle) => {
      dispatch(updateTag(id, postData, toggle));
    },
    fetchSlug: (keyword, setFieldValue) => {
      dispatch(fetchSlug(keyword, setFieldValue));
    },
    setAddUpdateTagSuccess: (isAddUpdateTagSuccess) => {
      dispatch(setAddUpdateTagSuccess(isAddUpdateTagSuccess));
    },
    fetchSeo: () => {
      dispatch(fetchSeo());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OneTag);
