import React from "react";
import MuiTable from "common/mui-table";
import { connect } from "react-redux";
import { fetchUsers, deleteUsers } from "../actions/userActions";
import UserActionButtons from "./UserActionButtons";
import { history } from "helpers/history";
import UserSkeleeton from "./UserSkeleeton";

import { Badge, Row, Col, Label } from "hopcms-ui";

class UserList extends React.Component {
  componentDidMount() {
    this.props.fetchUsers();
  }

  render() {
    const headCells = [
      {
        id: "username",
        numeric: false,
        disablePadding: false,
        label: "Username",
      },
      { id: "name", numeric: false, disablePadding: false, label: "Name" },
      { id: "email", numeric: false, disablePadding: false, label: "Email" },
      { id: "phone", numeric: true, disablePadding: false, label: "Phone" },
      { id: "role", numeric: false, disablePadding: false, label: "Role" },
      {
        id: "created",
        numeric: true,
        disablePadding: false,
        label: "Created At",
      },
      {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Active Status",
      },
      { id: "action", numeric: true, disablePadding: false, label: "Action" },
    ];

    const createData = (
      id,
      username,
      name,
      email,
      phone,
      role,
      created,
      status
    ) => {
      return { id, username, name, email, phone, role, created, status };
    };

    const onReload = (rowsPerPage, keyword) => {
      this.props.fetchUsers(1, rowsPerPage, keyword);
    };

    const handlePaginationChange = (pageNo, perPage, keyword) => {
      this.props.fetchUsers(pageNo, perPage, keyword);
    };

    const onMultiDelete = (postData, pageNo, perPage, keyword) => {
      this.props.deleteUsers(postData, pageNo, perPage, keyword);
    };

    const onSearch = (keyword, perPage) => {
      if (keyword.length > 2 || keyword.length === 0)
        this.props.fetchUsers(1, perPage, keyword);
    };

    var rows = [];
    if (this.props.users) {
      this.props.users.map((user) =>
        rows.push(
          createData(
            user.id,
            user.customer_username,
            user.customer_fname + " " + user.customer_lname,
            user.customer_email,
            user.customer_phone,
            user.role,
            user.created_at,
            user.customer_status === "1" ? "Active" : "Deactive"
          )
        )
      );
    }
    return (
      <div>
        <div className="page-header">
          <Row>
            <Col className="page-title">
              <h1>{this.props.title}</h1>
            </Col>
            <Col className="text-right page-header-button">
              <button
                className="mb-3 btn btn-primary"
                onClick={() => {
                  history.push("add");
                }}
              >
                Add User
              </button>
            </Col>
          </Row>
        </div>
        <div className="content-wrapper">
          <MuiTable
            headCells={headCells}
            rows={rows}
            onReload={onReload} // comment this to disable refresh option
            isFetchPending={this.props.isFetchUserPending}
            // chooseColumn={false}   //=>to false display column option
            noOfRecords={this.props.noOfRecords}
            handlePaginationChange={handlePaginationChange}
            multipleDelete
            onMultiDelete={onMultiDelete}
            onSearch={onSearch}
            CustomSkeleton={UserSkeleeton}
            Actions={UserActionButtons}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchUserPending: state.user.isFetchUserPending,
    users: state.user.users,
    noOfRecords: state.user.noOfRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (pageNo, perPage, keyword) => {
      dispatch(fetchUsers(pageNo, perPage, keyword));
    },
    deleteUsers: (postData, pageNo, perPage, keyword) => {
      dispatch(deleteUsers(postData, pageNo, perPage, keyword));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
