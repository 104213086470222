import React, { Fragment } from 'react'
import TableCell from '@material-ui/core/TableCell';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';


export default function MediaSkeleeton() {
    return (
        <Fragment>
            <TableCell style={{ width: '90%' }}>
                <Box display="flex" alignItems="center">
                    <Box margin={1} style={{ margin: '1px 0' }}>
                        <Skeleton variant="rect" width={75} height={75} />
                    </Box>
                    <Box width="100%" style={{ marginLeft: '8px' }}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </Box>
                </Box>
            </TableCell>
            <TableCell style={{ width: '11%' }}>
                <Skeleton variant="text" />
            </TableCell>
        </Fragment>
    )
}
