//category
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_PENDING = 'FETCH_CATEGORY_PENDING';
export const FETCH_CATEGORY_ERROR = 'FETCH_CATEGORY_ERROR';
export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const FETCH_CATEGORY_COUNT = 'FETCH_CATEGORY_COUNT';
export const ADD_UPDATE_CATEGORY_ERROR = 'ADD_UPDATE_CATEGORY_ERROR';
export const FETCH_ONE_CATEGORY = 'FETCH_ONE_CATEGORY';
export const FETCH_ONE_CATEGORY_PENDING = 'FETCH_ONE_CATEGORY_PENDING';
export const ADD_UPDATE_CATEGORY_PENDING = 'ADD_UPDATE_CATEGORY_PENDING';
export const ADD_UPDATE_CATEGORY_SUCCESS = 'ADD_UPDATE_CATEGORY_SUCCESS';
export const FETCH_PARENT_CATEGORY = 'FETCH_PARENT_CATEGORY';
export const FETCH_PARENT_CATEGORY_PENDING = 'FETCH_PARENT_CATEGORY_PENDING';

//tag
export const FETCH_TAG_SUCCESS = 'FETCH_TAG_SUCCESS'
export const FETCH_TAG_PENDING = 'FETCH_TAG_PENDING'
export const FETCH_TAG_ERROR = 'FETCH_TAG_ERROR'
export const FETCH_TAG = 'FETCH_TAG'
export const FETCH_TAG_COUNT = 'FETCH_TAG_COUNT'
export const ADD_UPDATE_TAG_ERROR = 'ADD_UPDATE_TAG_ERROR'
export const ADD_UPDATE_TAG_PENDING = 'ADD_UPDATE_TAG_PENDING'
export const ADD_UPDATE_TAG_SUCCESS = 'ADD_UPDATE_TAG_SUCCESS'
export const FETCH_ONE_TAG = 'FETCH_ONE_TAG'
export const FETCH_ONE_TAG_PENDING = 'FETCH_ONE_TAG_PENDING'

//post
export const ADD_UPDATE_POST_ERROR = 'ADD_UPDATE_POST_ERROR'
export const ADD_UPDATE_POST_PENDING = 'ADD_UPDATE_POST_PENDING'
export const ADD_UPDATE_POST_SUCCESS = 'ADD_UPDATE_POST_SUCCESS'
export const FETCH_POST_SUCCESS = 'FETCH_POST_SUCCESS';
export const FETCH_POST_PENDING = 'FETCH_POST_PENDING';
export const FETCH_POST_ERROR = 'FETCH_POST_ERROR';
export const FETCH_POST = 'FETCH_POST';
export const FETCH_POST_COUNT = 'FETCH_POST_COUNT';
export const FETCH_ONE_POST = 'FETCH_ONE_POST';
export const FETCH_ONE_POST_PENDING = 'FETCH_ONE_POST_PENDING';

//author
export const FETCH_AUTHOR = 'FETCH_AUTHOR';
export const FETCH_AUTHOR_PENDING = 'FETCH_AUTHOR_PENDING';
