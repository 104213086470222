import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { deleteWebsites, changeWebsiteStatus } from '../actions/websiteActions';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { connect } from 'react-redux';
import { history } from 'helpers/history';
import CustomModal from 'common/custommodal'
import { Popover, PopoverBody, } from 'reactstrap';

const WebsiteActionButtons = (props) => {

    const { setPage, setSelected, page, rowsPerPage, keyword, row, rowLength, deleteWebsites, changeWebsiteStatus } = props;

    const [modal, setModal] = React.useState(false);
    const toggle = () => setModal(!modal)
    const [modal2, setModal2] = React.useState(false);
    const toggle2 = () => setModal2(!modal2)

    const [popover, setPopover] = React.useState(false);
    const togglePopover = () => setPopover(!popover)

    const onDelete = () => {
        toggle();
        if (rowLength === 1 && page !== 0) {
            setPage(page - 1);
            deleteWebsites({ id: row.id }, page, rowsPerPage, keyword);
        }
        else
            deleteWebsites({ id: row.id }, page + 1, rowsPerPage, keyword);
        setSelected([]);
    };

    const onStatusChange = () => {
        toggle2();
        changeWebsiteStatus({ id: row.id, status: row.status === "active" ? "suspend" : "active" }, page + 1, rowsPerPage, keyword);
        setSelected([]);
    };

    return (
        <div>
            <IconButton aria-label='key' className='no-border-onfocus' onClick={togglePopover} id={`Popover-${row.id}`}>
                <MoreHorizIcon />
            </IconButton>
            <Popover placement='left' trigger="legacy" isOpen={popover} target={`Popover-${row.id}`} toggle={togglePopover}>
                <PopoverBody>
                    <a role='button' className='anchoreless-button' onClick={(e) => {
                        e.stopPropagation();
                        const win = window.open(row.url, '_blank');
                        if (win != null) {
                            win.focus();
                        }
                    }}>
                        <VisibilityIcon />{' '} Visit Site
                    </a>
                    {row.status === "active" ?
                        <a role='button' className='anchoreless-button' onClick={(e) => {
                            e.stopPropagation();
                            toggle2();
                        }}>
                            <NotInterestedIcon />{' '}Suspend Site
                        </a>
                        :
                        <a role='button' disabled={row.status === "pending"} className='anchoreless-button' onClick={(e) => {
                            e.stopPropagation();
                            toggle2();
                        }}>
                            <CheckCircleOutlineIcon />{' '}Active Site
                        </a>
                    }
                    <a role='button' className='anchoreless-button' onClick={(e) => {
                        e.stopPropagation();
                        history.push(row.id);
                    }}>
                        <EditIcon />{' '}Edit
                    </a>
                    <a role='button' className='anchoreless-button' onClick={(e) => {
                        e.stopPropagation();
                        toggle();
                    }}>
                        <DeleteIcon />{' '}Delete
                    </a>
                </PopoverBody>
            </Popover>
            <CustomModal
                onModalAccept={onDelete}
                onModalCancel={toggle}
                modal={modal}
                toggle={toggle}
                modalTitle="Delete"
                modalBody="Are you sure you want to delete?"
            />
            <CustomModal
                onModalAccept={onStatusChange}
                onModalCancel={toggle2}
                modal={modal2}
                toggle={toggle2}
                modalTitle={`${row.status === "active" ? "Suspend" : "Activate"} the site`}
                modalBody={`Are you sure you want to ${row.status === "active" ? "Suspend" : "Activate"} the site?`}
            />
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        deleteWebsites: (postData, pageNo, perPage, keyword) => { dispatch(deleteWebsites(postData, pageNo, perPage, keyword)) },
        changeWebsiteStatus: (postData, pageNo, perPage, keyword) => { dispatch(changeWebsiteStatus(postData, pageNo, perPage, keyword)) },
    };
};

export default connect(null, mapDispatchToProps)(WebsiteActionButtons);