import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton, Tooltip } from '@material-ui/core';

import { changePostStatus } from '../../actions/postActions';
import { connect } from 'react-redux';
import CustomModal from 'common/custommodal';

const PageMassActionButtons = (props) => {
  const {
    selected,
    setSelected,
    page,
    rowsPerPage,
    keyword,
    changePostStatus,
  } = props;
  console.log(props);
  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);

  const onMassTrash = () => {
    toggle();
    changePostStatus(
      { id: selected, status: 'trash' },
      page + 1,
      rowsPerPage,
      keyword,
      '',
      'post',
      props.otherProps.status
    );
    setSelected([]);
  };

  return (
    <div>
      <Tooltip title='Move to Trash'>
        <div>
          <IconButton
            aria-label='delete'
            disabled={selected.length < 1}
            onClick={(e) => {
              e.stopPropagation();
              toggle();
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </Tooltip>

      <CustomModal
        onModalAccept={onMassTrash}
        onModalCancel={toggle}
        modal={modal}
        toggle={toggle}
        modalTitle='Move to Trash'
        modalBody='Are you sure you want to trash this post(s)?'
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePostStatus: (
      postData,
      pageNo,
      perPage,
      keyword,
      order,
      postType,
      post_status
    ) => {
      dispatch(
        changePostStatus(
          postData,
          pageNo,
          perPage,
          keyword,
          order,
          postType,
          post_status
        )
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageMassActionButtons);
