//comment
export const FETCH_COMMENT_SUCCESS = 'FETCH_COMMENT_SUCCESS'
export const FETCH_COMMENT_PENDING = 'FETCH_COMMENT_PENDING'
export const FETCH_COMMENT_ERROR = 'FETCH_COMMENT_ERROR'
export const FETCH_COMMENT = 'FETCH_COMMENT'
export const FETCH_COMMENT_COUNT = 'FETCH_COMMENT_COUNT'
export const ADD_UPDATE_COMMENT_ERROR = 'ADD_UPDATE_COMMENT_ERROR'
export const ADD_UPDATE_COMMENT_PENDING = 'ADD_UPDATE_COMMENT_PENDING'
export const ADD_UPDATE_COMMENT_SUCCESS = 'ADD_UPDATE_COMMENT_SUCCESS'
export const FETCH_ONE_COMMENT = 'FETCH_ONE_COMMENT'
export const FETCH_ONE_COMMENT_PENDING = 'FETCH_ONE_COMMENT_PENDING'