import { instance2 } from "utils/axios";
import { history } from "helpers/history";
import CustomSnackbar from "common/customsnackbar";

import {
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_PENDING,
  FETCH_CATEGORY_ERROR,
  FETCH_CATEGORY,
  FETCH_CATEGORY_COUNT,
  ADD_UPDATE_CATEGORY_ERROR,
  ADD_UPDATE_CATEGORY_PENDING,
  ADD_UPDATE_CATEGORY_SUCCESS,
  FETCH_ONE_CATEGORY,
  FETCH_ONE_CATEGORY_PENDING,
  FETCH_PARENT_CATEGORY,
  FETCH_PARENT_CATEGORY_PENDING,
} from "./types";

const setFetchCategorySuccess = (isFetchCategorySuccess) => (dispatch) => {
  dispatch({
    type: FETCH_CATEGORY_SUCCESS,
    payload: isFetchCategorySuccess,
  });
};

const setFetchCategoryPending = (isFetchCategoryPending) => (dispatch) => {
  dispatch({
    type: FETCH_CATEGORY_PENDING,
    payload: isFetchCategoryPending,
  });
};

const setAddUpdateCategoryPending =
  (isAddUpdateCategoryPending) => (dispatch) => {
    dispatch({
      type: ADD_UPDATE_CATEGORY_PENDING,
      payload: isAddUpdateCategoryPending,
    });
  };

const setFetchOneCategoryPending = (isCategoryDataPending) => (dispatch) => {
  dispatch({
    type: FETCH_ONE_CATEGORY_PENDING,
    payload: isCategoryDataPending,
  });
};

export const setAddUpdateCategoryError =
  (addUpdateCategoryError) => (dispatch) => {
    dispatch({
      type: ADD_UPDATE_CATEGORY_ERROR,
      payload: addUpdateCategoryError,
    });
  };

export const setAddUpdateCategorySuccess =
  (isAddUpdateCategorySuccess) => (dispatch) => {
    dispatch({
      type: ADD_UPDATE_CATEGORY_SUCCESS,
      payload: isAddUpdateCategorySuccess,
    });
  };

export const setFetchCategoryError = (fetchCategoryError) => (dispatch) => {
  dispatch({
    type: FETCH_CATEGORY_ERROR,
    payload: fetchCategoryError,
  });
};

const setFetchParentCategoryPending =
  (isFetchParentCategoryPending) => (dispatch) => {
    dispatch({
      type: FETCH_PARENT_CATEGORY_PENDING,
      payload: isFetchParentCategoryPending,
    });
  };

export const fetchCategories =
  (
    pageNo = 1,
    perPage = 10,
    keyword = "",
    order = "",
    postType = "post",
    has_post = 0,
    desc = false
  ) =>
  async (dispatch) => {
    dispatch(setFetchCategoryPending(true));
    dispatch({
      type: FETCH_CATEGORY_COUNT,
      payload: -1,
    });
    dispatch({
      type: FETCH_CATEGORY,
      payload: [],
    });

    instance2
      .post(
        `term/getAll/category/${pageNo}/${perPage}/search=${keyword}&order=${order}&post_type=${postType}&has_post=${has_post}${
          desc ? " desc" : ""
        }`,
        {}
      )
      .then((response) => {
        dispatch(setFetchCategorySuccess(true));
        dispatch({
          type: FETCH_CATEGORY_COUNT,
          payload: parseInt(response.data.data.count),
        });
        dispatch({
          type: FETCH_CATEGORY,
          payload: response.data.data.items,
        });
        dispatch(setFetchCategoryPending(false));
      })
      .catch((err) => {
        if (err.response) {
          dispatch(setFetchCategoryError(err.response.data.data));
          CustomSnackbar("error", err.response.data.data);
        }
        dispatch(setFetchCategoryPending(false));
      });
  };

export const addCategory = (postData, toggle) => async (dispatch) => {
  dispatch(setAddUpdateCategorySuccess(false));
  dispatch(setAddUpdateCategoryPending(true));
  instance2
    .post("term/create/category", postData)
    .then((response) => {
      dispatch(setAddUpdateCategorySuccess(true));
      toggle();
      CustomSnackbar("success", "Category added successfully");
      dispatch(fetchCategories());
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setAddUpdateCategoryError(err.response.data.data));
        CustomSnackbar("error", err.response.data.data);
      }
    })
    .finally(() => {
      dispatch(setAddUpdateCategoryPending(false));
    });
};

export const updateCategory = (id, postData, toggle) => async (dispatch) => {
  dispatch(setAddUpdateCategorySuccess(false));
  dispatch(setAddUpdateCategoryPending(true));
  dispatch(setFetchOneCategoryPending(true));

  instance2
    .post(`term/update/category/${id}`, postData)
    .then((response) => {
      dispatch({
        type: FETCH_ONE_CATEGORY,
        payload: response.data.data,
      });
      dispatch(setAddUpdateCategorySuccess(true));
      toggle();
      CustomSnackbar("success", "Category updated successfully");
      dispatch(fetchCategories());
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setAddUpdateCategoryError(err.response.data.data));
        CustomSnackbar("error", err.response.data.data);
      }
    })
    .finally(() => {
      dispatch(setAddUpdateCategoryPending(false));
      dispatch(setFetchOneCategoryPending(false));
    });
};

export const fetchCategory = (id) => async (dispatch) => {
  dispatch(setFetchOneCategoryPending(true));

  instance2
    .post(`term/get/category/${id}`, {})
    .then((response) => {
      if (!response.data.data) {
        history.push("/not-found");
      }
      dispatch({
        type: FETCH_ONE_CATEGORY,
        payload: response.data.data,
      });
      dispatch(setFetchOneCategoryPending(false));
    })
    .catch((err) => {
      dispatch(setFetchOneCategoryPending(false));
    });
};

export const deleteCategories =
  (postData, pageNo, perPage, keyword = "", order = "") =>
  async (dispatch) => {
    instance2
      .post("term/delete", postData)
      .then((response) => {
        dispatch(fetchCategories(pageNo, perPage, keyword, order));
        CustomSnackbar("success", "Category(s) deleted successfully");
      })
      .catch((err) => {
        if (err.response) {
          dispatch(setFetchCategoryError(err.response.data.data));
          CustomSnackbar("error", err.response.data.data);
        }
      });
  };

export const fetchParentCategories = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_PARENT_CATEGORY,
    payload: [],
  });
  dispatch(setFetchParentCategoryPending(true));

  instance2
    .post(`term/parentTerm/category/${id ? id : ""}`, {})
    .then((response) => {
      dispatch({
        type: FETCH_PARENT_CATEGORY,
        payload: response.data.data,
      });
      dispatch(setFetchParentCategoryPending(false));
    })
    .catch((err) => {
      dispatch(setFetchParentCategoryPending(false));
    });
};

export const fetchSlug = (postData, setFieldValue) => async (dispatch) => {
  instance2.post("term/slug/category", postData).then((response) => {
    setFieldValue("slug", response.data.data);
  });
};
