import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from './layout-components/header/header';
import Sidebar from './layout-components/sidebar/sidebar';
import Footer from './layout-components/footer/footer';
// import Customizer from './layout-components/customizer/view/Customizer';
import ThemeRoutes from 'routes/router';
import TitleProvider from 'common/titleprovider'
import PerfectScrollbar from 'react-perfect-scrollbar'

const mapStateToProps = state => ({
	...state
});

class Fulllayout extends React.Component {
	constructor(props) {
		super(props);
		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.updateDimensions = this.updateDimensions.bind(this);
		this.onScroll = this.onScroll.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.state = {
			isOpen: false,
			width: window.innerWidth
		};

		this.props.history.listen((location, action) => {
			if (window.innerWidth < 991 &&
				document.getElementById('main-wrapper').className.indexOf("show-sidebar") !== -1) {
				document.getElementById('main-wrapper').classList.toggle("show-sidebar");
			}
		});
	}
	/*--------------------------------------------------------------------------------*/
	/*Life Cycle Hook, Applies when loading or resizing App                           */
	/*--------------------------------------------------------------------------------*/
	componentDidMount() {
		window.addEventListener("load", this.updateDimensions);
		window.addEventListener("resize", this.updateDimensions);
		document.addEventListener('click', this.handleClickOutside, true);
	}

	setWrapperRef(node) {
		this.wrapperRef = node;
	}
	handleClickOutside(event) {
		const domNode = ReactDOM.findDOMNode(this.wrapperRef);
		if (!domNode || !domNode.contains(event.target)) {
			if (window.innerWidth < 991 &&
				document.getElementById('main-wrapper').className.indexOf("show-sidebar") !== -1) {
				document.getElementById('main-wrapper').classList.toggle("show-sidebar");
			}
		}
	}

	/*--------------------------------------------------------------------------------*/
	/*Function that handles sidebar, changes when resizing App                        */
	/*--------------------------------------------------------------------------------*/
	updateDimensions() {
		let element = document.getElementById('main-wrapper');
		this.setState({
			width: window.innerWidth
		});

		switch (this.props.settings.activeSidebarType) {
			case 'full':
			case 'iconbar':
				if (this.state.width < 991) {
					element.setAttribute("data-sidebartype", "mini-sidebar");
					element.classList.add("mini-sidebar");
				} else {
					element.setAttribute("data-sidebartype", this.props.settings.activeSidebarType);
					element.classList.remove("mini-sidebar");
				}
				break;

			// case 'overlay':
			// 	if (this.state.width < 767) {
			// 		element.setAttribute("data-sidebartype", "mini-sidebar");
			// 	} else {
			// 		element.setAttribute("data-sidebartype", this.props.settings.activeSidebarType);
			// 	}
			// 	break;

			default:
		}
	}
	/*--------------------------------------------------------------------------------*/
	/*Life Cycle Hook                                                                 */
	/*--------------------------------------------------------------------------------*/
	componentWillUnmount() {
		window.removeEventListener("load", this.updateDimensions);
		window.removeEventListener("resize", this.updateDimensions);
		document.removeEventListener('click', this.handleClickOutside, true);
	}
	onScroll(e) {
		if (document.getElementsByClassName('page-header').length > 0) {
			if (e.scrollTop > 0)
				document.getElementsByClassName('page-header')[0].classList.add("scrolled");
			else
				document.getElementsByClassName('page-header')[0].classList.remove("scrolled");
		}
	}
	render() {
		/*--------------------------------------------------------------------------------*/
		/* Theme Setting && Layout Options wiil be Change From Here                       */
		/*--------------------------------------------------------------------------------*/
		return (
			<div
				id="main-wrapper"
				dir={this.props.settings.activeDir}
				data-theme={this.props.settings.activeTheme}
				data-layout={this.props.settings.activeThemeLayout}
				data-sidebartype={this.props.settings.activeSidebarType}
				data-sidebar-position={this.props.settings.activeSidebarPos}
				data-header-position={this.props.settings.activeHeaderPos}
				data-boxed-layout={this.props.settings.activeLayout}
			>
				{/*--------------------------------------------------------------------------------*/}
				{/* Header                                                                         */}
				{/*--------------------------------------------------------------------------------*/}
				<Header />
				{/*--------------------------------------------------------------------------------*/}
				{/* Sidebar                                                                        */}
				{/*--------------------------------------------------------------------------------*/}
				<Sidebar ref={this.setWrapperRef} {...this.props} routes={ThemeRoutes} />
				{/*--------------------------------------------------------------------------------*/}
				{/* Page Main-Content                                                              */}
				{/*--------------------------------------------------------------------------------*/}
				<div className="page-wrapper d-block" id="page-wrapper">
					<PerfectScrollbar onScrollY={(e) => this.onScroll(e)}>
						<div className="page-content container-fluid">
							<Switch>
								{ThemeRoutes.map((prop, key) => {
									if (prop.child) {
										return prop.child.map((prop2, key2) => {
											if (prop2.collapse) {
												return prop2.subchild.map((prop3, key3) => {
													if (prop3.navlabel || !prop3.component)
														return null;
													else
														return (
															<Route exact path={prop3.path} render={(props) => <TitleProvider component={prop3.component} title={prop3.title || prop3.name} {...props} />} key={key3} />
														);
												});
											}
											else if (prop2.navlabel || !prop2.component)
												return null;
											else {
												return (
													<Route exact path={prop2.path} render={(props) => <TitleProvider component={prop2.component} title={prop2.title || prop2.name} {...props} />} key={key2} />
												);
											}
										});
									}
									else if (prop.redirect) {
										return <Redirect exact from={prop.path} to={prop.pathTo} key={key} />;
									}
									else if (prop.name === 'Not Found') {
										return <Route render={(props) => <TitleProvider component={prop.component} title={prop.title || prop.name} {...props} />} key={key} />
									}
									else {
										return (
											<Route exact path={prop.path} render={(props) => <TitleProvider component={prop.component} title={prop.title || prop.name} {...props} />} key={key} />
										);
									}
								})}
							</Switch>
						</div>
						<Footer />
					</PerfectScrollbar>
				</div>
			</div>
		);
	}
}
export default connect(mapStateToProps)(Fulllayout);
