import React from "react";
import { Modal, ModalHeader, ModalBody, Row, Col } from "hopcms-ui";
import MuiTable from "common/mui-table";
import { connect } from "react-redux";
import {
  fetchComments,
  setFetchCommentError,
  deleteComments,
} from "../actions/commentActions";
import CommentActionButtons from "./CommentActionButtons";
import OneComment from "./OneComment";

class CommentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { site: "", modal: false };
  }

  componentDidMount() {
    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
      });
      this.props.fetchComments();
    }
  }

  componentDidUpdate() {
    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
      });
      this.props.fetchComments();
    }
  }

  render() {
    const headCells = [
      { id: "name", numeric: false, disablePadding: false, label: "Name" },
      {
        id: "description",
        numeric: false,
        disablePadding: false,
        label: "Description",
      },
      { id: "slug", numeric: false, disablePadding: false, label: "Slug" },
      { id: "count", numeric: false, disablePadding: false, label: "Count" },
      { id: "action", numeric: true, disablePadding: false, label: "Action" },
    ];

    const createData = (id, name, description, slug, count) => {
      return { id, name, description, slug, count };
    };

    const onReload = (rowsPerPage, keyword) => {
      this.props.fetchComments(1, rowsPerPage, keyword);
    };

    const handlePaginationChange = (pageNo, perPage, keyword) => {
      this.props.fetchComments(pageNo, perPage, keyword);
    };

    const onMultiDelete = (postData, pageNo, perPage, keyword) => {
      this.props.deleteComments(postData, pageNo, perPage, keyword);
    };

    const onSearch = (keyword, perPage) => {
      if (keyword.length > 2 || keyword.length === 0)
        this.props.fetchComments(1, perPage, keyword);
    };

    var rows = [];
    if (this.props.comments) {
      this.props.comments.map((comment) =>
        rows.push(
          createData(
            comment.id,
            comment.name,
            comment.description,
            comment.slug,
            comment.post_count
          )
        )
      );
    }

    // if (this.props.site === '')
    //     return null

    const toggle = () =>
      this.setState({ ...this.state, modal: !this.state.modal });

    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Create New Comment</ModalHeader>
          <ModalBody>
            <OneComment id="add" toggle={toggle} />
          </ModalBody>
        </Modal>
        <div className="page-header">
          <Row>
            <Col className="page-title">
              <h1>{this.props.title}</h1>
            </Col>
            <Col className="text-right page-header-button">
              <button className="mb-3 btn btn-primary" onClick={toggle}>
                Add Comment
              </button>
            </Col>
          </Row>
        </div>
        <div className="content-wrapper">
          <MuiTable
            headCells={headCells}
            rows={rows}
            onReload={onReload} // comment this to disable refresh option
            isFetchPending={this.props.isFetchCommentPending}
            // chooseColumn={false}   //=>to false display column option
            noOfRecords={this.props.noOfRecords}
            handlePaginationChange={handlePaginationChange}
            onMultiDelete={onMultiDelete}
            onSearch={onSearch}
            Actions={CommentActionButtons}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchCommentsuccess: state.comment.isFetchCommentsuccess,
    isFetchCommentPending: state.comment.isFetchCommentPending,
    fetchCommentError: state.comment.fetchCommentError,
    comments: state.comment.comments,
    noOfRecords: state.comment.noOfRecords,
    site: state.siteSelector.selectedSite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchComments: (pageNo, perPage, keyword) => {
      dispatch(fetchComments(pageNo, perPage, keyword));
    },
    setFetchCommentError: (postData) => {
      dispatch(setFetchCommentError(postData));
    },
    deleteComments: (postData, pageNo, perPage, keyword) => {
      dispatch(deleteComments(postData, pageNo, perPage, keyword));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentList);
