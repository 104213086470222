//website
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_PENDING = 'UPLOAD_FILE_PENDING';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const FETCH_MEDIA_SUCCESS = 'FETCH_MEDIA_SUCCESS';
export const FETCH_MEDIA_PENDING = 'FETCH_MEDIA_PENDING';
export const FETCH_MEDIA_ERROR = 'FETCH_MEDIA_ERROR';
export const FETCH_ONE_MEDIA = 'FETCH_ONE_MEDIA';
export const FETCH_ONE_MEDIA_PENDING = 'FETCH_ONE_MEDIA_PENDING';
export const FETCH_MEDIA = 'FETCH_MEDIA';
export const FETCH_MEDIA_COUNT = 'FETCH_MEDIA_COUNT';