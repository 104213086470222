import { AUTH_STATE_CHANGED, LOGIN_PENDING, LOGIN_ERROR } from './types';
import { history } from 'helpers/history'
import firebase from 'lib/firebase';
import LocalStorageService from 'helpers/LocalStorageService'

const localStorageService = LocalStorageService.getService();

const setLoginPending = isLoginPending => dispatch => {
    dispatch({
        type: LOGIN_PENDING,
        payload: isLoginPending
    });
}

const setLoginError = loginError => dispatch => {
    dispatch({
        type: LOGIN_ERROR,
        payload: loginError
    });
}

export const setAuthState = (isAuthenticated, user) => dispatch => {
    dispatch({
        type: AUTH_STATE_CHANGED,
        payload: {
            isAuthenticated,
            user
        }
    });
}

export const login = (postData, from) => async dispatch => {

    dispatch(setLoginPending(true));

    postData = {
        ...postData,
        grant_type: "password",
    }

    const success = await firebase.auth().signInWithEmailAndPassword(postData.email, postData.password).catch(function (error) {
        if (error.code === "auth/wrong-password" || error.code === "auth/user-not-found") {
            dispatch(setLoginError("Email or password is incorrect"))
        } else {
            dispatch(setLoginError("Something went wrong. Please try again later."))
        }
    });
    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            // Here you should extract the complete user profile to make it available in your entire app.
            // The auth state only provides basic information.
            dispatch(setAuthState(true, {
                id: user.uid,
                avatar: user.photoURL,
                email: user.email,
                name: user.displayName || user.email,
            }))
        } else {
            dispatch(setAuthState(false, null))
        }
    })

    dispatch(setLoginPending(false));
    success && dispatch(setLoginError(null)) && history.push(from);
};

export const logout = () => async dispatch => {

    await firebase.auth().signOut();
    localStorageService.clearSite();
    history.push('/');

}