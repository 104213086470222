import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
} from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import CustomSpinner from "common/CustomSpinner";
import { connect } from "react-redux";
import { addUser, fetchUser, updateUser } from "../actions/userActions";
import { appName } from "utils/axios";

//icons
import PersonIcon from "@material-ui/icons/Person";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import PhoneIcon from "@material-ui/icons/Phone";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import FlagIcon from "@material-ui/icons/Flag";

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
const roleOptions = [
  { value: "1", label: "Admin" },
  { value: "2", label: "Client" },
];
const emptyFormValues = {
  fname: "",
  lname: "",
  username: "",
  email: "",
  phone: "",
  password: "",
  role: "1",
};

class OneUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modal: false };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id !== "add") this.props.fetchUser(id);
  }

  componentDidUpdate() {
    const { id } = this.props.match.params;

    if (id !== "add") document.title = "Edit User" + appName;
    else document.title = "Add User" + appName;
  }

  render() {
    const { id } = this.props.match.params;
    const { userData, authUserData } = this.props;

    if (
      id !== "add" &&
      this.props.isUserDataPending &&
      !this.props.isAddUpdateUserPending
    ) {
      return <CustomSpinner />;
    }

    return (
      <div>
        <div>{this.props.isUserDataPending && <CustomSpinner />}</div>
        <div className="page-header">
          <Row>
            <Col className="page-title">
              <h1 className="page-title">
                {id === "add" ? "Create New User" : "Update User"}
              </h1>
            </Col>
          </Row>
        </div>
        <div className="content-wrapper">
          <Card>
            <CardTitle>
              {id === "add" ? "Create New User" : "Update User"}
            </CardTitle>
            <CardBody className="">
              <div>
                <Formik
                  enableReinitialize
                  initialValues={
                    id === "add"
                      ? emptyFormValues
                      : {
                          fname: userData.customer_fname || "",
                          lname: userData.customer_lname || "",
                          username: userData.customer_username || "",
                          email: userData.customer_email || "",
                          phone: userData.customer_phone || "",
                          password: "",
                          role: userData.role_id || "1",
                        }
                  }
                  validationSchema={Yup.object().shape({
                    fname: Yup.string().required("First Name is required"),
                    lname: Yup.string().required("Last Name is required"),
                    username: Yup.string().required("Username is required"),
                    email: Yup.string()
                      .email("Email is invalid")
                      .required("Email is required"),
                    phone: Yup.string()
                      .matches(phoneRegExp, "Phone number is invalid")
                      .required("Phone number is required"),
                    // password: Yup.string().required('Password is required'),
                    role: Yup.string().required("Role is required"),
                  })}
                  onSubmit={({
                    fname,
                    lname,
                    username,
                    email,
                    phone,
                    password,
                    role,
                  }) => {
                    const postData = {
                      customer_fname: fname,
                      customer_lname: lname,
                      customer_username: username,
                      customer_email: email,
                      customer_phone: phone,
                      customer_password: password,
                      role: role,
                    };
                    id === "add"
                      ? this.props.addUser(postData)
                      : this.props.updateUser(
                          id,
                          postData,
                          id === authUserData.id
                        );
                  }}
                >
                  {({
                    errors,
                    touched,
                    setFieldValue,
                    values,
                    setFieldTouched,
                    resetForm,
                  }) => (
                    <Form id="loginform">
                      <Row>
                        <Col lg="6" xs="12">
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <PersonIcon fontSize="small" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Field
                              placeholder="First Name"
                              name="fname"
                              type="text"
                              className={
                                "form-control" +
                                (errors.fname && touched.fname
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="fname"
                              component="div"
                              className="invalid-feedback"
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="6" xs="12">
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <PersonIcon fontSize="small" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Field
                              placeholder="Last Name"
                              name="lname"
                              type="text"
                              className={
                                "form-control" +
                                (errors.lname && touched.lname
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="lname"
                              component="div"
                              className="invalid-feedback"
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="6" xs="12">
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <AccountCircleIcon fontSize="small" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Field
                              placeholder="Username"
                              name="username"
                              disabled={id !== "add"}
                              type="text"
                              className={
                                "form-control" +
                                (errors.username && touched.username
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="username"
                              component="div"
                              className="invalid-feedback"
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="6" xs="12">
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <AlternateEmailIcon fontSize="small" />
                              </InputGroupText>
                            </InputGroupAddon>

                            <Field
                              placeholder="Email"
                              name="email"
                              type="text"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="6" xs="12">
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <PhoneIcon fontSize="small" />
                              </InputGroupText>
                            </InputGroupAddon>

                            <Field
                              placeholder="Phone Number"
                              name="phone"
                              type="text"
                              className={
                                "form-control" +
                                (errors.phone && touched.phone
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="invalid-feedback"
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="6" xs="12">
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <VpnKeyIcon fontSize="small" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Field
                              placeholder="Password"
                              name="password"
                              type="password"
                              className={
                                "form-control" +
                                (errors.password && touched.password
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="6" xs="12">
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <FlagIcon fontSize="small" />
                              </InputGroupText>
                            </InputGroupAddon>

                            <Select
                              placeholder="Select Role"
                              options={roleOptions}
                              isSearchable={false}
                              styles={{
                                container: (provided, state) => ({
                                  ...provided,
                                  flex: "1 1 auto",
                                  width: "1%",
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  border:
                                    errors.role && touched.role
                                      ? "1px solid #f62d51"
                                      : "1px solid #e9ecef",
                                  borderRadius: "2px",
                                }),
                                singleValue: (provided, state) => ({
                                  ...provided,
                                  color: "#525f7f",
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  padding: "0 0.75rem",
                                }),
                              }}
                              onChange={(option) => {
                                setFieldValue("role", option.value);
                              }}
                              onBlur={() => {
                                setFieldTouched("role");
                              }}
                              value={
                                roleOptions.find(
                                  (option) => option.value === values.role
                                ) || ""
                              }
                            />
                            <ErrorMessage
                              name="role"
                              component="div"
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            />
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="3" xs="6" className="mb-3">
                          <button
                            type="submit"
                            className="btn btn-block btn-primary"
                            disabled={this.props.isAddUpdateUserPending}
                          >
                            {id === "add" ? "Create" : "Update"}
                          </button>
                        </Col>
                        <Col lg="3" xs="6" className="mb-3">
                          <button
                            type="reset"
                            className="btn btn-block btn-outline-primary"
                            disabled={this.props.isAddUpdateUserPending}
                            onClick={(e) => {
                              e.preventDefault();
                              resetForm(
                                id === "add"
                                  ? emptyFormValues
                                  : {
                                      ...emptyFormValues,
                                      username: values.username,
                                    }
                              );
                            }}
                          >
                            Clear
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
    userShifts: state.user.userShifts,
    isFetchUserShiftPending: state.user.isFetchUserShiftPending,
    userData: state.user.userData,
    isAddUpdateUserPending: state.user.isAddUpdateUserPending,
    addUpdateUserError: state.user.addUpdateUserError,
    isUserDataPending: state.user.isUserDataPending,
    isAddUpdateUserSuccess: state.user.isAddUpdateUserSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (postData) => {
      dispatch(addUser(postData));
    },
    fetchUser: (id) => {
      dispatch(fetchUser(id));
    },
    updateUser: (id, postData, currentUser) => {
      dispatch(updateUser(id, postData, currentUser));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OneUser);
