import React from 'react'
import {
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Col,
} from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CustomSpinner from 'common/CustomSpinner';
import { connect } from 'react-redux';
import { addGroup, fetchGroups, fetchGroup, setAddUpdateGroupError, updateGroup, setAddUpdateGroupSuccess } from '../actions/groupActions';

//icons
import PersonIcon from '@material-ui/icons/Person';


const emptyFormValues = {
    website_group_name: '',
    website_group_description: '',
}

class OneGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = { modal: false }
    }

    componentDidMount() {
        const { id } = this.props;
        if (id !== "add")
            this.props.fetchGroup(id);
    }

    render() {

        const { id, toggle } = this.props;
        const {
            groupData,
            isGroupDataPending,
            isAddUpdateGroupPending,
            addGroup,
            updateGroup,
        } = this.props;

        if (id !== "add" && isGroupDataPending && !isAddUpdateGroupPending) {
            return <CustomSpinner />;
        }

        return (
            <div>
                <div >
                    {isGroupDataPending && <CustomSpinner />}
                </div>
                <div >
                    <Formik
                        enableReinitialize
                        initialValues={
                            id === "add" ?
                                emptyFormValues : {
                                    website_group_name: groupData.website_group_name || '',
                                    website_group_description: groupData.website_group_description || '',
                                }
                        }
                        validationSchema={Yup.object().shape({
                            website_group_name: Yup.string().required('Name is required'),
                        })}
                        onSubmit={({ website_group_name, website_group_description }) => {
                            const postData = {
                                website_group_name: website_group_name,
                                website_group_description: website_group_description,
                            };
                            id === "add" ? addGroup(postData, toggle) : updateGroup(id, postData, toggle)
                        }}
                    >
                        {({ errors, touched, setFieldValue, values, setFieldTouched, resetForm }) => (
                            <Form id="loginform">
                                <Row>
                                    <Col xs="12">
                                        <InputGroup className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <PersonIcon fontSize='small' />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Field placeholder="Name" name="website_group_name" type="text" className={'form-control' + (errors.website_group_name && touched.website_group_name ? ' is-invalid' : '')} />
                                            <ErrorMessage name="website_group_name" component="div" className="invalid-feedback" />
                                        </InputGroup>
                                    </Col>

                                    <Col xs="12">
                                        <InputGroup className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <PersonIcon fontSize='small' />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Field style={{ height: '100px' }} component="textarea" placeholder="description" name="website_group_description" type="text" className={'form-control' + (errors.website_group_description && touched.website_group_description ? ' is-invalid' : '')} />
                                            <ErrorMessage name="website_group_description" component="div" className="invalid-feedback" />
                                        </InputGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs="6" className="mb-3">
                                        <button type="submit" className="btn btn-block btn-primary" disabled={isAddUpdateGroupPending}>{id === "add" ? "Create" : "Update"}</button>
                                    </Col>
                                    <Col xs="6" className="mb-3">
                                        <button type="reset" className="btn btn-block btn-outline-primary" disabled={isAddUpdateGroupPending} onClick={(e) => {
                                            e.preventDefault();
                                            resetForm(emptyFormValues);
                                        }}>Clear</button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        groupData: state.group.groupData,
        isFetchGroupPending: state.group.isFetchGroupPending,
        groups: state.group.groups,
        isAddUpdateGroupPending: state.group.isAddUpdateGroupPending,
        addUpdateGroupError: state.group.addUpdateGroupError,
        isGroupDataPending: state.group.isGroupDataPending,
        isAddUpdateGroupSuccess: state.group.isAddUpdateGroupSuccess,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addGroup: (postData, toggle) => { dispatch(addGroup(postData, toggle)) },
        fetchGroups: (pageNo, perPage, keyword) => { dispatch(fetchGroups(pageNo, perPage, keyword)) },
        fetchGroup: (id) => { dispatch(fetchGroup(id)) },
        setAddUpdateGroupError: (error) => { dispatch(setAddUpdateGroupError(error)) },
        updateGroup: (id, postData, toggle) => { dispatch(updateGroup(id, postData, toggle)) },
        setAddUpdateGroupSuccess: (isAddUpdateGroupSuccess) => { dispatch(setAddUpdateGroupSuccess(isAddUpdateGroupSuccess)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OneGroup);