import React from 'react'
import {
    Row,
    Col,
    Label,
    Input,
    Container,
    FormText,
} from 'reactstrap';
import { connect } from 'react-redux';
import { fetchOneMedia, updateOneMedia, deleteMedia } from '../actions/mediaActions';
import { FormGroup } from '@material-ui/core';
import CustomModal from 'common/custommodal'
import { bytesToSize } from 'utils/fun';

class MediaEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            deleteModal: false,
            formData: {
                altText: '',
                title: '',
                caption: '',
                description: '',
                initialized: false
            }
        }
    }

    componentDidMount() {
        const { oneMedia } = this.props;
        if (oneMedia.length !== 0 && !this.state.formData.initialized) {
            this.setState({
                ...this.state,
                formData: {
                    ...this.state.formData,
                    altText: oneMedia.altText,
                    title: oneMedia.titleOnly,
                    caption: oneMedia.caption,
                    description: oneMedia.description,
                    initialized: true,
                }
            })
        }
    }

    componentDidUpdate() {
        const { oneMedia } = this.props;
        if (oneMedia.length !== 0 && !this.state.formData.initialized) {
            this.setState({
                ...this.state,
                formData: {
                    ...this.state.formData,
                    altText: oneMedia.altText,
                    title: oneMedia.titleOnly,
                    caption: oneMedia.caption,
                    description: oneMedia.description,
                    initialized: true,
                }
            })
        }
    }

    render() {

        const { id, page, rowsPerPage, keyword, toggle, site, oneMedia, deleteMedia, updateOneMedia, afterDelete } = this.props;

        const handleChange = (e) => {
            this.setState({
                ...this.state,
                formData: {
                    ...this.state.formData,
                    [e.target.name]: e.target.value
                }
            })
        }

        const toggleDeleteModal = (e) => {
            this.setState({
                ...this.state,
                deleteModal: !this.state.deleteModal
            })
        }

        const updateForm = (e) => {
            updateOneMedia(id, {
                caption: this.state.formData.caption,
                'alt-text': this.state.formData.altText,
                description: this.state.formData.description,
                title: this.state.formData.title
            }, [])
        }

        const onDelete = (e) => {
            toggleDeleteModal();
            toggle();
            if (afterDelete) {
                deleteMedia({ id }, page, rowsPerPage, keyword, false);
                afterDelete();
            }
            else
                deleteMedia({ id }, page, rowsPerPage, keyword);

        }

        if (oneMedia.length < 1)
            return null;

        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col xs="12" sm="8">
                            <img style={{ maxWidth: '100%', marginBottom: '8px' }} src={site.website_url + '/' + oneMedia.slug} />
                        </Col>
                        <Col xs="12" sm="4">
                            <b>File name: </b>{oneMedia.slug.split("/").slice(-1)[0]}<br />
                            <b>File type: </b>{oneMedia.postMimeType}<br />
                            <b>Uploaded on: </b>{oneMedia.date}<br />
                            <b>File size: </b>{bytesToSize(oneMedia.meta.filesize)}<br />
                            <b>Dimensions: </b>{`${oneMedia.meta.width} by ${oneMedia.meta.height} pixels`}<br />
                            <hr />
                            <FormGroup>
                                <Label className="control-label" for="Alternative Text">Alternative Text</Label>
                                <Input onChange={handleChange} value={this.state.formData.altText} type="text" name="altText" id="Alternative Text" />
                                <FormText color="muted">Leave empty if the image is purely decorative.</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label className="control-label" for="Title">Title</Label>
                                <Input onChange={handleChange} value={this.state.formData.title} type="text" name="title" id="Title" />
                            </FormGroup>
                            <FormGroup>
                                <Label className="control-label" for="Caption">Caption</Label>
                                <Input onChange={handleChange} value={this.state.formData.caption} type="text" name="caption" id="Caption" />
                            </FormGroup>
                            <FormGroup>
                                <Label className="control-label" for="Description">Description</Label>
                                <Input onChange={handleChange} value={this.state.formData.description} type="text" name="description" id="Description" />
                            </FormGroup>
                            <FormGroup>
                                <Label className="control-label" for="Uploaded By">Uploaded By</Label>
                                <Input readOnly type="text" name="Uploaded By" id="Uploaded By" value={oneMedia.author} />
                            </FormGroup>
                            <FormGroup>
                                <Label className="control-label" for="Uploaded To">Uploaded To</Label>
                                <Input style={{ cursor: 'pointer' }} readOnly type="text" name="Uploaded To" id="Uploaded To" value="Link to post" onClick={(e) => {
                                    const win = window.open(site.website_url + '/' + oneMedia.slug, '_blank');
                                    if (win != null) {
                                        win.focus();
                                    }
                                }} />
                            </FormGroup>
                            <FormGroup>
                                <Label className="control-label" for="Copy Link">Copy Link</Label>
                                <Input readOnly type="text" name="Copy Link" id="Copy Link" value={site.website_url + '/' + oneMedia.slug} />
                            </FormGroup>
                            <button type="submit" onClick={updateForm} className="mt-4 btn btn-primary">Update</button>
                            <button type="button" onClick={toggleDeleteModal} className="mt-4 btn btn-outline-danger pull-right">Delete</button>
                        </Col>
                    </Row>
                </Container>
                <CustomModal
                    onModalAccept={onDelete}
                    onModalCancel={toggleDeleteModal}
                    modal={this.state.deleteModal}
                    toggle={toggleDeleteModal}
                    modalTitle="Delete Media"
                    modalBody="Are you sure you want to delete this media?"
                />
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        isFetchOneMediaPending: state.media.isFetchOneMediaPending,
        site: state.siteSelector.selectedSite,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deleteMedia: (postData, pageNo, perPage, keyword, refresh) => { dispatch(deleteMedia(postData, pageNo, perPage, keyword, refresh)) },
        fetchOneMedia: (id) => { dispatch(fetchOneMedia(id)) },
        updateOneMedia: (id, postData, oldData) => { dispatch(updateOneMedia(id, postData, oldData)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaEdit);