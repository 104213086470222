import React from 'react'
import {
    Row,
    Col,
    Label,
} from 'hopcms-ui';
import { connect } from 'react-redux';
import { fetchMedia, deleteMedia } from '../actions/mediaActions';
import { fetchAuthors } from '../../post/actions/postActions'
import { history } from 'helpers/history';
import MuiTable from 'common/mui-table'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import {
    Button,
    Drawer,
    TextField,
} from '@material-ui/core';
import Select from 'react-select'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MediaActionButtons from './MediaActionButtons';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import MediaSkeleeton from './MediaSkeleeton';

class MediaList extends React.Component {

    constructor(props) {
        super(props);
        this.state = { site: '' }
    }

    componentDidMount() {
        if (this.state.site !== this.props.site) {
            this.setState({
                ...this.state,
                site: this.props.site
            })
            this.props.fetchMedia();
        }
    }

    componentDidUpdate() {
        if (this.state.site !== this.props.site) {
            this.setState({
                ...this.state,
                site: this.props.site
            })
            this.props.fetchMedia();
        }
    }

    render() {

        const { fetchMedia, isFetchMediaPending, noOfRecords, deleteMedia, fetchAuthors, isFetchAuthorPending, authors } = this.props;

        const emptyFormValues = {
            author: [],
            from: '',
            to: '',
            mediaType: [],
        }

        const mediaTypes = [
            { value: '1', label: 'Image' },
            { value: '2', label: 'Audio' },
            { value: '3', label: 'Video' },
            { value: '4', label: 'Documents' },
            { value: '5', label: 'Spreadsheet' },
            { value: '6', label: 'Archive' },
        ]

        const headCells = [
            { id: 'id', numeric: false, disablePadding: false, label: '#', hidden: true },
            { id: 'title', numeric: false, disablePadding: false, label: 'Title', width: '90%' },
            { id: 'author', numeric: false, disablePadding: false, label: 'Author', hidden: true },
            { id: 'date', numeric: false, disablePadding: false, label: 'Date', width: '11%' },
            { id: 'action', numeric: true, disablePadding: false, label: 'Action', width: '1%' },
        ];

        const createData = (id, slug, title, author, date, altText, titleOnly, caption, description, postMimeType, meta) => {
            return { id, slug, title, author, date, altText, titleOnly, caption, description, postMimeType, meta };
        }

        const onReload = (rowsPerPage, keyword) => {
            fetchMedia(1, rowsPerPage, keyword);
        }

        const handlePaginationChange = (pageNo, perPage, keyword) => {
            fetchMedia(pageNo, perPage, keyword);
        }

        const onMultiDelete = (postData, pageNo, perPage, keyword) => {
            deleteMedia(postData, pageNo, perPage, keyword);
        }

        const onSearch = (keyword, perPage) => {
            if (keyword.length > 2 || keyword.length === 0)
                fetchMedia(1, perPage, keyword);
        }

        const generatePostTitleField = (title, author, fileName, imgUrl) => {
            return (
                `
                <table>
                    <tr>
                        <td><img class='title-featured-image' height='75' width='75' src='${imgUrl}'></td>
                        <td>
                            <table>
                                <tr>
                                    <td><svg class="MuiSvgIcon-root jss67 ic-sm" focusable="false" viewBox="0 0 24 24" aria-hidden="true" tabindex="-1" title="Person" data-ga-event-category="material-icons" data-ga-event-action="click" data-ga-event-label="Person"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></svg><span>${author}</span></td>
                                </tr>
                                <tr>
                                    <td><span style="font-size: 1.2rem; font-weight: 600;">${title}</span></td>
                                </tr>
                                <tr>
                                    <td><span>${fileName}</span></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
                `
            )
        }

        var rows = [];
        if (this.props.media) {
            this.props.media.map(oneMedia => {
                return rows.push(createData(oneMedia.id, oneMedia.post_guid, generatePostTitleField(oneMedia.post_title, oneMedia.post_author, oneMedia.post_guid.split("/").slice(-1)[0], (oneMedia.meta && oneMedia.meta.width > 74 && oneMedia.meta.height > 74) && this.props.site.website_url + "/" + oneMedia.post_guid.substring(0, oneMedia.post_guid.lastIndexOf('.')) + "-150X150." + oneMedia.post_guid.substring(oneMedia.post_guid.lastIndexOf('.') + 1) || "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg"), oneMedia.post_author, oneMedia.post_created.split(" ")[0], oneMedia.image_alt, oneMedia.post_title, oneMedia.post_excerpt, oneMedia.post_content, oneMedia.post_mime_type, oneMedia.meta));
            })
        }

        const onClose = () => {
            this.setState({ ...this.state, open: !this.state.open })
        }

        return (
            <div>

                <Formik
                    enableReinitialize
                    initialValues={
                        emptyFormValues
                    }
                    onSubmit={({ mediaType, author, from, to }) => {
                        const postData = {
                            mediaType,
                            author,
                            from,
                            to,
                        };
                        let urlData = { mediaType: '', author: '' }
                        mediaType.forEach(item => urlData.mediaType += `${item.label.toLowerCase()}${urlData.mediaType.length > 0 ? ',' : ''}`)
                        urlData.mediaType = urlData.mediaType.substring(0, urlData.mediaType.lastIndexOf(',')) + urlData.mediaType.substring(urlData.mediaType.lastIndexOf(',') + 1);
                        author.forEach(item => urlData.author += `${item.post_author},`)
                        urlData.author = urlData.author.substring(0, urlData.author.lastIndexOf(',')) + urlData.author.substring(urlData.author.lastIndexOf(',') + 1);
                        fetchMedia(1, 10, "", "", "attachment", urlData.mediaType, "", "", urlData.author, from.length > 0 ? from + "," + (to.length > 0 ? to : from) : "")
                        onClose();
                    }}
                >
                    {({ errors, touched, setFieldValue, values, setFieldTouched, resetForm }) => (

                        <Drawer
                            anchor="right"
                            classes={{ paper: 'filter-paper' }}
                            onClose={onClose}
                            open={this.state.open}
                            variant="temporary"
                        >


                            <Form id="tagform">
                                <div className='close-button-container'>
                                    <Button
                                        onClick={onClose}
                                        size="small"
                                    >
                                        <CloseIcon />{' '}
                                        Close
                                    </Button>
                                </div>
                                <Row className='filter-fields-container'>
                                    <Col className="mb-2" xs="12">
                                        <Label className="control-label" for="author">Select author</Label>
                                        <Select
                                            id="author"
                                            isMulti
                                            isClearable={true}
                                            options={authors}
                                            getOptionLabel={(option) => option.post_author}
                                            getOptionValue={(option) => option.post_author}
                                            styles={{
                                                container: (provided, state) => ({
                                                    ...provided,
                                                    flex: '1 1 auto',
                                                }),
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    border: errors.author && touched.author ? '1px solid #f62d51' : '1px solid #e9ecef',
                                                    borderRadius: '2px',
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    color: '#525f7f',
                                                }),
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    padding: '0 0.75rem',
                                                }),
                                            }}
                                            isLoading={isFetchAuthorPending}
                                            onInputChange={(val, event) => { if (event.action === 'input-change') fetchAuthors(1, 10, val); }}
                                            onFocus={() => { fetchAuthors() }}
                                            onChange={(option) => { setFieldValue("author", option ? option : ''); }}
                                            onBlur={() => { setFieldTouched("author"); }}
                                            value={values.author || ''}
                                        />
                                        <ErrorMessage name="author" component="div" className="invalid-feedback" style={{ display: 'block' }} />
                                    </Col>


                                    <Col className="mb-2" xs="12">
                                        <Label className="control-label" for="mediaType">Select media type</Label>
                                        <Select
                                            id='mediaType'
                                            isMulti
                                            options={mediaTypes}
                                            isSearchable={false}
                                            styles={{
                                                container: (provided, state) => ({
                                                    ...provided,
                                                    flex: '1 1 auto',
                                                }),
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    border: errors.mediaType && touched.mediaType ? '1px solid #f62d51' : '1px solid #e9ecef',
                                                    borderRadius: '2px',
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    color: '#525f7f',
                                                }),
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    padding: '0 0.75rem',
                                                }),
                                            }}
                                            onChange={(option) => { setFieldValue("mediaType", option); }}
                                            onBlur={() => { setFieldTouched("mediaType"); }}
                                            value={values.mediaType || ''}
                                        />
                                    </Col>


                                    <Col className="mb-2" xs="12">
                                        <Label className="control-label" for="tag">Select from date</Label><br />
                                        <TextField
                                            id="date-from"
                                            style={{ width: '100%' }}
                                            type="date"
                                            onChange={(e) => { setFieldValue("from", e.target.value) }}
                                            value={values.from}
                                            InputLabelProps={{
                                                shrink: true,
                                            }} />
                                    </Col>

                                    <Col className="mb-2" xs="12">
                                        <Label className="control-label" for="tag">Select to date</Label><br />
                                        <TextField
                                            id="date-to"
                                            style={{ width: '100%' }}
                                            type="date"
                                            onChange={(e) => { setFieldValue("to", e.target.value) }}
                                            value={values.to}
                                            InputLabelProps={{
                                                shrink: true,
                                            }} />
                                    </Col>

                                </Row>

                                <div className='filter-action-buttons-container'>
                                    <button
                                        className='btn btn-block btn-outline-primary filter-action-button'
                                        onClick={() => {
                                            resetForm();
                                            this.setState({
                                                ...this.state,
                                                formData: {
                                                    ...this.state.formData,
                                                    categories: [],
                                                    tags: []
                                                }
                                            })
                                        }}
                                    >
                                        <DeleteIcon />{' '}
                                    Clear
                                    </button>
                                    <button
                                        className='btn btn-block btn-primary filter-action-button'
                                        type="submit"
                                    >
                                        Apply filters
                                    </button>
                                </div>
                            </Form>
                        </Drawer>
                    )}
                </Formik>

                <div className="page-header">
                    <Row>
                        <Col className="page-title">
                            <h1>{this.props.title}</h1>
                        </Col>
                        <Col className="text-right page-header-button">
                            <button className="btn btn-primary" onClick={() => { history.replace("add") }}><AddCircleOutlineIcon className='ic-sm' />Add New</button>
                            <button type="button" className="ml-2 btn btn-primary" onClick={() => this.setState({ ...this.state, open: true })}><FilterListIcon className='ic-sm' /> Filters</button>
                        </Col>
                    </Row>
                </div>

                <div className="content-wrapper">

                    <MuiTable
                        headCells={headCells}
                        rows={rows}
                        onReload={onReload}  // comment this to disable refresh option
                        isFetchPending={isFetchMediaPending}
                        // chooseColumn={false}   //=>to false display column option
                        title="Media"
                        noOfRecords={noOfRecords}
                        handlePaginationChange={handlePaginationChange}
                        onSearch={onSearch}
                        Actions={MediaActionButtons}
                        CustomSkeleton={MediaSkeleeton}
                        onMultiDelete={onMultiDelete}
                    />

                    {/* <Card>
                        <CardTitle>
                            All Media
                        <div style={{ float: 'right' }}>

                            </div>
                        </CardTitle>
                        <CardBody>
                            {this.props.mediaItems.map(mediaItem => {
                                return (
                                    <img key={mediaItem.id} className='m-1' height='150' width='150' src={`${this.props.site.website_url + "/" + mediaItem.post_guid.substring(0, mediaItem.post_guid.lastIndexOf('.')) + "-150X150." + mediaItem.post_guid.substring(mediaItem.post_guid.lastIndexOf('.') + 1)}`} />
                                )
                            })}
                        </CardBody>
                    </Card> */}
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        isFetchMediasuccess: state.media.isFetchMediasuccess,
        isFetchMediaPending: state.media.isFetchMediaPending,
        fetchMediaError: state.media.fetchMediaError,
        noOfRecords: state.media.noOfRecords,
        media: state.media.media,
        site: state.siteSelector.selectedSite,
        authors: state.post.authors,
        isFetchAuthorPending: state.post.isFetchAuthorPending,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchMedia: (pageNo, perPage, keyword, order, postType, postAttachment, post_status, terms, authors, dates) => { dispatch(fetchMedia(pageNo, perPage, keyword, order, postType, postAttachment, post_status, terms, authors, dates)) },
        deleteMedia: (postData, pageNo, perPage, keyword) => { dispatch(deleteMedia(postData, pageNo, perPage, keyword)) },
        fetchAuthors: (pageNo, perPage, keyword) => { dispatch(fetchAuthors(pageNo, perPage, keyword)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaList);