import {
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_PENDING,
    UPLOAD_FILE_ERROR,
    FETCH_MEDIA_SUCCESS,
    FETCH_MEDIA_PENDING,
    FETCH_MEDIA_ERROR,
    FETCH_ONE_MEDIA,
    FETCH_ONE_MEDIA_PENDING,
    FETCH_MEDIA,
    FETCH_MEDIA_COUNT,
    FETCH_AUTHOR,
    FETCH_AUTHOR_PENDING,
} from '../actions/types';

const initialState = {
    uploadFileError: null,
    isUploadFilePending: false,
    isUploadFileSuccess: false,
    isFetchMediaSuccess: false,
    isFetchMediaPending: false,
    fetchMediaError: null,
    oneMedia: [],
    isFetchOneMediaPending: false,
    media: [],
    noOfRecords: 0,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UPLOAD_FILE_ERROR:
            return {
                ...state,
                uploadFileError: action.payload
            };

        case UPLOAD_FILE_PENDING:
            return {
                ...state,
                isUploadFilePending: action.payload
            };

        case UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                isUploadFileSuccess: action.payload
            };

        case FETCH_ONE_MEDIA:
            return {
                ...state,
                media: action.payload
            };

        case FETCH_ONE_MEDIA_PENDING:
            return {
                ...state,
                isFetchOneMediaPending: action.payload
            };

        case FETCH_MEDIA:
            return {
                ...state,
                media: action.payload
            };

        case FETCH_MEDIA_SUCCESS:
            return {
                ...state,
                isFetchMediaSuccess: action.payload
            };

        case FETCH_MEDIA_PENDING:
            return {
                ...state,
                isFetchMediaPending: action.payload
            };

        case FETCH_MEDIA_ERROR:
            return {
                ...state,
                fetchMediaError: action.payload
            };

        case FETCH_MEDIA_COUNT:
            return {
                ...state,
                noOfRecords: action.payload
            };

        default:
            return state;
    }
}