import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/Edit';
import { deleteTags } from '../actions/tagActions';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import CustomModal from 'common/custommodal'
import OneTag from './OneTag'

const TagActionButtons = (props) => {

    const { setPage, setSelected, page, rowsPerPage, keyword, row, rowLength, deleteTags } = props;

    const [modal, setModal] = React.useState(false);
    const toggle = () => setModal(!modal)

    const [modal1, setModal1] = React.useState(false);
    const toggle1 = () => setModal1(!modal1)

    const onDelete = () => {
        toggle();
        if (rowLength === 1 && page !== 0) {
            setPage(page - 1);
            deleteTags({ id: row.id }, page, rowsPerPage, keyword);
        }
        else
            deleteTags({ id: row.id }, page + 1, rowsPerPage, keyword);
        setSelected([]);
    };


    return (
        <div>
            <Tooltip title="Edit">
                <IconButton aria-label='edit' onClick={(e) => {
                    toggle1();
                    e.stopPropagation();
                }}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
                <IconButton aria-label='delete' onClick={(e) => {
                    e.stopPropagation();
                    toggle();
                }}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            {row.id === "1" ?
                <CustomModal
                    onModalAccept={toggle}
                    successButtonText="Ok"
                    modal={modal}
                    toggle={toggle}
                    modalTitle="Info"
                    modalBody="You can't delete Uncategorized"
                />
                :
                <CustomModal
                    onModalAccept={onDelete}
                    onModalCancel={toggle}
                    modal={modal}
                    toggle={toggle}
                    modalTitle="Delete"
                    modalBody="Are you sure you want to delete?"
                />
            }
            <Modal isOpen={modal1} toggle={toggle1}>
                <ModalHeader toggle={toggle1}>Update Tag</ModalHeader>
                <ModalBody>
                    <OneTag
                        id={row.id}
                        toggle={toggle1}
                    />
                </ModalBody>
            </Modal>
        </div >

    )
}

const mapDispatchToProps = dispatch => {
    return {
        deleteTags: (postData, pageNo, perPage, keyword) => { dispatch(deleteTags(postData, pageNo, perPage, keyword)) },
    };
};

export default connect(null, mapDispatchToProps)(TagActionButtons);