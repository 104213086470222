import React, { Fragment } from 'react';
import TableCell from '@material-ui/core/TableCell';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';

export default function PageSkeleton() {
  return (
    <Fragment>
      <TableCell style={{ width: '90%' }}>
        <Box display='flex' alignItems='center'>
          <Box width='100%' style={{ marginLeft: '8px' }}>
            <Skeleton variant='text' />
            <Skeleton variant='text' />
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Skeleton variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton variant='text' />
      </TableCell>
    </Fragment>
  );
}
