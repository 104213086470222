import React from "react";
import { InputGroup } from "reactstrap";
import { connect } from "react-redux";
import { fetchWebsites } from "app/manage/website/actions/websiteActions";
import { setSite } from "../actions/siteSelectorActions";
import Select from "react-select";
import LocalStorageService from "helpers/LocalStorageService";
import { instance2, setAppSecretKey } from "utils/axios";
import { history } from "helpers/history";

const localStorageService = LocalStorageService.getService();

class SiteSelector extends React.Component {
  componentDidMount() {
    let path = this.props.location.pathname;
    // if( > -1){
    //     console.log(path.substring(path.indexOf("/site/") + 6, path.indexOf("/", 6)))
    // }
    if (path.indexOf("/site/") === -1 && localStorageService.getSite()) {
      this.props.setSite(JSON.parse(localStorageService.getSite()));
      instance2.defaults.baseURL =
        JSON.parse(localStorageService.getSite()).website_url + "/coreapi";
      setAppSecretKey(
        JSON.parse(localStorageService.getSite()).website_app_secret_key
      );
    }
    this.props.fetchWebsites(1, 100);
  }

  componentDidUpdate() {
    if (this.props.websites && this.props.websites.length > 0) {
      if (this.props.site === "") {
        let path = this.props.location.pathname;
        let id = path.substring(
          path.indexOf("/site/") + 6,
          path.indexOf("/", 6)
        );
        let website = this.props.websites.find(function (el) {
          return el.website_domain === id;
        });

        if (website) {
          localStorageService.setSite(JSON.stringify(website));
          this.props.setSite(website);
          instance2.defaults.baseURL = website.website_url + "/coreapi";
          setAppSecretKey(website.website_app_secret_key);
        } else {
          localStorageService.setSite(JSON.stringify(this.props.websites[0]));
          this.props.setSite(this.props.websites[0]);
          instance2.defaults.baseURL =
            this.props.websites[0].website_url + "/coreapi";
          setAppSecretKey(this.props.websites[0].website_app_secret_key);
        }
      }
    }
  }

  render() {
    const { websites, isFetchWebsitePending, fetchWebsites, setSite, site } =
      this.props;

    return (
      <div className="site-selector container">
        <InputGroup>
          <Select
            placeholder="Select Site"
            classNamePrefix={"site-selector"}
            options={websites}
            getOptionLabel={(option) => option.website_domain}
            getOptionValue={(option) => option.id}
            styles={{
              container: (provided, state) => ({
                ...provided,
                flex: "1 1 auto",
                width: "1%",
              }),
              control: (provided, state) => ({
                ...provided,
                border: "none",
                borderRadius: "0",
                background: "none",
              }),
              singleValue: (provided, state) => ({
                ...provided,
                color: "#ffffff",
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                padding: "0 0.75rem",
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: "#ffffff",
              }),
            }}
            isLoading={isFetchWebsitePending}
            onFocus={() => {
              fetchWebsites(1, 100);
            }}
            onInputChange={(val, event) => {
              if (event.action === "input-change") fetchWebsites(1, 100, val);
            }}
            onChange={(option) => {
              if (option) {
                localStorageService.setSite(JSON.stringify(option));
                instance2.defaults.baseURL = option.website_url + "/coreapi";
                setAppSecretKey(option.website_app_secret_key);
                setSite(option);
                history.push(
                  this.props.location.pathname.replace(
                    site.website_domain,
                    option.website_domain
                  )
                );
              } else {
                localStorageService.setSite("");
                instance2.defaults.baseURL = "";
                setSite("");
                history.push("/site/cms/dashboard");
              }
            }}
            value={site}
          />
        </InputGroup>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchWebsiteSuccess: state.website.isFetchWebsiteSuccess,
    isFetchWebsitePending: state.website.isFetchWebsitePending,
    websites: state.website.websites,
    site: state.siteSelector.selectedSite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchWebsites: (pageNo, perPage, keyword) => {
      dispatch(fetchWebsites(pageNo, perPage, keyword));
    },
    setSite: (site) => {
      dispatch(setSite(site));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteSelector);
