import React from 'react'
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
} from 'reactstrap';


class SiteDashboard extends React.Component {
	render() {
		return (
			<div>
				<div className="page-header">
					<Row>
						<Col className="page-title">
							{/* <h1>{this.props.t(this.props.title)}</h1> */}
							<h1>Site Dashboard</h1>
						</Col>
						<Col className="text-right page-header-button">
							<button type="button" className="ml-2 btn btn-primary">Add Widgets</button>
						</Col>
					</Row>
				</div>

				<div className="content-wrapper">
					<Card>
						<CardBody>
							Website Dashboard!!
				</CardBody>
					</Card>
				</div>
			</div>
		);
	}
}

export default SiteDashboard;