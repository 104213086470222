import { FETCH_USER_DETAIL } from '../actions/types';

const initialState = {
    userData: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_USER_DETAIL:
            return {
                ...state,
                userData: action.payload
            };

        default:
            return state;
    }
}

