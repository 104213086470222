import React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'hopcms-ui';
import MuiTable from 'common/mui-table';
import { connect } from 'react-redux';
import { fetchTags, setFetchTagError, deleteTags } from '../actions/tagActions';
import TagActionButtons from './TagActionButtons';
import OneTag from './OneTag';
import TagSkeleeton from './TagSkeleeton';

// var changed = false;

class TagList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { site: '', modal: false };
  }

  componentDidMount() {
    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
      });
      this.props.fetchTags();
    }
  }

  componentDidUpdate() {
    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
      });
      this.props.fetchTags();
    }
    // changed = true;
  }

  render() {
    const headCells = [
      // { id: "id", numeric: false, disablePadding: false, label: "#" },
      { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
      {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description',
      },
      { id: 'slug', numeric: false, disablePadding: false, label: 'Slug' },
      { id: 'count', numeric: false, disablePadding: false, label: 'Count' },
      { id: 'action', numeric: true, disablePadding: false, label: 'Action' },
    ];

    const createData = (id, name, description, slug, count) => {
      return { id, name, description, slug, count };
    };

    const onReload = (rowsPerPage, keyword) => {
      this.props.fetchTags(1, rowsPerPage, keyword);
    };

    const handlePaginationChange = (pageNo, perPage, keyword) => {
      this.props.fetchTags(pageNo, perPage, keyword);
    };

    const onMultiDelete = (postData, pageNo, perPage, keyword) => {
      this.props.deleteTags(postData, pageNo, perPage, keyword);
    };

    const onSearch = (keyword, perPage) => {
      if (keyword.length > 2 || keyword.length === 0)
        this.props.fetchTags(1, perPage, keyword);
    };

    var rows = [];
    if (this.props.tags) {
      this.props.tags.map((tag) =>
        rows.push(
          createData(
            tag.id,
            tag.name,
            tag.description,
            tag.slug,
            tag.post_count
          )
        )
      );
    }

    // if (this.props.site === '')
    //     return null

    const toggle = () =>
      this.setState({ ...this.state, modal: !this.state.modal });

    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          toggle={toggle}
          container='main-wrapper'
        >
          <ModalHeader toggle={toggle}>Create New Tag</ModalHeader>
          <ModalBody>
            <OneTag id='add' toggle={toggle} />
          </ModalBody>
        </Modal>
        <div className='page-header'>
          <Row>
            <Col className='page-title'>
              <h1>{this.props.title}</h1>
            </Col>
            <Col className='text-right page-header-button'>
              <button className='mb-3 btn btn-primary' onClick={toggle}>
                Add Tag
              </button>
            </Col>
          </Row>
        </div>
        <div className='content-wrapper'>
          <MuiTable
            headCells={headCells}
            rows={rows}
            onReload={onReload} // comment this to disable refresh option
            isFetchPending={this.props.isFetchTagPending}
            // chooseColumn={false}   //=>to false display column option
            noOfRecords={this.props.noOfRecords}
            handlePaginationChange={handlePaginationChange}
            multipleDelete
            onMultiDelete={onMultiDelete}
            onSearch={onSearch}
            CustomSkeleton={TagSkeleeton}
            Actions={TagActionButtons}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchTagsuccess: state.tag.isFetchTagsuccess,
    isFetchTagPending: state.tag.isFetchTagPending,
    fetchTagError: state.tag.fetchTagError,
    tags: state.tag.tags,
    noOfRecords: state.tag.noOfRecords,
    site: state.siteSelector.selectedSite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTags: (pageNo, perPage, keyword) => {
      dispatch(fetchTags(pageNo, perPage, keyword));
    },
    setFetchTagError: (postData) => {
      dispatch(setFetchTagError(postData));
    },
    deleteTags: (postData, pageNo, perPage, keyword) => {
      dispatch(deleteTags(postData, pageNo, perPage, keyword));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TagList);
