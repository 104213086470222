import React, { Fragment } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Card,
  CardBody,
  Col,
  Collapse,
  Button,
  FormGroup,
  Label,
  Input,
  FormText,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { TextField, Drawer, Button as MButton } from '@material-ui/core';
import Select from 'react-select';

import {
  addPost,
  updatePost,
  fetchPost,
  setAddUpdatePostSuccess,
  setAddUpdatePostError,
} from '../../actions/postActions';
import { Editor } from 'tinymce-react-hopcms';
import { Formik, Field, Form } from 'formik';
import CustomSpinner from 'common/CustomSpinner';
import { appName } from 'utils/axios';
import NoProagation from 'common/NoProagation';
import { Prompt } from 'react-router';
import CustomModal from 'common/custommodal';
import { history } from 'helpers/history';
import MediaModal from 'app/site/cms/media/views/MediaModal';

//icons
import SettingsIcon from '@material-ui/icons/Settings';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PublishIcon from '@material-ui/icons/Publish';
import CloseIcon from '@material-ui/icons/Close';

const emptyFormValues = {
  title: '',
  content: '',
  visibility: 'public',
  password: '',
  published_on: '',
  urlSlug: '',
  excerpt: '',
  type: 'page',
  status: '',
  parent: 0,
  comment_status: 'open',
  ping_status: 'open',
  _terms: [],
  featuredImage: {
    id: '',
    url: '',
  },
};

const visibility = [
  { value: 'public', label: 'Public' },
  { value: 'private', label: 'Private' },
  { value: 'protected', label: 'Password Protected' },
];

const dataFormatter = (str, withT) => {
  let dt = new Date(str);
  if (withT) {
    return `${dt.getFullYear()}-${
      dt.getMonth() + 1 > 9 ? dt.getMonth() + 1 : '0' + (dt.getMonth() + 1)
    }-${dt.getDate() > 9 ? dt.getDate() : '0' + dt.getDate()}T${
      dt.getHours() > 9 ? dt.getHours() : '0' + dt.getHours()
    }:${dt.getMinutes() > 9 ? dt.getMinutes() : '0' + dt.getMinutes()}`;
  } else {
    return `${dt.getFullYear()}-${
      dt.getMonth() + 1 > 9 ? dt.getMonth() + 1 : '0' + (dt.getMonth() + 1)
    }-${dt.getDate() > 9 ? dt.getDate() : '0' + dt.getDate()} ${
      dt.getHours() > 9 ? dt.getHours() : '0' + dt.getHours()
    }:${dt.getMinutes() > 9 ? dt.getMinutes() : '0' + dt.getMinutes()}:${
      dt.getSeconds() > 9 ? dt.getSeconds() : '0' + dt.getSeconds()
    }`;
  }
};
class OnePage extends React.Component {
  constructor(props) {
    super(props);
    this.didUpdated = false;
    this.updateDimensions = this.updateDimensions.bind(this);
    this.state = {
      site: '',
      id: '',
      activeTab: '1',
      activePrimaryTab: '1',
      inputValue: '',
      isOpen: {
        4: true,
      },
      isSidebarOpen: false,
      isMobile: window.innerWidth < 991 ? true : false,
      formData: {
        tags: [],
        parentCategory: '',
      },
      modal: false,
      modal1: false,
      modal2: false,
      published_on: dataFormatter(new Date().toString(), true),
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    this.setState({
      ...this.state,
      id,
    });

    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
      });
      if (id !== 'add') this.props.fetchPost(id);
    }

    window.addEventListener('load', this.updateDimensions);
    window.addEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate() {
    const { id } = this.props.match.params;

    if (id !== 'add') document.title = 'Edit Post' + appName;
    else document.title = 'Add Post' + appName;

    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
        isSidebarOpen: true,
      });
      if (id !== 'add') this.props.fetchPost(id);
    }

    if (this.state.id !== id) {
      this.setState({
        id,
      });
      if (id !== 'add' && this.state.id !== '') {
        this.props.fetchPost(id);
      }
    }

    if (!this.didUpdated && this.props.postData.tag) {
      this.didUpdated = true;
      if (
        this.props.postData.id === id &&
        this.props.postData.post_status === 'trash'
      ) {
        alert(
          'This post has been trash. You can cannot edit this post. You need to restore this post to edit'
        );
        history.replace('trash');
      }
      this.setState({
        ...this.state,
        formData: {
          ...this.state.formData,
          tags: this.props.postData.tag,
        },
      });
    }
  }

  updateDimensions() {
    this.setState({
      ...this.state,
      width: window.innerWidth,
    });

    if (this.state.width < 991) {
      this.setState({
        ...this.state,
        isMobile: true,
      });
    } else {
      this.setState({
        ...this.state,
        isMobile: false,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.updateDimensions);
    window.removeEventListener('resize', this.updateDimensions);
    window.onbeforeunload = () => undefined;
    // let element = document.getElementsByClassName('page-content')[0];
    // element.classList.remove('p-0');
  }

  toggleModal = () =>
    this.setState({ ...this.state, modal: !this.state.modal });
  toggleModal1 = () =>
    this.setState({ ...this.state, modal1: !this.state.modal1 });
  toggleModal2 = () =>
    this.setState({
      ...this.state,
      modal2: !this.state.modal2,
      isSidebarOpen: false,
    });

  toggle(tab) {
    if (this.state.activeTab !== tab)
      this.setState({ ...this.state, activeTab: tab });
  }

  togglePrimary(tab) {
    if (this.state.activePrimaryTab !== tab)
      this.setState({ ...this.state, activePrimaryTab: tab });
  }

  toggleCollapse(index) {
    this.setState({
      ...this.state,
      isOpen: { ...this.state.isOpen, [index]: !this.state.isOpen[index] },
    });
  }

  render() {
    const { id } = this.props.match.params;

    const {
      addPost,
      updatePost,
      postData,
      isPostDataPending,
      isAddUpdatePostPending,
      site,
    } = this.props;

    let _terms = [];

    const onSidebarClose = () => {
      this.setState({
        ...this.state,
        isSidebarOpen: !this.state.isSidebarOpen,
      });
    };

    const closeModalOpenSidebar = () => {
      this.setState({
        ...this.state,
        isSidebarOpen: !this.state.isSidebarOpen,
        modal2: !this.state.modal2,
      });
    };

    if (id !== 'add' && isPostDataPending && !isAddUpdatePostPending) {
      return <CustomSpinner />;
    }

    return (
      <div>
        <div>{isPostDataPending && <CustomSpinner />}</div>
        <Formik
          enableReinitialize
          initialValues={
            id === 'add'
              ? emptyFormValues
              : {
                  title: postData.post_title || '',
                  content: postData.post_content || '',
                  visibility: postData.post_visibility || '',
                  password: postData.post_password || '',
                  published_on:
                    postData.post_status === 'draft'
                      ? ''
                      : postData.post_published || '',
                  urlSlug: postData.post_name || '',
                  excerpt: postData.post_excerpt || '',
                  type: postData.post_type || '',
                  status: postData.post_status || '',
                  parent: postData.post_parent || '',
                  comment_status: postData.comment_status || '',
                  ping_status: postData.ping_status || '',
                  _terms: _terms || '',
                  featuredImage: {
                    id: postData._thumbnail_id || '',
                    post_guid: postData.thumbnail_guide || '',
                  },
                }
          }
          onSubmit={({
            title,
            visibility,
            password,
            published_on,
            urlSlug,
            excerpt,
            content,
            type,
            status,
            parent,
            comment_status,
            ping_status,
            _terms,
            featuredImage,
          }) => {
            const postData = {
              post_title: title,
              post_visibility: visibility,
              post_password: password,
              post_published:
                status === 'publish' &&
                this.props.postData.post_status === 'draft' &&
                published_on === ''
                  ? dataFormatter(new Date().toString(), false)
                  : published_on,
              post_name: urlSlug,
              post_excerpt: excerpt,
              post_content: content,
              post_type: type,
              post_status: status,
              post_parent: parent,
              comment_status: comment_status,
              ping_status: ping_status,
              _terms: _terms,
              _thumbnail_id: featuredImage.id,
            };
            console.log(postData);
            this.state.id === 'add'
              ? addPost(postData)
              : updatePost(
                  id,
                  postData,
                  status !== this.props.postData.post_status
                );
          }}
        >
          {({
            errors,
            touched,
            setFieldValue,
            values,
            setFieldTouched,
            resetForm,
            submitForm,
            dirty,
          }) => {
            if (dirty) window.onbeforeunload = () => true;
            else window.onbeforeunload = () => undefined;
            return (
              <Form id='loginform'>
                <Prompt
                  message={(location, action) => {
                    if (action !== 'REPLACE' && dirty) {
                      return 'You have unsaved changes, are you sure you want to leave?';
                    } else return true;
                  }}
                />

                <div className='page-header'>
                  <Row>
                    <Col className='page-title'>
                      <h1 className='page-title'>
                        {this.state.id === 'add'
                          ? this.props.title
                          : 'Edit Page'}
                      </h1>
                    </Col>
                    <Col className='text-right page-header-button'>
                      {id !== 'add' ? (
                        <button
                          type='button'
                          onClick={() => {
                            window.open(
                              site.website_url + postData.post_url,
                              '_blank'
                            );
                          }}
                          className='mr-2 btn btn-outline-primary'
                        >
                          Preview
                        </button>
                      ) : null}
                      <button
                        type='submit'
                        disabled={
                          id !== 'add'
                            ? values.status !== 'draft' && !dirty
                            : !dirty
                        }
                        onClick={() => {
                          setFieldValue('status', 'publish');
                        }}
                        className='ml-2 btn btn-primary'
                      >
                        <PublishIcon className='ic-sm' />
                        {id === 'add' ||
                        (id !== 'add' && values.status === 'draft')
                          ? 'Publish'
                          : 'Update'}
                      </button>
                      <button
                        type='button'
                        className='ml-2 btn btn-outline-primary'
                        onClick={onSidebarClose}
                      >
                        <SettingsIcon className='ic-sm' /> Settings
                      </button>
                    </Col>
                  </Row>
                </div>
                <div className='content-wrapper'>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Field
                          placeholder='Add title here'
                          name='title'
                          type='text'
                          className='form-control'
                          style={{ height: '2.8rem', fontSize: '1.3rem' }}
                        />
                      </FormGroup>
                      <Card>
                        <CardBody>
                          {/* <div style={{ height: window.innerHeight - 178, background: '#f6f7f8', }}></div> */}
                          <PerfectScrollbar className='sidebar-nav'>
                            <Nav tabs>
                              <NavItem>
                                <NavLink
                                  className={
                                    this.state.activePrimaryTab === '1'
                                      ? 'active'
                                      : ''
                                  }
                                  onClick={() => {
                                    this.togglePrimary('1');
                                  }}
                                >
                                  Content
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={
                                    this.state.activePrimaryTab === '3'
                                      ? 'active'
                                      : ''
                                  }
                                  onClick={() => {
                                    this.togglePrimary('3');
                                  }}
                                >
                                  SEO
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={
                                    this.state.activePrimaryTab === '4'
                                      ? 'active'
                                      : ''
                                  }
                                  onClick={() => {
                                    this.togglePrimary('4');
                                  }}
                                >
                                  Options
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activePrimaryTab}>
                              <TabPane tabId='1'>
                                <Editor
                                  // apiKey="mwtdgachffkfhz73qtzzhc974sz8azr9z88aw7xn29jw4daw"
                                  initialValue='<p>Start writing your awesome post here.</p>'
                                  init={{
                                    height: 550,
                                    menubar: false,
                                    plugins: [
                                      'advlist autolink lists link image',
                                      'charmap print preview anchor help',
                                      'searchreplace visualblocks code',
                                      'insertdatetime media table paste wordcount',
                                    ],
                                    toolbar:
                                      'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat table | code',
                                  }}
                                  onEditorChange={(content, editor) => {
                                    setFieldValue('content', content);
                                  }}
                                  value={values.content}
                                />
                              </TabPane>
                              <TabPane tabId='2'>tab 2</TabPane>
                            </TabContent>
                          </PerfectScrollbar>
                        </CardBody>
                      </Card>
                    </Col>
                    <Drawer
                      anchor='right'
                      classes={{ paper: 'filter-paper' }}
                      onClose={onSidebarClose}
                      open={this.state.isSidebarOpen}
                      variant='temporary'
                    >
                      <div className='close-button-container'>
                        <MButton onClick={onSidebarClose} size='small'>
                          <CloseIcon /> Close
                        </MButton>
                      </div>
                      <Card>
                        <CardBody style={{ minHeight: 655 }}>
                          {/* <div style={{ height: window.innerHeight - 127, background: '#f6f7f8' }}></div> */}
                          <PerfectScrollbar className='sidebar-nav'>
                            <Nav tabs>
                              <NavItem>
                                <NavLink
                                  className={
                                    this.state.activeTab === '1' ? 'active' : ''
                                  }
                                  onClick={() => {
                                    this.toggle('1');
                                  }}
                                >
                                  Document
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={
                                    this.state.activeTab === '2' ? 'active' : ''
                                  }
                                  onClick={() => {
                                    this.toggle('2');
                                  }}
                                >
                                  Block
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                              <TabPane tabId='1' style={{ minHeight: '550px' }}>
                                {/* Status & visibility */}
                                <Button
                                  color='primary'
                                  onClick={() => {
                                    this.toggleCollapse(0);
                                  }}
                                  style={{
                                    marginBottom: '0.5rem',
                                    width: '100%',
                                    textAlign: 'left',
                                  }}
                                >
                                  Status & visibility
                                  {this.state.isOpen[0] ? (
                                    <ExpandLessIcon
                                      style={{ float: 'right' }}
                                    />
                                  ) : (
                                    <ExpandMoreIcon
                                      style={{ float: 'right' }}
                                    />
                                  )}
                                </Button>
                                <Collapse isOpen={this.state.isOpen[0]}>
                                  <div className='collapse-content'>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <span>Visibility</span>
                                      <Select
                                        id='visibility'
                                        options={visibility}
                                        isSearchable={false}
                                        styles={{
                                          container: (provided, state) => ({
                                            ...provided,
                                            flex: '0.8 1 auto',
                                            width: '1%',
                                          }),
                                          control: (provided, state) => ({
                                            ...provided,
                                            border: '1px solid #e9ecef',
                                            borderRadius: '2px',
                                          }),
                                          singleValue: (provided, state) => ({
                                            ...provided,
                                            color: '#525f7f',
                                          }),
                                          valueContainer: (
                                            provided,
                                            state
                                          ) => ({
                                            ...provided,
                                            padding: '0 0.75rem',
                                          }),
                                        }}
                                        onChange={(option) => {
                                          setFieldValue(
                                            'visibility',
                                            option.value
                                          );
                                        }}
                                        onBlur={() => {
                                          setFieldTouched('visibility');
                                        }}
                                        value={
                                          visibility.find(
                                            (option) =>
                                              option.value === values.visibility
                                          ) || ''
                                        }
                                      />
                                    </div>
                                    {values.visibility === 'protected' && (
                                      <FormGroup className='mt-2'>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <span>Password: </span>
                                          <Field
                                            id='password'
                                            name='password'
                                            type='password'
                                            placeholder='password'
                                            className='form-control'
                                          />
                                        </div>
                                        <FormText color='muted'>
                                          * Only those with the password can
                                          view this post.
                                        </FormText>
                                      </FormGroup>
                                    )}
                                    {id !== 'add' ? (
                                      <div
                                        className='mt-4'
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <span>Status</span>
                                        <span>{values.status}</span>
                                      </div>
                                    ) : null}
                                    <div
                                      className='mt-4'
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <span>
                                        {new Date().getTime() >
                                          new Date(
                                            values.published_on
                                          ).getTime() ||
                                        values.published_on === ''
                                          ? 'Published On'
                                          : 'Scheduled for'}
                                      </span>
                                      <span
                                        id='UncontrolledPopover1'
                                        style={{
                                          cursor: 'pointer',
                                          color: 'blue',
                                          textDecoration: 'underline',
                                        }}
                                      >
                                        {values.published_on === ''
                                          ? 'Immediately'
                                          : values.published_on}
                                      </span>
                                      <UncontrolledPopover
                                        trigger='legacy'
                                        placement='bottom-end'
                                        target='UncontrolledPopover1'
                                      >
                                        <PopoverHeader>
                                          Select Date & Time
                                        </PopoverHeader>
                                        <PopoverBody>
                                          <TextField
                                            id='datetime-local'
                                            type='datetime-local'
                                            onChange={(e) => {
                                              this.setState({
                                                ...this.state,
                                                published_on: e.target.value,
                                              });
                                            }}
                                            value={this.state.published_on}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />

                                          <button
                                            className='btn'
                                            onClick={() => {
                                              setFieldValue(
                                                'published_on',
                                                dataFormatter(
                                                  this.state.published_on,
                                                  false
                                                )
                                              );
                                            }}
                                          >
                                            Ok
                                          </button>
                                        </PopoverBody>
                                      </UncontrolledPopover>
                                    </div>
                                    {id !== 'add' ? (
                                      <div
                                        className='mt-4'
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <span>Last Updated</span>
                                        <span>{postData.post_modified}</span>
                                      </div>
                                    ) : null}
                                    <FormGroup check className='mt-4'>
                                      <Label check>
                                        <Input type='checkbox' /> Stick to the
                                        top of the blog
                                      </Label>
                                    </FormGroup>
                                    <FormGroup check className='mt-4'>
                                      <Label check>
                                        <Input type='checkbox' /> Pending review
                                      </Label>
                                    </FormGroup>

                                    <CustomModal
                                      onModalAccept={async () => {
                                        await setFieldValue('status', 'trash');
                                        await submitForm();
                                      }}
                                      onModalCancel={this.toggleModal1}
                                      modal={this.state.modal1}
                                      toggle={this.toggleModal1}
                                      modalTitle='Move to Trash'
                                      modalBody='Are you sure you want to trash this post?'
                                    />

                                    <div
                                      className='mt-4'
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {id !== 'add' ? (
                                        <button
                                          type='button'
                                          onClick={this.toggleModal1}
                                          className='mt-1 btn btn-outline-danger'
                                        >
                                          Trash
                                        </button>
                                      ) : (
                                        <span></span>
                                      )}
                                      {(values.status === 'draft' ||
                                        id === 'add') && (
                                        <button
                                          type='submit'
                                          disabled={!dirty}
                                          className='mr-2 btn btn-outline-primary'
                                          onClick={async () => {
                                            await setFieldValue(
                                              'status',
                                              'draft'
                                            );
                                            await submitForm();
                                          }}
                                        >
                                          Save Draft
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </Collapse>

                                {id !== 'add' && (
                                  <div>
                                    {/* Permalink */}
                                    <Button
                                      color='primary'
                                      onClick={() => {
                                        this.toggleCollapse(1);
                                      }}
                                      style={{
                                        marginBottom: '0.5rem',
                                        width: '100%',
                                        textAlign: 'left',
                                      }}
                                    >
                                      Permalink
                                      {this.state.isOpen[1] ? (
                                        <ExpandLessIcon
                                          style={{ float: 'right' }}
                                        />
                                      ) : (
                                        <ExpandMoreIcon
                                          style={{ float: 'right' }}
                                        />
                                      )}
                                    </Button>
                                    <Collapse isOpen={this.state.isOpen[1]}>
                                      <div className='collapse-content'>
                                        <FormGroup>
                                          <Label for='urlSlug'>URL Slug</Label>
                                          <Field
                                            id='urlSlug'
                                            name='urlSlug'
                                            type='text'
                                            onBlur={(e) => {
                                              setFieldValue(
                                                'urlSlug',
                                                e.target.value
                                                  .toLowerCase()
                                                  .trim()
                                                  .replace(/[\W_]+/g, '-')
                                              );
                                            }}
                                            className='form-control'
                                          />
                                          <FormText color='muted'>
                                            The last part of the URL.
                                          </FormText>
                                          <br />
                                          <a
                                            rel='noopener noreferrer'
                                            target='_blank'
                                            href={
                                              site.website_url +
                                              (postData.post_url || '')
                                            }
                                          >
                                            Visit Post
                                          </a>
                                        </FormGroup>
                                      </div>
                                    </Collapse>
                                  </div>
                                )}

                                {/* Featured image */}
                                <Button
                                  color='primary'
                                  onClick={() => {
                                    this.toggleCollapse(4);
                                  }}
                                  style={{
                                    marginBottom: '0.5rem',
                                    width: '100%',
                                    textAlign: 'left',
                                  }}
                                >
                                  Featured image
                                  {this.state.isOpen[4] ? (
                                    <ExpandLessIcon
                                      style={{ float: 'right' }}
                                    />
                                  ) : (
                                    <ExpandMoreIcon
                                      style={{ float: 'right' }}
                                    />
                                  )}
                                </Button>
                                <Collapse isOpen={this.state.isOpen[4]}>
                                  <div className='collapse-content'>
                                    {values.featuredImage.post_guid &&
                                      values.featuredImage.post_guid !== '' && (
                                        <Fragment>
                                          <img
                                            style={{ cursor: 'pointer' }}
                                            onClick={this.toggleModal2}
                                            className='m-1'
                                            height='150'
                                            width='150'
                                            src={`${
                                              site.website_url +
                                              '/' +
                                              values.featuredImage.post_guid.substring(
                                                0,
                                                values.featuredImage.post_guid.lastIndexOf(
                                                  '.'
                                                )
                                              ) +
                                              '-150X150.' +
                                              values.featuredImage.post_guid.substring(
                                                values.featuredImage.post_guid.lastIndexOf(
                                                  '.'
                                                ) + 1
                                              )
                                            }`}
                                          />
                                          <br />
                                        </Fragment>
                                      )}
                                    <button
                                      type='button'
                                      onClick={() =>
                                        values.featuredImage.post_guid &&
                                        values.featuredImage.post_guid !== ''
                                          ? setFieldValue('featuredImage', {
                                              url: '',
                                              post_guid: '',
                                            })
                                          : this.toggleModal2()
                                      }
                                      className='mt-1 btn btn-outline-primary'
                                    >
                                      {values.featuredImage.post_guid &&
                                      values.featuredImage.post_guid !== ''
                                        ? 'Remove featured image'
                                        : 'Set Now'}
                                    </button>
                                  </div>
                                </Collapse>

                                {/* Excerpt */}
                                <Button
                                  color='primary'
                                  onClick={() => {
                                    this.toggleCollapse(5);
                                  }}
                                  style={{
                                    marginBottom: '0.5rem',
                                    width: '100%',
                                    textAlign: 'left',
                                  }}
                                >
                                  Excerpt
                                  {this.state.isOpen[5] ? (
                                    <ExpandLessIcon
                                      style={{ float: 'right' }}
                                    />
                                  ) : (
                                    <ExpandMoreIcon
                                      style={{ float: 'right' }}
                                    />
                                  )}
                                </Button>
                                <Collapse isOpen={this.state.isOpen[5]}>
                                  <div className='collapse-content'>
                                    <FormGroup>
                                      <FormText color='muted'>
                                        Write an excerpt (optional)
                                      </FormText>
                                      <Field
                                        style={{ height: 160 }}
                                        component='textarea'
                                        name='excerpt'
                                        type='text'
                                        className='form-control'
                                      />
                                    </FormGroup>
                                  </div>
                                </Collapse>

                                {/* Discussion */}
                                <Button
                                  color='primary'
                                  onClick={() => {
                                    this.toggleCollapse(6);
                                  }}
                                  style={{
                                    marginBottom: '0.5rem',
                                    width: '100%',
                                    textAlign: 'left',
                                  }}
                                >
                                  Discussion
                                  {this.state.isOpen[6] ? (
                                    <ExpandLessIcon
                                      style={{ float: 'right' }}
                                    />
                                  ) : (
                                    <ExpandMoreIcon
                                      style={{ float: 'right' }}
                                    />
                                  )}
                                </Button>
                                <Collapse isOpen={this.state.isOpen[6]}>
                                  <div className='collapse-content'>
                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          type='checkbox'
                                          checked={
                                            values.comment_status === 'open'
                                          }
                                          onChange={(e) =>
                                            setFieldValue(
                                              'comment_status',
                                              e.target.checked
                                                ? 'open'
                                                : 'close'
                                            )
                                          }
                                        />{' '}
                                        Allow comments
                                      </Label>
                                    </FormGroup>
                                    <FormGroup check className='mt-4'>
                                      <Label check>
                                        <Input
                                          type='checkbox'
                                          checked={
                                            values.ping_status === 'open'
                                          }
                                          onChange={(e) =>
                                            setFieldValue(
                                              'ping_status',
                                              e.target.checked
                                                ? 'open'
                                                : 'close'
                                            )
                                          }
                                        />{' '}
                                        Allow pingbacks & trackbacks
                                      </Label>
                                    </FormGroup>
                                  </div>
                                </Collapse>

                                {/* Post Attributes */}
                                <Button
                                  color='primary'
                                  onClick={() => {
                                    this.toggleCollapse(7);
                                  }}
                                  style={{
                                    marginBottom: '0.5rem',
                                    width: '100%',
                                    textAlign: 'left',
                                  }}
                                >
                                  Post Attributes
                                  {this.state.isOpen[7] ? (
                                    <ExpandLessIcon
                                      style={{ float: 'right' }}
                                    />
                                  ) : (
                                    <ExpandMoreIcon
                                      style={{ float: 'right' }}
                                    />
                                  )}
                                </Button>
                                <Collapse isOpen={this.state.isOpen[7]}>
                                  <div className='collapse-content'>
                                    <FormGroup>
                                      <Label for='exampleSelect'>
                                        Template:
                                      </Label>
                                      <Input
                                        type='select'
                                        name='select'
                                        id='exampleSelect'
                                      >
                                        <option>Default</option>
                                        <option>No Sidebar</option>
                                        <option>No Header</option>
                                        <option>No Footer</option>
                                      </Input>
                                    </FormGroup>
                                  </div>
                                </Collapse>
                              </TabPane>
                              <TabPane tabId='2'>tab 2</TabPane>
                            </TabContent>
                          </PerfectScrollbar>
                        </CardBody>
                      </Card>
                    </Drawer>
                    <Modal
                      style={{
                        maxWidth: '100%',
                        width: '95%',
                        margin: '10px auto',
                      }}
                      size='xl'
                      isOpen={this.state.modal2}
                      toggle={this.toggleModal2}
                    >
                      <ModalHeader toggle={this.toggleModal2}>
                        Featured image
                      </ModalHeader>
                      <ModalBody>
                        <MediaModal
                          toggle={closeModalOpenSidebar}
                          setImageState={(featuredImage) => {
                            setFieldValue('featuredImage', featuredImage);
                            console.log(featuredImage);
                          }}
                        />
                      </ModalBody>
                    </Modal>
                  </Row>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    site: state.siteSelector.selectedSite,
    postData: state.post.postData,
    isPostDataPending: state.post.isPostDataPending,
    addUpdatePostError: state.post.addUpdatePostError,
    isAddUpdatePostSuccess: state.post.isAddUpdatePostSuccess,
    isAddUpdatePostPending: state.post.isAddUpdatePostPending,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addPost: (postData) => {
      dispatch(addPost(postData));
    },
    updatePost: (id, postData, firstTimePublish) => {
      dispatch(updatePost(id, postData, firstTimePublish));
    },
    fetchPost: (id) => {
      dispatch(fetchPost(id));
    },
    setAddUpdatePostError: (addUpdatePostError) => {
      dispatch(setAddUpdatePostError(addUpdatePostError));
    },
    setAddUpdatePostSuccess: (isAddUpdatePostSuccess) => {
      dispatch(setAddUpdatePostSuccess(isAddUpdatePostSuccess));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnePage);
