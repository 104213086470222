import axios from 'axios';
import { history } from 'helpers/history';
import LocalStorageService from 'helpers/LocalStorageService';
import qs from 'qs';
import { toast } from 'react-toastify';
import firebase from 'lib/firebase';

export const appName = ' | hopCMS';

export const instance2 = axios.create();
export var appSecretKey;
export const setAppSecretKey = (key) => {
  appSecretKey = key;
};

// instance2.defaults.baseURL = 'https://site.hopwebsite.com/coreapi/';
instance2.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

instance2.interceptors.request.use(
  (config) => {
    if (config.headers.post['Content-Type'] === 'multipart/form-data') {
      instance2.defaults.headers.post['Content-Type'] =
        'application/x-www-form-urlencoded';
    } else {
      if (typeof config.data !== 'string')
        config.data = qs.stringify(config.data, { arrayFormat: 'comma' });
      config.data +=
        (config.data.length > 0 ? '&_token=' : '_token=') + appSecretKey;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance2.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response)
      if (!navigator.onLine) {
        toast.error('You are offline. Please check Internet Connection.');
        return Promise.reject(error);
      } else {
        toast.error('Your website API is not working at the moment.');
        return Promise.reject(error);
      }
    return Promise.reject(error);
  }
);

export const instance = axios.create();

instance.defaults.baseURL = process.env.REACT_APP_API_URL;
// instance.defaults.baseURL = 'http://hopcmsdashapi.local/v1/';
instance.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

instance.interceptors.request.use(
  async (config) => {
    const token = await firebase.auth().currentUser.getIdToken();
    if (typeof config.data !== 'string')
      config.data = qs.stringify(config.data, { arrayFormat: 'comma' });
    if (token) config.headers['Authorization'] = token;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response)
      if (!navigator.onLine) {
        toast.error('You are offline. Please check Internet Connection.');
        return Promise.reject(error);
      } else {
        toast.error('Our Server API is not working at the moment.');
        return Promise.reject(error);
      }
    if (history.location.pathname === '/authentication/login') {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);
