//HopCMS
import CommonTemplate from 'hopcms/views/Template';

//EliteAdmin - Theme Components - Start
import Alerts from 'eliteadmin/views/alert';
import Badges from 'eliteadmin/views/badge';
import Breadcrumbs from 'eliteadmin/views/breadcrumb';
import Buttons from 'eliteadmin/views/button';
import Dropdowns from 'eliteadmin/views/dropdown';
import BtnGroups from 'eliteadmin/views/btn-group';
import Cards from 'eliteadmin/views/cards';
import CollapseComponent from 'eliteadmin/views/collapse';
import CarouselComponent from 'eliteadmin/views/carousel';
import JumbotronComponent from 'eliteadmin/views/jumbotron';
import LayoutComponent from 'eliteadmin/views/layout';
import ListComponent from 'eliteadmin/views/listgroup';
import MediaComponent from 'eliteadmin/views/media';
import ModalComponent from 'eliteadmin/views/modal';
import NavbarComponent from 'eliteadmin/views/navbar';
import NavsComponent from 'eliteadmin/views/navs';
import PaginationComponent from 'eliteadmin/views/pagination';
import PopoverComponent from 'eliteadmin/views/popover';
import ProgressComponent from 'eliteadmin/views/progress';
import TabsComponent from 'eliteadmin/views/tabs';
import TooltipComponent from 'eliteadmin/views/tooltip';
import TableComponent from 'eliteadmin/views/table';

//EliteAdmin - Theme Icons - Start
import ColorLensIcon from '@material-ui/icons/ColorLens';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import FlipToFrontSharpIcon from '@material-ui/icons/FlipToFrontSharp';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ToggleOffOutlinedIcon from '@material-ui/icons/ToggleOffOutlined';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import FlipTwoToneIcon from '@material-ui/icons/FlipTwoTone';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import AppsIcon from '@material-ui/icons/Apps';
import ListIcon from '@material-ui/icons/List';
import StayCurrentLandscapeIcon from '@material-ui/icons/StayCurrentLandscape';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import TabUnselectedIcon from '@material-ui/icons/TabUnselected';
import CreateIcon from '@material-ui/icons/Create';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import FilterVintageIcon from '@material-ui/icons/FilterVintage';
import BorderClearIcon from '@material-ui/icons/BorderClear';
import MenuIcon from '@material-ui/icons/Menu';
import PermMediaIcon from '@material-ui/icons/PermMedia';

const eliteroutes = [
    {
        path: '/eliteadmin',
        name: 'Elite Admin Theme',
        title: 'Theme',
        id: 'elite-admin',
        icon: ColorLensIcon,
        child: [
            {
                collapse: true,
                name: 'HopCMS',
                icon: PermMediaIcon,
                state: 'HopCMS',
                subchild: [
                    {
                        path: '/hopcms/template',
                        name: 'Template',
                        title: 'Common Template',
                        component: CommonTemplate,
                    }
                ]
            },
            {
                path: '/eliteadmin/alerts',
                name: 'Alerts',
                icon: AnnouncementIcon,
                component: Alerts,
            },
            {
                path: '/eliteadmin/badge',
                name: 'Badges',
                icon: FlipToFrontSharpIcon,
                component: Badges,
            },
            {
                path: '/eliteadmin/breadcrumb',
                name: 'Breadcrumbs',
                icon: DragHandleIcon,
                component: Breadcrumbs,
            },
            {
                path: '/eliteadmin/button',
                name: 'Buttons',
                icon: ToggleOffOutlinedIcon,
                component: Buttons,
            },
            {
                path: '/eliteadmin/dropdown',
                name: 'Button Dropdown',
                icon: DesktopMacIcon,
                component: Dropdowns,
            },
            {
                path: '/eliteadmin/btn-group',
                name: 'Button Group',
                icon: FlipTwoToneIcon,
                component: BtnGroups,
            },
            {
                path: '/eliteadmin/card',
                name: 'Cards',
                icon: CardMembershipIcon,
                component: Cards,
            },
            {
                path: '/eliteadmin/collapse',
                name: 'Collapse',
                icon: ViewAgendaIcon,
                component: CollapseComponent,
            },
            {
                path: '/eliteadmin/carousel',
                name: 'Carousel',
                icon: ViewCarouselIcon,
                component: CarouselComponent,
            },
            {
                path: '/eliteadmin/jumbotron',
                name: 'Jumbotron',
                icon: MenuIcon,
                component: JumbotronComponent,
            },
            {
                path: '/eliteadmin/layout',
                name: 'Layout',
                icon: AppsIcon,
                component: LayoutComponent,
            },
            {
                path: '/eliteadmin/listgroup',
                name: 'List Group',
                icon: ListIcon,
                component: ListComponent,
            },
            {
                path: '/eliteadmin/media',
                name: 'Media',
                icon: PermMediaIcon,
                component: MediaComponent,
            },
            {
                path: '/eliteadmin/modal',
                name: 'Modal',
                icon: StayCurrentLandscapeIcon,
                component: ModalComponent,
            },
            {
                path: '/eliteadmin/navbar',
                name: 'Navbar',
                icon: CallToActionIcon,
                component: NavbarComponent,
            },
            {
                path: '/eliteadmin/navs',
                name: 'Navs',
                icon: HorizontalSplitIcon,
                component: NavsComponent,
            },
            {
                path: '/eliteadmin/pagination',
                name: 'Pagination',
                icon: LowPriorityIcon,
                component: PaginationComponent,
            },
            {
                path: '/eliteadmin/popover',
                name: 'Popover',
                icon: CreateIcon,
                component: PopoverComponent,
            },
            {
                path: '/eliteadmin/progress',
                name: 'Progress',
                icon: EqualizerIcon,
                component: ProgressComponent,
            },
            {
                path: '/eliteadmin/table',
                name: 'Tables',
                icon: BorderClearIcon,
                component: TableComponent,
            },
            {
                path: '/eliteadmin/tabs',
                name: 'Tabs',
                icon: TabUnselectedIcon,
                component: TabsComponent,
            },
            {
                path: '/eliteadmin/tooltip',
                name: 'Tooltip',
                icon: FilterVintageIcon,
                component: TooltipComponent,
            },
        ]
    }
]

export default eliteroutes;