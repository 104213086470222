import React from 'react';
import MuiTable from 'common/mui-table';
import { connect } from 'react-redux';
import {
  fetchPosts,
  setFetchPostError,
  deletePosts,
  fetchAuthors,
} from '../actions/postActions';
import { fetchCategories } from '../actions/categoryActions';
import { fetchTags } from '../actions/tagActions';
import PostActionButtons from './PostActionButtons';
import PostMassActionButtons from './PostMassActionButtons';
import { history } from 'helpers/history';
import { Badge, Row, Col, Label } from 'hopcms-ui';
import { Formik, Form, ErrorMessage } from 'formik';
import Select from 'react-select';

import { Button, Drawer, TextField } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PostSkeleeton from './PostSkeleeton';

function CustomBadge(props) {
  const { text, ...rest } = props;

  return (
    <div>
      <Badge {...rest}>{text}</Badge>
    </div>
  );
}

class PostList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      site: '',
      open: false,
      formData: { categories: [], tags: [] },
    };
  }

  componentDidMount() {
    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
      });
      this.props.fetchPosts(1, 10, '', '', 'post', this.props.status);
    }
  }

  componentDidUpdate() {
    if (this.state.site !== this.props.site) {
      this.setState({
        ...this.state,
        site: this.props.site,
      });
      this.props.fetchPosts(1, 10, '', '', 'post', this.props.status);
    }
  }

  render() {
    const {
      fetchCategories,
      categories,
      isFetchCategoryPending,
      isFetchTagPending,
      tags,
      fetchTags,
      fetchAuthors,
      isFetchAuthorPending,
      authors,
    } = this.props;

    const emptyFormValues = {
      author: [],
      category: [],
      tag: [],
      from: '',
      to: '',
    };

    const headCells = [
      {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: '#',
        hidden: true,
      },
      {
        id: 'title',
        numeric: false,
        disablePadding: false,
        label: 'Title',
        width: '90%',
      },
      {
        id: 'author',
        numeric: false,
        disablePadding: false,
        label: 'Author',
        hidden: true,
      },
      {
        id: 'category',
        numeric: false,
        disablePadding: false,
        label: 'Categories',
      },
      {
        id: 'tag',
        numeric: false,
        disablePadding: false,
        label: 'Tags',
        hidden: true,
      },
      {
        id: 'comment',
        numeric: true,
        disablePadding: false,
        label: 'Comments',
        hidden: true,
      },
      {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'Date',
        width: '11%',
        hidden: true,
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        component: CustomBadge,
      },
      {
        id: 'action',
        numeric: true,
        disablePadding: false,
        label: 'Action',
        width: '1%',
      },
    ];

    const createData = (
      id,
      slug,
      title,
      author,
      category,
      tag,
      comment,
      date,
      status,
      customProps
    ) => {
      return {
        id,
        slug,
        title,
        author,
        category,
        tag,
        comment,
        date,
        status,
        customProps,
      };
    };

    const onReload = (rowsPerPage, keyword) => {
      this.props.fetchPosts(
        1,
        rowsPerPage,
        keyword,
        '',
        'post',
        this.props.status
      );
    };

    const handlePaginationChange = (pageNo, perPage, keyword) => {
      this.props.fetchPosts(
        pageNo,
        perPage,
        keyword,
        '',
        'post',
        this.props.status
      );
    };

    const onMultiDelete = (postData, pageNo, perPage, keyword) => {
      this.props.deletePosts(
        postData,
        pageNo,
        perPage,
        keyword,
        '',
        'post',
        this.props.status
      );
    };

    const onSearch = (keyword, perPage) => {
      if (keyword.length > 2 || keyword.length === 0)
        this.props.fetchPosts(
          1,
          perPage,
          keyword,
          '',
          'post',
          this.props.status
        );
    };

    const generatePostTitleField = (
      title,
      author,
      publishedDate,
      commnetCount,
      thumbnail_guide
    ) => {
      return `
                <table>
                    <tr>
                        <td class='MuiTableCell-root MuiTableCell-body special'><img width='75' height='75' class='title-featured-image' src='${
                          (thumbnail_guide &&
                            this.props.site.website_url +
                              '/' +
                              thumbnail_guide.substring(
                                0,
                                thumbnail_guide.lastIndexOf('.')
                              ) +
                              '-150X150.' +
                              thumbnail_guide.substring(
                                thumbnail_guide.lastIndexOf('.') + 1
                              )) ||
                          'https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg'
                        }'></td>
                        <td class='MuiTableCell-root MuiTableCell-body special'>
                            <table>
                                <tr>
                                    <td class='MuiTableCell-root MuiTableCell-body special'><svg class="MuiSvgIcon-root jss67 ic-sm" focusable="false" viewBox="0 0 24 24" aria-hidden="true" tabindex="-1" title="Person" data-ga-event-category="material-icons" data-ga-event-action="click" data-ga-event-label="Person"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></svg><span>${author}</span></td>
                                </tr>
                                <tr>
                                    <td class='MuiTableCell-root MuiTableCell-body special'><span style="font-size: 1.2rem; font-weight: 600;">${title}</span></td>
                                </tr>
                                <tr>
                                    <td class='MuiTableCell-root MuiTableCell-body special'><svg class="MuiSvgIcon-root jss67 ic-sm" focusable="false" viewBox="0 0 24 24" aria-hidden="true" tabindex="-1" title="Schedule" data-ga-event-category="material-icons" data-ga-event-action="click" data-ga-event-label="Schedule"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path><path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path></svg><span>${publishedDate} </span><svg class="MuiSvgIcon-root jss67 ic-sm" focusable="false" viewBox="0 0 24 24" aria-hidden="true" tabindex="-1" title="Comment" data-ga-event-category="material-icons" data-ga-event-action="click" data-ga-event-label="Comment"><path d="M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM18 14H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"></path></svg><span>${commnetCount}</span></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
                `;
    };

    var rows = [];
    if (this.props.posts) {
      this.props.posts.map((post) => {
        let categories = [];
        let tags = [];
        post.category.map((oneCategory) => {
          return oneCategory.name ? categories.push(oneCategory.name) : null;
        });
        post.tag.map((oneTag) => {
          return oneTag.name ? tags.push(oneTag.name) : null;
        });
        return rows.push(
          createData(
            post.id,
            post.post_url,
            generatePostTitleField(
              post.post_title,
              post.post_author,
              post.post_created.split(' ')[0],
              post.comment_count,
              post.thumbnail_guide
            ),
            post.post_author,
            categories.join(),
            tags.join(),
            post.comment_count,
            post.post_created.split(' ')[0],
            post.post_status,
            {
              color:
                post.post_status === 'draft'
                  ? 'secondary'
                  : post.post_status === 'trash'
                  ? 'warning'
                  : 'success',
            }
          )
        );
      });
    }

    const onClose = () => {
      this.setState({ ...this.state, open: !this.state.open });
    };

    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={emptyFormValues}
          onSubmit={({ author, category, tag, from, to }) => {
            const postData = {
              author,
              category,
              tag,
              from,
              to,
            };
            let urlData = { term: '', status: '', author: '' };
            category.forEach((item) => (urlData.term += `${item},`));
            tag.forEach((item) => (urlData.term += `${item},`));
            urlData.term =
              urlData.term.substring(0, urlData.term.lastIndexOf(',')) +
              urlData.term.substring(urlData.term.lastIndexOf(',') + 1);

            author.forEach(
              (item) => (urlData.author += `${item.post_author},`)
            );
            urlData.author =
              urlData.author.substring(0, urlData.author.lastIndexOf(',')) +
              urlData.author.substring(urlData.author.lastIndexOf(',') + 1);
            this.props.fetchPosts(
              1,
              10,
              '',
              '',
              'post',
              this.props.status,
              urlData.term,
              urlData.author,
              from.length > 0 ? from + ',' + (to.length > 0 ? to : from) : ''
            );
            onClose();
          }}
        >
          {({
            errors,
            touched,
            setFieldValue,
            values,
            setFieldTouched,
            resetForm,
          }) => (
            <Drawer
              anchor='right'
              classes={{ paper: 'filter-paper' }}
              onClose={onClose}
              open={this.state.open}
              variant='temporary'
            >
              <Form id='tagform'>
                <div className='close-button-container'>
                  <Button onClick={onClose} size='small'>
                    <CloseIcon /> Close
                  </Button>
                </div>
                <Row className='filter-fields-container'>
                  <Col className='mb-2' xs='12'>
                    <Label className='control-label' for='author'>
                      Select author
                    </Label>
                    <Select
                      id='author'
                      isMulti
                      isClearable={true}
                      options={authors}
                      getOptionLabel={(option) => option.post_author}
                      getOptionValue={(option) => option.post_author}
                      styles={{
                        container: (provided, state) => ({
                          ...provided,
                          flex: '1 1 auto',
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          border:
                            errors.author && touched.author
                              ? '1px solid #f62d51'
                              : '1px solid #e9ecef',
                          borderRadius: '2px',
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          color: '#525f7f',
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          padding: '0 0.75rem',
                        }),
                      }}
                      isLoading={isFetchAuthorPending}
                      onInputChange={(val, event) => {
                        if (event.action === 'input-change')
                          fetchAuthors(1, 10, val);
                      }}
                      onFocus={() => {
                        fetchAuthors();
                      }}
                      onChange={(option) => {
                        setFieldValue('author', option ? option : '');
                      }}
                      onBlur={() => {
                        setFieldTouched('author');
                      }}
                      value={values.author || ''}
                    />
                    <ErrorMessage
                      name='author'
                      component='div'
                      className='invalid-feedback'
                      style={{ display: 'block' }}
                    />
                  </Col>

                  <Col className='mb-2' xs='12'>
                    <Label className='control-label' for='category'>
                      Select category
                    </Label>
                    <Select
                      id='category'
                      isMulti
                      isClearable={true}
                      options={categories || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      styles={{
                        container: (provided, state) => ({
                          ...provided,
                          flex: '1 1 auto',
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          border:
                            errors.category && touched.category
                              ? '1px solid #f62d51'
                              : '1px solid #e9ecef',
                          borderRadius: '2px',
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          color: '#525f7f',
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          padding: '0 0.75rem',
                        }),
                      }}
                      isLoading={isFetchCategoryPending}
                      onFocus={() => fetchCategories(1, 10, '', '', 'post', 1)}
                      onInputChange={(val, event) => {
                        if (event.action === 'input-change')
                          fetchCategories(1, 10, val, '', 'post', 1);
                      }}
                      onChange={(options, { action }) => {
                        if (options) {
                          let differences =
                            this.state.formData.categories.filter(
                              (x) => !options.includes(x)
                            );
                          let newData = [...values.category];
                          if (differences.length > 0) {
                            differences.map((difference) => {
                              return (newData = newData.filter(function (
                                value,
                                index,
                                arr
                              ) {
                                return value !== difference.id;
                              }));
                            });
                          } else newData.push(options[options.length - 1].id);
                          setFieldValue('category', newData);
                        } else setFieldValue('category', []);
                        this.setState({
                          ...this.state,
                          formData: {
                            ...this.state.formData,
                            categories: options ? [...options] : [],
                          },
                        });
                      }}
                      onBlur={() => {
                        setFieldTouched('category');
                      }}
                      value={this.state.formData.categories}
                    />
                    <ErrorMessage
                      name='category'
                      component='div'
                      className='invalid-feedback'
                      style={{ display: 'block' }}
                    />
                  </Col>

                  <Col className='mb-2' xs='12'>
                    <Label className='control-label' for='tag'>
                      Select tag
                    </Label>
                    <Select
                      id='tag'
                      isMulti
                      isClearable={true}
                      options={tags || []}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      styles={{
                        container: (provided, state) => ({
                          ...provided,
                          flex: '1 1 auto',
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          border:
                            errors.tag && touched.tag
                              ? '1px solid #f62d51'
                              : '1px solid #e9ecef',
                          borderRadius: '2px',
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          color: '#525f7f',
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          padding: '0 0.75rem',
                        }),
                      }}
                      isLoading={isFetchTagPending}
                      onFocus={() => fetchTags(1, 10, '', '', 'post', 1)}
                      onInputChange={(val, event) => {
                        if (event.action === 'input-change')
                          fetchTags(1, 10, val, '', 'post', 1);
                      }}
                      onChange={(options, { action }) => {
                        if (options) {
                          let differences = this.state.formData.tags.filter(
                            (x) => !options.includes(x)
                          );
                          let newData = [...values.category];
                          if (differences.length > 0) {
                            differences.map((difference) => {
                              return (newData = newData.filter(function (
                                value,
                                index,
                                arr
                              ) {
                                return value !== difference.id;
                              }));
                            });
                          } else newData.push(options[options.length - 1].id);
                          setFieldValue('tag', newData);
                        }
                        this.setState({
                          ...this.state,
                          formData: {
                            ...this.state.formData,
                            tags: options ? [...options] : [],
                          },
                        });
                      }}
                      onBlur={() => {
                        setFieldTouched('tag');
                      }}
                      value={this.state.formData.tags}
                    />
                    <ErrorMessage
                      name='tag'
                      component='div'
                      className='invalid-feedback'
                      style={{ display: 'block' }}
                    />
                  </Col>

                  <Col className='mb-2' xs='12'>
                    <Label className='control-label' for='tag'>
                      Select from date
                    </Label>
                    <br />
                    <TextField
                      id='date-from'
                      style={{ width: '100%' }}
                      type='date'
                      onChange={(e) => {
                        setFieldValue('from', e.target.value);
                      }}
                      value={values.from}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Col>

                  <Col className='mb-2' xs='12'>
                    <Label className='control-label' for='tag'>
                      Select to date
                    </Label>
                    <br />
                    <TextField
                      id='date-to'
                      style={{ width: '100%' }}
                      type='date'
                      onChange={(e) => {
                        setFieldValue('to', e.target.value);
                      }}
                      value={values.to}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Col>
                </Row>

                <div className='filter-action-buttons-container'>
                  <button
                    className='btn btn-block btn-outline-primary filter-action-button'
                    onClick={() => {
                      resetForm();
                      this.setState({
                        ...this.state,
                        formData: {
                          ...this.state.formData,
                          categories: [],
                          tags: [],
                        },
                      });
                    }}
                  >
                    <DeleteIcon /> Clear
                  </button>
                  <button
                    className='btn btn-block btn-primary filter-action-button'
                    type='submit'
                  >
                    Apply filters
                  </button>
                </div>
              </Form>
            </Drawer>
          )}
        </Formik>

        <div className='page-header'>
          <Row>
            <Col className='page-title'>
              <h1>{this.props.t(this.props.title)}</h1>
            </Col>
            <Col className='text-right page-header-button'>
              <button
                className='btn btn-primary'
                onClick={() => {
                  history.push('add');
                }}
              >
                <AddCircleOutlineIcon className='ic-sm' />{' '}
                {this.props.t('addPost.label')}
              </button>
              <button
                type='button'
                className='ml-2 btn btn-primary'
                onClick={() => this.setState({ ...this.state, open: true })}
              >
                <FilterListIcon className='ic-sm' /> Filters
              </button>
            </Col>
          </Row>
        </div>

        <div className='content-wrapper'>
          <MuiTable
            headCells={headCells}
            rows={rows}
            onReload={onReload} // comment this to disable refresh option
            isFetchPending={this.props.isFetchPostPending}
            // chooseColumn={false}   //=>to false display column option
            noOfRecords={this.props.noOfRecords}
            handlePaginationChange={handlePaginationChange}
            multipleDelete={this.props.status === 'trash'}
            onMultiDelete={onMultiDelete}
            onSearch={onSearch}
            Actions={PostActionButtons}
            CustomSkeleton={PostSkeleeton}
            MassActions={this.props.status !== 'trash' && PostMassActionButtons}
            otherProps={{ status: this.props.status }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchPostsuccess: state.post.isFetchPostsuccess,
    isFetchPostPending: state.post.isFetchPostPending,
    fetchPostError: state.post.fetchPostError,
    posts: state.post.posts,
    noOfRecords: state.post.noOfRecords,
    authors: state.post.authors,
    isFetchAuthorPending: state.post.isFetchAuthorPending,
    site: state.siteSelector.selectedSite,
    categories: state.category.categories,
    isFetchCategoryPending: state.category.isFetchCategoryPending,
    isFetchTagPending: state.tag.isFetchTagPending,
    tags: state.tag.tags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPosts: (
      pageNo,
      perPage,
      keyword = '',
      order = '',
      postType = 'post',
      post_status = 'all',
      terms = '',
      authors = '',
      dates = ''
    ) => {
      dispatch(
        fetchPosts(
          pageNo,
          perPage,
          keyword,
          order,
          postType,
          post_status,
          terms,
          authors,
          dates
        )
      );
    },
    setFetchPostError: (postData) => {
      dispatch(setFetchPostError(postData));
    },
    deletePosts: (
      postData,
      pageNo,
      perPage,
      keyword,
      order,
      postType,
      post_status
    ) => {
      dispatch(
        deletePosts(
          postData,
          pageNo,
          perPage,
          keyword,
          order,
          postType,
          post_status
        )
      );
    },
    fetchCategories: (pageNo, perPage, keyword, order, postType, has_post) => {
      dispatch(
        fetchCategories(pageNo, perPage, keyword, order, postType, has_post)
      );
    },
    fetchTags: (pageNo, perPage, keyword, order, postType, has_post) => {
      dispatch(fetchTags(pageNo, perPage, keyword, order, postType, has_post));
    },
    fetchAuthors: (pageNo, perPage, keyword) => {
      dispatch(fetchAuthors(pageNo, perPage, keyword));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PostList));
