import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import firebase from 'lib/firebase';
import { setAuthState } from 'app/auth/actions/authActions'
import SplashScreen from 'common/SplashScreen';

const PrivateRoute = ({ component: Component, isAuthenticated, isInitialised, setAuthState, ...rest }) => {

    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            setAuthState(true, {
                id: user.uid,
                avatar: user.photoURL,
                email: user.email,
                name: user.displayName || user.email,
            })
        } else {
            setAuthState(false, null)
        }
    })

    if (!isInitialised) {
        return <SplashScreen />;
    }

    return (
        <Route {...rest} render={props => {
            if (!isAuthenticated) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/authentication/login', state: { from: props.location } }} />
            }

            // authorised so return component
            return <Component {...props} />
        }} />
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isInitialised: state.auth.isInitialised,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setAuthState: (isAuthenticated, user) => { dispatch(setAuthState(isAuthenticated, user)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);