import { instance2 } from 'utils/axios';
import CustomSnackbar from 'common/customsnackbar';

import {
  UPDATE_SEO_ERROR,
  UPDATE_SEO_PENDING,
  UPDATE_SEO_SUCCESS,
  FETCH_ONE_SEO,
  FETCH_ONE_SEO_PENDING,
} from './types';

const setUpdateSeoPending = (isUpdateSeoPending) => (dispatch) => {
  dispatch({
    type: UPDATE_SEO_PENDING,
    payload: isUpdateSeoPending,
  });
};

const setFetchOneSeoPending = (isSeoDataPending) => (dispatch) => {
  dispatch({
    type: FETCH_ONE_SEO_PENDING,
    payload: isSeoDataPending,
  });
};

export const setUpdateSeoError = (updateSeoError) => (dispatch) => {
  dispatch({
    type: UPDATE_SEO_ERROR,
    payload: updateSeoError,
  });
};

export const setUpdateSeoSuccess =
  (isUpdateSeoSuccess) => (dispatch) => {
    dispatch({
      type: UPDATE_SEO_SUCCESS,
      payload: isUpdateSeoSuccess,
    });
  };

// https://site.hopwebsite.com/coreapi/meta/save/{seting_group}
export const updateSeo = (postData) => async (dispatch) => {
  dispatch(setUpdateSeoSuccess(false));
  dispatch(setUpdateSeoPending(true));
  dispatch(setFetchOneSeoPending(true));

  instance2
    .post('meta/save/seo', postData)
    .then((response) => {
      dispatch({
        type: FETCH_ONE_SEO,
        payload: response.data.data,
      });
      dispatch(setUpdateSeoSuccess(true));
      CustomSnackbar('success', 'Setting updated successfully');
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setUpdateSeoError(err.response.data.data));
        CustomSnackbar('error', err.response.data.data);
      }
    })
    .finally(() => {
      dispatch(setUpdateSeoPending(false));
      dispatch(setFetchOneSeoPending(false));
    });
};

// https://site.hopwebsite.com/coreapi/meta/get/{seting_group}
export const fetchSeo = () => async (dispatch) => {
  dispatch(setFetchOneSeoPending(true));

  instance2
    .post('meta/get/seo', {})
    .then((response) => {
      dispatch({
        type: FETCH_ONE_SEO,
        payload: response.data.data,
      });
      dispatch(setFetchOneSeoPending(false));
    })
    .catch((err) => {
      dispatch(setFetchOneSeoPending(false));
    });
};
