import { combineReducers } from 'redux';
import { mainSiteSelectorReducer } from 'common/site-selector/reducers';
import { mainAuthReducer } from 'app/auth/reducers/';
import { mainUserReducer } from 'app/manage/user/reducers';
import { mainWebsiteReducer } from 'app/manage/website/reducers';
import { mainPostReducer } from 'app/site/cms/post/reducers';
import { mainCommentReducer } from 'app/site/cms/comment/reducers';
import { mainMediaReducer } from 'app/site/cms/media/reducers';
import { mainSeoReducer } from 'app/site/settings/seo/reducers';
import { mainGeneralReducer } from 'app/site/settings/general/reducers';
import { mainThemeReducer } from 'app/site/settings/appearance/reducers';
import { mainCustomizerReducer } from 'layouts/layout-components/customizer/reducers';

const reducers = combineReducers({
    ...mainSiteSelectorReducer,
    ...mainAuthReducer,
    ...mainUserReducer,
    ...mainWebsiteReducer,
    ...mainPostReducer,
    ...mainCommentReducer,
    ...mainMediaReducer,
    ...mainSeoReducer,
    ...mainGeneralReducer,
    ...mainThemeReducer,
    ...mainCustomizerReducer,
});

export default reducers;