const LocalStorageService = (() => {
    var _service;
    function _getService() {
        if (!_service) {
            _service = this;
            return _service
        }
        return _service
    }

    //site-selector
    function _setSite(site) {
        localStorage.setItem('site', site);
    }
    function _getSite() {
        return localStorage.getItem('site');
    }
    function _clearSite() {
        localStorage.removeItem('site');
    }

    return {
        getService: _getService,
        setSite: _setSite,
        getSite: _getSite,
        clearSite: _clearSite
    }
})();

export default LocalStorageService;
