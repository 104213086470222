import { instance } from "utils/axios";
import { history } from "helpers/history";
import CustomSnackbar from "common/customsnackbar";

import {
  FETCH_WEBSITE_SUCCESS,
  FETCH_WEBSITE_PENDING,
  FETCH_WEBSITE_ERROR,
  FETCH_WEBSITE,
  FETCH_WEBSITE_COUNT,
  ADD_UPDATE_WEBSITE_ERROR,
  ADD_UPDATE_WEBSITE_PENDING,
  ADD_WEBSITE_SUCCESS,
  UPDATE_WEBSITE_SUCCESS,
  FETCH_ONE_WEBSITE,
  FETCH_ONE_WEBSITE_PENDING,
  DELETE_WEBSITE_SUCCESS,
  SUSPEND_ACTIVE_WEBSITE_SUCCESS,
  FETCH_SERVER,
  FETCH_SERVER_PENDING,
} from "./types";

const setFetchWebsiteSuccess = (isFetchWebsiteSuccess) => (dispatch) => {
  dispatch({
    type: FETCH_WEBSITE_SUCCESS,
    payload: isFetchWebsiteSuccess,
  });
};

const setFetchWebsitePending = (isFetchWebsitePending) => (dispatch) => {
  dispatch({
    type: FETCH_WEBSITE_PENDING,
    payload: isFetchWebsitePending,
  });
};

const setAddUpdateWebsitePending =
  (isAddUpdateWebsitePending) => (dispatch) => {
    dispatch({
      type: ADD_UPDATE_WEBSITE_PENDING,
      payload: isAddUpdateWebsitePending,
    });
  };

const setFetchOneWebsitePending = (isWebsiteDataPending) => (dispatch) => {
  dispatch({
    type: FETCH_ONE_WEBSITE_PENDING,
    payload: isWebsiteDataPending,
  });
};

export const setAddUpdateWebsiteError =
  (addUpdateWebsiteError) => (dispatch) => {
    dispatch({
      type: ADD_UPDATE_WEBSITE_ERROR,
      payload: addUpdateWebsiteError,
    });
  };

export const setAddWebsiteSuccess = (isAddWebsiteSuccess) => (dispatch) => {
  dispatch({
    type: ADD_WEBSITE_SUCCESS,
    payload: isAddWebsiteSuccess,
  });
};

export const setUpdateWebsiteSuccess =
  (isUpdateWebsiteSuccess) => (dispatch) => {
    dispatch({
      type: UPDATE_WEBSITE_SUCCESS,
      payload: isUpdateWebsiteSuccess,
    });
  };

export const setFetchWebsiteError = (fetchWebsiteError) => (dispatch) => {
  dispatch({
    type: FETCH_WEBSITE_ERROR,
    payload: fetchWebsiteError,
  });
};

export const setSuspendActiveWebsiteSuccess =
  (isSuspendActiveWebsiteSuccess) => (dispatch) => {
    dispatch({
      type: SUSPEND_ACTIVE_WEBSITE_SUCCESS,
      payload: isSuspendActiveWebsiteSuccess,
    });
  };

export const setDeleteWebsiteSuccess =
  (isDeleteWebsiteSuccess) => (dispatch) => {
    dispatch({
      type: DELETE_WEBSITE_SUCCESS,
      payload: isDeleteWebsiteSuccess,
    });
  };

const setFetchServerPending = (isFetchServerPending) => (dispatch) => {
  dispatch({
    type: FETCH_SERVER_PENDING,
    payload: isFetchServerPending,
  });
};

export const fetchWebsites =
  (pageNo = 1, perPage = 10, keyword = "", order = "", desc = false) =>
  async (dispatch) => {
    dispatch(setFetchWebsitePending(true));
    /* dispatch({
      type: FETCH_WEBSITE_COUNT,
      payload: -1,
    });
    dispatch({
      type: FETCH_WEBSITE,
      payload: null,
    }); */
    instance
      .post(
        `websites/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${
          desc ? " desc" : ""
        }`,
        {}
      )
      .then((response) => {
        dispatch(setFetchWebsiteSuccess(true));
        dispatch({
          type: FETCH_WEBSITE_COUNT,
          payload: parseInt(response.data.data.count),
        });
        dispatch({
          type: FETCH_WEBSITE,
          payload: response.data.data.items,
        });
        dispatch(setFetchWebsitePending(false));
      })
      .catch((err) => {
        if (err.response) {
          dispatch(setFetchWebsiteError(err.response.data.data));
          CustomSnackbar("error", err.response.data.data);
        }
        dispatch(setFetchWebsitePending(false));
      });
  };

export const addWebsite = (postData) => async (dispatch) => {
  dispatch(setAddWebsiteSuccess(false));
  dispatch(setAddUpdateWebsitePending(true));
  instance
    .post("websites/create", postData)
    .then((response) => {
      dispatch(setAddWebsiteSuccess(true));
      CustomSnackbar("success", "Website added successfully");
      history.replace(response.data.data.id);
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setAddUpdateWebsiteError(err.response.data.data));
        CustomSnackbar("error", err.response.data.data);
      }
    })
    .finally(() => {
      dispatch(setAddUpdateWebsitePending(false));
    });
};

export const updateWebsite = (id, postData) => async (dispatch) => {
  dispatch(setUpdateWebsiteSuccess(false));
  dispatch(setAddUpdateWebsitePending(true));
  dispatch(setFetchOneWebsitePending(true));

  instance
    .post(`websites/update/${id}`, postData)
    .then((response) => {
      dispatch({
        type: FETCH_ONE_WEBSITE,
        payload: response.data.data,
      });
      dispatch(setUpdateWebsiteSuccess(true));
      CustomSnackbar("success", "Website updated successfully");
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setAddUpdateWebsiteError(err.response.data.data));
        CustomSnackbar("error", err.response.data.data);
      }
    })
    .finally(() => {
      dispatch(setAddUpdateWebsitePending(false));
      dispatch(setFetchOneWebsitePending(false));
    });
};

export const fetchWebsite = (id) => async (dispatch) => {
  dispatch(setFetchOneWebsitePending(true));
  dispatch({
    type: FETCH_ONE_WEBSITE,
    payload: [],
  });

  instance
    .post(`websites/get/${id}`, {})
    .then((response) => {
      if (!response.data.data) {
        history.push("/not-found");
      }
      dispatch({
        type: FETCH_ONE_WEBSITE,
        payload: response.data.data,
      });
      dispatch(setFetchOneWebsitePending(false));
    })
    .catch((err) => {
      dispatch(setFetchOneWebsitePending(false));
    });
};

export const deleteWebsites =
  (postData, pageNo, perPage, keyword = "") =>
  async (dispatch) => {
    dispatch(setDeleteWebsiteSuccess(false));
    instance
      .post("websites/delete", postData)
      .then((response) => {
        dispatch(fetchWebsites(pageNo, perPage, keyword));
        dispatch(setDeleteWebsiteSuccess(true));
        CustomSnackbar("success", "Website(s) deleted successfully");
      })
      .catch((err) => {
        if (err.response) {
          dispatch(setAddUpdateWebsiteError(err.response.data.data));
          CustomSnackbar("error", err.response.data.data);
        }
      });
  };

export const changeWebsiteStatus =
  (postData, pageNo, perPage, keyword = "") =>
  async (dispatch) => {
    dispatch(setSuspendActiveWebsiteSuccess(false));
    instance
      .post("websites/change_status", postData)
      .then((response) => {
        dispatch(fetchWebsites(pageNo, perPage, keyword));
        dispatch(setSuspendActiveWebsiteSuccess(true));
        CustomSnackbar(
          "success",
          `Website ${
            postData.status === "active" ? "activated" : "suspended"
          } successfully`
        );
      })
      .catch((err) => {
        if (err.response) {
          dispatch(setAddUpdateWebsiteError(err.response.data.data));
          CustomSnackbar("error", err.response.data.data);
        }
      });
  };

export const fetchAllServers = () => async (dispatch) => {
  dispatch(setFetchServerPending(true));
  instance
    .post(`servers/get`, {})
    .then((response) => {
      dispatch({
        type: FETCH_SERVER,
        payload: response.data.data,
      });

      dispatch(setFetchServerPending(false));
    })
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);

        dispatch(setFetchServerPending(false));
      }
    });
};
